@font-face {
    font-family: "ZonaPro-ExtraLight";
    src: url("../../zona-pro/FontsFree-Net-ZonaPro-Bold.ttf");
}

@font-face {
    font-family: "ZonaPro-ExtraLight";
    src: url("../../zona-pro/FontsFree-Net-ZonaPro-ExtraLight.ttf");
}

#cart_page .eltdf-container {
    width: 100%;
    padding: 0;
}

#cart_page .page-id-7 .eltdf-content .eltdf-content-inner > .eltdf-container > .eltdf-container-inner, .page-id-7 .eltdf-content .eltdf-content-inner > .eltdf-full-width > .eltdf-full-width-inner {
    padding: 33px 0 49px 0;
}

#cart_page .eltdf-grid-row {
    margin-left: -15px;
    margin-right: -15px;
}

#cart_page .eltdf-grid-col-12 {
    float: left;
    width: 100%;
}

#cart_page .cross-sells, .eltdf-content .woocommerce, .eltdf-pl-main-holder, .related.products, .upsells.products {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
}

#cart_page .woocommerce .woocommerce-cart-form {
    width: 65%;
    float: left;
    padding-right: 58px;
    box-sizing: border-box;
}

#cart_page .woocommerce .woocommerce-cart-form h2 {
    font-family: 'Zona Pro';
    color: #A65A6A;
    padding-top: 23px;
}

#cart_page .woocommerce h2 {
    font-size: 25px;
    margin-bottom: 21px;
    text-transform: capitalize;
}

#cart_page table {
    border-collapse: collapse;
    border-spacing: 0;
    vertical-align: middle;
    width: 100%;
}

#cart_page .woocommerce .woocommerce-cart-form>table>thead, .woocommerce table.cart td.actions .coupon label {
    display: none;
}

#cart_page .woocommerce table.cart thead tr {
    border: 0;
    border-bottom: 0 solid #ccc;
}

#cart_page .woocommerce table.cart thead tr th {
    padding: 10px 0;
    color: #000;
    text-align: left;
}

#cart_page .woocommerce table.cart tr.cart_item {
    border-bottom: 1px solid #e6e6e6;
}

#cart_page .woocommerce table.cart tbody tr {
    border: 0;
}

#cart_page .woocommerce table.cart tr.cart_item td.product-thumbnail {
    width: 116px;
    padding-right: 20px;
}

#cart_page .woocommerce table.cart tr.cart_item td {
    padding: 25px 0;
    vertical-align: middle;
    text-align: inherit;
}

#cart_page .woocommerce table.cart tr.cart_item td.product-thumbnail a, .woocommerce table.cart tr.cart_item td.product-thumbnail img {
    display: block;
}

#cart_page .woocommerce table.cart tr.cart_item td.product-thumbnail a, .woocommerce table.cart tr.cart_item td.product-thumbnail img {
    display: block;
}

#cart_page .woocommerce table.cart tr.cart_item .product-name {
    font-size: 15px;
    font-weight: 500;
    padding-right: 30px;
}

#cart_page .woocommerce table.cart tr.cart_item .product-name a {
    font-family: 'Zona Pro';
    color: #A65A6A;
}

#cart_page .woocommerce table.cart tr.cart_item .product-price span {
    font-family: 'ZonaPro-ExtraLight';
    color: #ACABAB;
}

#cart_page .woocommerce table.cart tr.cart_item .product-price {
    font-size: 14px;
    font-weight: 400;
}

#cart_page .woocommerce table.cart tr.cart_item td.product-quantity {
    text-align: center;
}

#cart_page .woocommerce-page .eltdf-content .eltdf-quantity-buttons, div.woocommerce .eltdf-quantity-buttons {
    position: relative;
    display: inline-block;
    vertical-align: top;
    /* background-color:#E8C9C7; */
    border-radius: 40px;
}

#cart_page .woocommerce table.cart tr.cart_item td .eltdf-quantity-buttons:before {
    content: 'Quantity';
    font-size: 14px;
    font-family: 'ZonaPro-ExtraLight';
    color: #A65A6A; 
    position: absolute;
    top: 50%;
    left: 10%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
}

.screen-reader-text {
    position: absolute!important;
    width: 1px;
    height: 0;
    clip: rect(1px,1px,1px,1px);
    word-wrap: normal!important;
    overflow: hidden;
}

#cart_page .woocommerce table.cart tr.cart_item td .eltdf-quantity-buttons .eltdf-quantity-minus {
    bottom: 0;
    top: 0;
    right: 39px;
    position: absolute;
    border: 0;
    z-index: 100;
    height: 100%;
}

#cart_page .woocommerce-page .eltdf-content .eltdf-quantity-buttons .eltdf-quantity-input, .woocommerce-page .eltdf-content .eltdf-quantity-buttons .eltdf-quantity-minus, .woocommerce-page .eltdf-content .eltdf-quantity-buttons .eltdf-quantity-plus, div.woocommerce .eltdf-quantity-buttons .eltdf-quantity-input, div.woocommerce .eltdf-quantity-buttons .eltdf-quantity-minus, div.woocommerce .eltdf-quantity-buttons .eltdf-quantity-plus {
    display: inline-block;
    vertical-align: middle;
    background-color: transparent;
    border: 1px solid #d3d3d3;
    text-align: center;
    box-sizing: border-box;
}

#cart_page #quantity_60db2ca24bea5 {
    border-radius: 40px;
    /* background: #E8C9C7; */
    color: #A65A6A;
}

#cart_page .woocommerce table.cart tr.cart_item td .eltdf-quantity-buttons .eltdf-quantity-minus:before {
    content: "\f053";
    font-family: FontAwesome;
    font-size: 18px;
    top: 25%;
    position: relative;
    color: #D27F8B;
    cursor: pointer;
}

#cart_page .woocommerce table.cart tr.cart_item td .eltdf-quantity-buttons .eltdf-quantity-plus {
    bottom: 0;
    top: 0;
    right: 12px;
    position: absolute;
    border: 0;
    z-index: 100;
    height: 100%;
}

#cart_page .woocommerce table.cart tr.cart_item td .eltdf-quantity-buttons .eltdf-quantity-input {
    width: 195px;
    margin: 0;
    text-align: center;
    padding-left: 68%;
    padding-right: 15px !important;
    font-family: 'Zona Pro';
    color: #D27F8B;
    font-weight: 300;
    font-size: 14px;
}

#cart_page .woocommerce-page .eltdf-content .eltdf-quantity-buttons .eltdf-quantity-input, div.woocommerce .eltdf-quantity-buttons .eltdf-quantity-input {
    position: relative;
    height: 50px;
    width: 50px;
    padding: 0;
    margin: 0 25px 0 0;
    line-height: 48px;
    font-size: 13px;
    font-weight: 600;
    color: #2d2d2d;
    outline: 0;
}

#cart_page .woocommerce table.cart tr.cart_item td .eltdf-quantity-buttons .eltdf-quantity-plus {
    bottom: 0;
    top: 0;
    right: 12px;
    position: absolute;
    border: 0;
    z-index: 100;
    height: 100%;
}

#cart_page .woocommerce table.cart tr.cart_item td .eltdf-quantity-buttons .eltdf-quantity-plus:before {
    content: "\f054";
    font-family: FontAwesome;
    font-size: 18px;
    top: 25%;
    position: relative;
    color: #D27F8B;
    cursor: pointer;
}

#cart_page .woocommerce table.cart tr.cart_item td.product-remove {
    padding-left: 10px;
    padding-right: 10px;
    text-align: right;
}

#cart_page .woocommerce table.cart tr.cart_item td.product-remove a {
    color: #000;
    font-size: 0;
    line-height: 2;
}

#cart_page .woocommerce table.cart tr.cart_item td.product-remove a:after {
    content: "x";
    display: inline-block;
    vertical-align: middle;
    speak: none;
    color: #B6B5B5;
    font-size: 22px;
    line-height: inherit;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#cart_page .woocommerce table.cart td.actions {
    padding: 35px 0 36px;
    text-align: inherit;
}

#cart_page .btn-cart {
    float: right;
    padding: .375rem 25px;
    background: #D27F8B;
    font-family: 'Zona Pro';
    color: #fff;
    border-radius: 40px;
    cursor: pointer;
}

#cart_page .woocommerce table.cart td.actions button[type=submit] {
    background-color: transparent;
    color: #000;
    float: left;
    /* margin-top: 23px; */
    padding: 0;
    border: none;
}

#cart_page .woocommerce-page .eltdf-content .wc-forward:not(.added_to_cart):not(.checkout-button), .woocommerce-page .eltdf-content a.added_to_cart, .woocommerce-page .eltdf-content a.button, .woocommerce-page .eltdf-content button[type=submit]:not(.eltdf-search-submit), .woocommerce-page .eltdf-content input[type=submit], div.woocommerce .wc-forward:not(.added_to_cart):not(.checkout-button), div.woocommerce a.added_to_cart, div.woocommerce a.button, div.woocommerce button[type=submit]:not(.eltdf-search-submit), div.woocommerce input[type=submit] {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: auto;
    margin: 0;
    font-family: Poppins,sans-serif;
    font-size: 13px;
    line-height: 2.1em;
    font-weight: 600;
    outline: 0;
    box-sizing: border-box;
    -webkit-transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out;
    -moz-transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out;
    transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out;
    /* padding: 9px 46px; */
    color: #fff;
    /* background-color: #000; */
    border: 1px solid transparent;
    white-space: nowrap;
    cursor: pointer;
    z-index: 3;
    text-transform: capitalize;
}

#cart_page .woocommerce table.cart td.actions button[type=submit]:before {
    transform: scale(1,1);
    transform-origin: 0 50%;
}

#cart_page .woocommerce table.cart td.actions button[type=submit]:before, .woocommerce table.cart td.actions button[type=submit]:hover:before {
    content: '';
    right: 0;
    background-color: currentColor;
    width: 100%;
    transition: transform .4s cubic-bezier(.28,.38,0,.81);
    position: absolute;
    left: 0;
    height: 1px;
    bottom: 2px;
}

#cart_page .woocommerce .cart-collaterals {
    position: relative;
    float: right;
    margin: 110px 0 0 0;
    width: 35%;
    padding: 0;
    box-sizing: border-box;
    background-color: #F5F3F3;
}

#cart_page .woocommerce .cart-collaterals h2 {
    position: absolute;
    top: -63px;
    display: block;
    margin: 0 0 26px -28px;
    font-family: 'Zona Pro';
    color: #A65A6A;
}

#cart_page .woocommerce .cart_totals {
    margin: 24px 28px 40px;
}

#cartpage .woocommerce .cart-collaterals h2 {
    position: absolute;
    top: -63px;
    display: block;
    margin: 0 0 26px -28px;
}

#cart_page .woocommerce h2 {
    font-size: 25px;
    text-transform: capitalize;
}

#cart_page .woocommerce .cart-collaterals table {
    margin: 0 0 16px;
}

#cart_page .woocommerce .cart-collaterals table tr {
    border: 0;
    border-bottom: 0 solid #ccc;
    vertical-align: baseline;
}

#cart_page .woocommerce .cart-collaterals table th {
    width: 20%;
    padding: 10px 28px 10px 0;
    border: 0;
    vertical-align: baseline;
    color: #A65A6A;
    font-size: 18px;
    font-family: 'Zona Pro';
    font-weight: 600;
    text-align: left;
}
#cart_page .woocommerce .price-sb_total {
    font-family: 'ZonaPro-Extralight';
    color: #A65A6A;
}

#cart_page .subtotal-right {
    text-align: right;
}

.cart-empty {
    position: relative;
    display: block;
    text-align: center;
    text-transform: capitalize;
    margin: 46px 0 33px;
    font-size: 38px;
    line-height: 1.1em;
    color: #A65A6A;
    font-weight: 600;
    font-family: 'Zona Pro';
}

.return-to-shop {
    position: relative;
    display: block;
    text-align: center;
    margin: 0 0 40px;
}

.button-cart-empty {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: auto;
    margin: 0;
    font-family: 'Zona Pro';
    font-size: 13px;
    line-height: 2.1em;
    font-weight: 600;
    outline: 0;
    box-sizing: border-box;
    -webkit-transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out;
    -moz-transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out;
    transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out;
    padding: 9px 46px;
    color: #fff;
    background-color:  #A65A6A;
    border: 1px solid transparent;
    white-space: nowrap;
    cursor: pointer;
    z-index: 3;
    text-transform: capitalize;
}

.button-cart-empty:before {
    content: '';
    position: absolute;
    bottom: -4px;
    left: -1px;
    right: 0;
    height: 1px;
    background-color: inherit;
    width: calc(100% + 2px);
    transform: scale(1,1);
    transform-origin: 0 50%;
    transition: transform .4s cubic-bezier(.28,.38,0,.81);
}

.button-cart-empty:hover {
    color: #fff;
}

.button-cart-empty:hover:before {
    content: '';
    position: absolute;
    bottom: -4px;
    left: -1px;
    right: 0;
    height: 1px;
    background-color: inherit;
    width: calc(100% + 2px);
    transform: scale(0,1);
    transform-origin: 100% 50%;
    transition: transform .4s cubic-bezier(.28,.38,0,.81);
}

.woocommerce table.cart td.actions {
    padding: 35px 0 36px;
    text-align: inherit;
}

@media only screen and (min-width: 1367px) {
    #cart_page .eltdf-grid-1200 .eltdf-container-inner, #cart_page .eltdf-grid-1200 .eltdf-grid, #cart_page .eltdf-grid-1200 .eltdf-row-grid-section {
        width: 1200px;
    }
    /* #cart_page .woocommerce .woocommerce-cart-form {
        width: 100%;
    }
    #cart_page .woocommerce .cart-collaterals {
        width: 39%;
    } */
}

@media only screen and (max-width: 1024px) {
    #cart_page .woocommerce .woocommerce-cart-form {
        width: 100%;
        padding: 0;
    }

    #cart_page .woocommerce .cart-collaterals {
        width: 100%;
    }
}

@media only screen and (max-width: 680px) { 
    #cart_page .woocommerce table.cart tr.cart_item td.product-thumbnail {
        display: none;
        padding-right: 0;
    }
}