@font-face {
  font-family: "ZonaPro-ExtraLight";
  src: url("../../zona-pro/FontsFree-Net-ZonaPro-Bold.ttf");
}

@font-face {
  font-family: "ZonaPro-ExtraLight";
  src: url("../../zona-pro/FontsFree-Net-ZonaPro-ExtraLight.ttf");
}

.pagination {
  margin-left: 0 !important;
  display: flex;
  list-style: none;
  outline: none;
}

.pagination > .active > a {
  background-color: #d27f8b;
  color: #fff !important;
}

.pagination > li > a {
  border: 1px solid #d27f8b;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.pagination > li > a,
.pagination > li > span {
  background-color: #fff;
}

#shop_page .woocommerce-pagination ul li.active,
.woocommerce-pagination ul li > span.current {
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 5px;
  background: #d27f8b;
  border: 3px solid #d27f8b;
  color: #fff;
  text-align: center;
}

.eltdf-container:after,
.eltdf-container:before {
  content: " ";
  display: table;
}
.aligncenter,
.alignfull,
.alignwide,
.clearfix:after,
.eltdf-container:after {
  clear: both;
}

#shop_page .eltdf-container-inner {
  padding: 80px 0 45px 0;
}

#shop_page .eltdf-grid-col-9 {
  float: left;
  width: 75%;
}

#shop_page .eltdf-grid-col-3 {
  /* float: left; */
  float: right;
  width: 25%;
}

#shop_page .eltdf-pl-main-holder,
.related.products,
.upsells.products {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}

#shop_page .eltdf-woo-normal-space ul.products {
  margin: 0 -15px;
}

#shop_page .eltdf-woo-normal-space ul.products > .product {
  padding: 0 15px;
  margin: 0 0 30px;
}

#shop_page ul.products > .product {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  float: left;
  margin: 0 0 30px;
  padding: 0 15px;
  box-sizing: border-box;
  cursor: pointer;
}

.eltdf-pl-icons-holder {
  /* position: absolute;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    flex-direction: row-reverse;
    padding: 0 8px;
    bottom: -1px;
    right: -1px;
    background-color: #fff;
    z-index: 111;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: transform .35s cubic-bezier(.34,.18,0,.9);
    -moz-transition: transform .35s cubic-bezier(.34,.18,0,.9);
    transition: transform .35s cubic-bezier(.34,.18,0,.9); */

  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  transform: translateX(100%);
  bottom: -1px;
  right: -1px;
  padding: 19px 0 10px;
  width: 100%;
  position: absolute;
  line-height: 0;
  -webkit-transition: 0.4s linear;
  -moz-transition: 0.4s linear;
  -ms-transition: 0.4s linear;
  -o-transition: 0.4s linear;
  transition: 0.4s linear;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#shop_page ul.products > .product:hover .eltdf-pl-icons-holder {
  transform: translateX(0%) !important;
  cursor: pointer;
}

#shop_page ul.products > .product .eltdf-pl-inner {
  will-change: transform;
  -webkit-filter: blur();
  filter: blur();
}

#shop_page ul.products > .product .eltdf-pl-inner,
ul.products > .product .eltdf-pl-inner .eltdf-pl-image {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  overflow: hidden;
}

#shop_page ul.products > .product .eltdf-pl-inner,
ul.products > .product .eltdf-pl-inner .eltdf-pl-image {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  overflow: hidden;
}

#shop_page ul.products > .product .eltdf-pl-inner .eltdf-pl-image img {
  display: block;
  width: 100%;
}

#shop_page ul.products > .product .eltdf-pl-inner img {
  backface-visibility: hidden;
  transform-origin: top;
  -webkit-transform: scale(1.06);
  -moz-transform: scale(1.06);
  transform: scale(1.06);
  -webkit-transition: -webkit-transform 0.45s cubic-bezier(0.33, 0.02, 0, 0.93);
  -moz-transition: -moz-transform 0.45s cubic-bezier(0.33, 0.02, 0, 0.93);
  transition: transform 0.45s cubic-bezier(0.33, 0.02, 0, 0.93);
}

#shop_page ul.products > .product .eltdf-pl-inner:hover img {
  transform: scale(1.06) translate3d(-3%, 0, 0);
  -webkit-transition: -webkit-transform 0.75s cubic-bezier(0.33, 0.02, 0, 0.93);
  -moz-transition: -moz-transform 0.75s cubic-bezier(0.33, 0.02, 0, 0.93);
  transition: transform 0.75s cubic-bezier(0.33, 0.02, 0, 0.93);
}

#shop_page
  .eltdf-woo-pl-info-below-image
  ul.products
  .eltdf-pl-inner
  .eltdf-pl-text {
  padding: 0;
}

#shop_page ul.products > .product .eltdf-pl-inner .eltdf-pl-text {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 10px;
  opacity: 0;
  box-sizing: border-box;
  -webkit-transition: opacity 0.2s ease-out;
  -moz-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
}

#shop_page ul.products > .product .eltdf-pl-inner .eltdf-pl-text-outer {
  position: relative;
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
  text-align: center;
}

#shop_page ul.products > .product .eltdf-pl-inner .eltdf-pl-text-inner {
  position: relative;
  display: table-cell;
  height: 100%;
  width: 100%;
  vertical-align: middle;
}

#shop_page ul.products > .product .eltdf-pl-text-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  margin: 20px 0 38px;
}

#shop_page ul.products > .product .eltdf-product-list-title {
  float: left;
  width: 100%;
  margin: 0;
  line-height: 21px;
}

#shop_page ul.products > .product .eltdf-product-list-title a {
  font-family: "Zona Pro";
  color: #a06464 !important;
}

#shop_page ul.products > .product .eltdf-pl-category {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.16em;
  line-height: 21px;
  float: left;
  width: 100%;
  margin: 4px 0 0;
  font-family: "ZonaPro-Extralight";
}

#shop_page ul.products > .product .price {
  font-family: "Zona Pro";
}

#shop_page ul.products > .product .price a {
  color: #a06464;
  font-weight: bold;
}

#shop_page ul.products > .product .eltdf-pl-category a {
  position: relative;
  color: #acabab;
}

#shop_page ul.products > .product .eltdf-pl-category a:before {
  transform: scale(0, 1);
  transform-origin: 100% 50%;
}

#shop_page ul.products > .product .eltdf-pl-category a:before,
ul.products > .product .eltdf-pl-category a:hover:before {
  content: "";
  position: absolute;
  bottom: -2px;
  right: 0;
  height: 1px;
  background-color: currentColor;
  width: 100%;
  transition: transform 0.4s cubic-bezier(0.28, 0.38, 0, 0.81);
  left: 0;
}

/* ICONS FOR PRODUCT (see, wishlist, heart) */

.eltdf-woo-pl-info-below-image
  ul.products
  .eltdf-pl-inner
  .eltdf-pl-text
  .eltdf-pl-text-inner
  .eltdf-pl-icons-holder
  > a.add_to_cart_button,
.eltdf-woo-pl-info-below-image
  ul.products
  .eltdf-pl-inner
  .eltdf-pl-text
  .eltdf-pl-text-inner
  .eltdf-pl-icons-holder
  > a.button.product_type_external,
.eltdf-woo-pl-info-below-image
  ul.products
  .eltdf-pl-inner
  .eltdf-pl-text
  .eltdf-pl-text-inner
  .eltdf-pl-icons-holder
  > a.button.product_type_simple {
  bottom: 0;
  right: -1px;
  display: inline-block;
  background: 0 0;
  box-sizing: content-box;
}

.eltdf-pl-icons-holder a {
  height: 43px;
  width: 18px;
  z-index: 300;
  overflow: hidden;
  padding: 0 8px;
  color: #000;
  font-size: 0;
  vertical-align: bottom;
  margin: 0;
  border: 0;
  box-sizing: initial;
  -webkit-transition: opacity 0.2s ease-out;
  -moz-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
}

#shop_page .yith-cart-button::after {
  content: "\f290";
  font-size: 17px;
  line-height: 43px;
  font-family: FontAwesome;
  color: #a65a6a;
}

#shop_page .yith-quickview-button::after {
  content: "\f06e";
  font-size: 17px;
  line-height: 43px;
  font-family: FontAwesome;
  color: #a65a6a;
}

#shop_page .yith-heart-button:after {
  content: "\f004";
  font-size: 17px;
  line-height: 43px;
  font-family: FontAwesome;
  color: #a65a6a;
}

/* FUNDI ICONS FOR PRODUCT (see, wishlist, heart) */

#shop_page .woocommerce-pagination {
  width: 100%;
  clear: both;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin: 10px 0 45px;
}

#shop_page .woocommerce-pagination,
.woocommerce-pagination ul,
.woocommerce-pagination ul li {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

#shop_page .woocommerce-pagination ul {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

#shop_page .woocommerce-pagination,
.woocommerce-pagination ul,
.woocommerce-pagination ul li {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

#shop_page .woocommerce-pagination,
.woocommerce-pagination ul,
.woocommerce-pagination ul li {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-family: "ZonaPro-Extralight";
}

#shop_page .woocommerce-pagination ul li:first-child > a,
.woocommerce-pagination ul li:first-child > span {
  margin-left: 0;
}

#shop_page .woocommerce-pagination ul li > a,
.woocommerce-pagination ul li > span {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 0 13px;
  padding: 0;
  color: #9f9f9f;
}

#shop_page .woocommerce-pagination ul li > a.current:after,
.woocommerce-pagination ul li > span.current {
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 5px;
  background: #d27f8b;
  border: 3px solid #d27f8b;
  color: #fff;
  text-align: center;
}

/*Right sidebar */
#shop_page aside.eltdf-sidebar {
  position: relative;
  z-index: 11;
  margin: 0 0 60px;
}

#shop_page .eltdf-woocommerce-page aside.eltdf-sidebar .widget:first-child {
  margin-top: 7px;
}

#shop_page .widget.woocommerce.widget_price_filter {
  margin: 0 0 43px;
}

.widget.woocommerce {
  clear: both;
}

.cross-sells,
.eltdf-content .woocommerce,
.eltdf-pl-main-holder,
.related.products,
.upsells.products {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  /* margin: 0 0 46px; */
}

.widget.woocommerce.widget_price_filter .eltdf-widget-title {
  margin: 0 0 30px;
  font-family: "Zona Pro";
  text-transform: uppercase;
}

.widget .eltdf-widget-title {
  font-size: 18px;
  font-family: "Zona Pro";
  color: #a65a6a;
}

#shop_page .widget.woocommerce.widget_price_filter form {
  margin-top: 20px;
}

#shop_page
  .widget.woocommerce.widget_price_filter
  .price_slider_wrapper
  .ui-widget-content {
  background-color: #000;
  height: 1px;
  position: relative;
}

#shop_page .ui-widget-content {
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background: 0 0;
  border: 0;
  border-radius: 0;
}

#shop_page
  .widget.woocommerce.widget_price_filter
  .price_slider_wrapper
  .ui-widget-content
  .ui-slider-range {
  background-color: #a65a6a;
  display: block;
  height: 3px;
  position: absolute;
  top: -1px;
  z-index: 1;
}

#shop_page .ui-widget-content .ui-widget-header {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: initial;
  background: 0 0;
  border: 0;
  border-radius: 0;
}

#shop_page
  .widget.woocommerce.widget_price_filter
  .price_slider_wrapper
  .ui-widget-content
  .ui-slider-handle:last-child {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
}

#shop_page
  .widget.woocommerce.widget_price_filter
  .price_slider_wrapper
  .ui-widget-content
  .ui-slider-handle {
  position: absolute;
  top: -5px;
  height: 14px;
  width: 14px;
  background-color: #a65a6a;
  margin: 0 0 0 -1px;
  cursor: pointer;
  outline: 0;
  border-radius: 50%;
  z-index: 2;
}

#shop_page
  .widget.woocommerce.widget_price_filter
  .price_slider_wrapper
  .ui-widget-content
  .ui-slider-handle:after {
  background-color: #fff;
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  top: 3px;
  right: 3px;
  border-radius: 50%;
}

#shop_page .widget.woocommerce.widget_price_filter .price_slider_amount {
  margin: 12px 0 0;
  font-family: "ZonaPro-ExtraLight";
  color: #9f9f9f;
}

#shop_page .widget .eltdf-widget-title {
  margin: 0 0 20px;
  font-size: 18px;
  text-transform: uppercase;
}

#shop_page .widget.woocommerce.widget_product_categories {
  margin: 0 0 46px;
}

#shop_page .widget ul {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 13px;
  font-weight: 400;
}

#shop_page .widget-sizes ul {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
}

#shop_page .widget-sizes ul li {
  padding-right: 10px;
}

#shop_page .widget.woocommerce li {
  position: relative;
}

#shop_page .widget.woocommerce li:hover, #shop_page .widget.woocommerce li a:focus {
  font-weight: 700;
}

#shop_page .widget ul li {
  margin: 0 0 9px;
  font-family: "ZonaPro-ExtraLight";
}

#shop_page .widget ul li a {
  color: #9f9f9f;
}

/*COLOR PICKER */
.color_picker {
  font-size: 2em;
  vertical-align: middle;
  border-radius: 50%;
}

.color-flex {
  display: flex;
  flex-wrap: wrap;
  width: 60%;
}

.color-flex li {
  padding-right: 10px;
}
/*fundi COLOR PICKER */

.button-filter-price-shop {
  float: right;
  line-height: 30px;
  font-size: 15px;
  /* padding: 0 !important; */
  color: #a65a6a;
  background-color: transparent;
  border: 0;
  letter-spacing: 0.01em;
  vertical-align: middle;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: auto;
  margin: 0;
  font-family: "Zona Pro";
  font-size: 13px;
  line-height: 2.1em;
  font-weight: 600;
  outline: 0;
  box-sizing: border-box;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;
  white-space: nowrap;
  cursor: pointer;
  z-index: 3;
  text-transform: capitalize;
}

.button-filter-price-shop:hover {
  color: #a65a6a;
}

#shop_container_notfound_products h5,
#shop_container_notfound_products p {
  font-family: "Zona Pro";
  color: #a65a6a;
}

#shop_container_notfound_products .btn-not-found-products {
  font-family: "Zona Pro";
  background-color: #a65a6a;
  color: #ffffff;
}

#shop_page .widget.widget_media_image {
  margin: 0 0 18px;
}

#shop_page .widget.widget_media_image a,
.widget.widget_media_image img {
  display: block;
}

/*Seperator line on right sidebar */
#shop_page .widget.eltdf-separator-widget {
  margin: 0 !important;
}
#shop_page .eltdf-separator-holder.eltdf-separator-center {
  text-align: center;
}
#shop_page .eltdf-separator-holder {
  position: relative;
  height: auto;
  font-size: 0;
  line-height: 1em;
}
#shop_page
  .eltdf-separator-holder.eltdf-separator-custom-border-bottom
  .eltdf-separator {
  background-position: bottom left;
  background-repeat: repeat-x;
}

#shop_page
  .eltdf-separator-holder.eltdf-separator-custom-border-bottom
  .eltdf-separator,
.eltdf-separator-holder.eltdf-separator-custom-border-left .eltdf-separator,
.eltdf-separator-holder.eltdf-separator-custom-border-right .eltdf-separator,
.eltdf-separator-holder.eltdf-separator-custom-border-top .eltdf-separator {
  height: 2px;
  border: none !important;
}

#shop_page .eltdf-separator-holder.eltdf-separator-full-width .eltdf-separator {
  width: 100% !important;
}
#shop_page .eltdf-section-title-holder .eltdf-st-dashed-line,
.eltdf-separator-holder.eltdf-separator-custom-border-bottom .eltdf-separator,
.eltdf-separator-holder.eltdf-separator-custom-border-left .eltdf-separator,
.eltdf-separator-holder.eltdf-separator-custom-border-right .eltdf-separator,
.eltdf-separator-holder.eltdf-separator-custom-border-top .eltdf-separator {
  background-image: radial-gradient(
    circle at 0 100%,
    #000 1px,
    transparent 1px
  );
  background-size: 2px 2px;
}
#shop_page .eltdf-separator {
  margin: 10px 0;
}
#shop_page .eltdf-separator,
.eltdf-single-image-holder,
.eltdf-single-image-holder .eltdf-si-inner {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
/*Fundi Seperator line on right sidebar */

input[type="range"] {
  /* width: 210px; */
  width: 100%;
  height: 30px;
  overflow: hidden;
  cursor: pointer;
  outline: none;
}
input[type="range"],
input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 200px;
  height: 3px;
  background: #a65a6a;
}

input[type="range"]:nth-child(2)::-webkit-slider-runnable-track {
  background: none;
}

input[type="range"]::-webkit-slider-thumb {
  position: relative;
  height: 15px;
  width: 15px;
  margin-top: -7px;
  background: #fff;
  border: 1px solid #a65a6a;
  border-radius: 25px;
  z-index: 1;
}

input[type="range"]:nth-child(1)::-webkit-slider-thumb {
  z-index: 2;
}

.rangeslider {
  position: relative;
  height: 60px;
  /* width: 210px; */
  width: 100%;
  display: inline-block;
  margin-top: -5px;
}
.rangeslider input {
  position: absolute;
}
.rangeslider {
  position: absolute;
}

.rangeslider span {
  position: absolute;
  margin-top: 30px;
  left: 0;
}

.rangeslider .right {
  position: relative;
  float: right;
  margin-right: -5px;
}

/* Proof of concept for Firefox */
@-moz-document url-prefix() {
  .rangeslider::before {
    content: "";
    width: 100%;
    height: 2px;
    background: #003d7c;
    display: block;
    position: relative;
    top: 16px;
  }

  input[type="range"]:nth-child(1) {
    position: absolute;
    top: 35px !important;
    overflow: visible !important;
    height: 0;
  }

  input[type="range"]:nth-child(2) {
    position: absolute;
    top: 35px !important;
    overflow: visible !important;
    height: 0;
  }
  input[type="range"]::-moz-range-thumb {
    position: relative;
    height: 15px;
    width: 15px;
    margin-top: -7px;
    background: #fff;
    border: 1px solid #003d7c;
    border-radius: 25px;
    z-index: 1;
  }

  input[type="range"]:nth-child(1)::-moz-range-thumb {
    transform: translateY(-20px);
  }
  input[type="range"]:nth-child(2)::-moz-range-thumb {
    transform: translateY(-20px);
  }
}

/* Filters collapse for mobile -pjesa */
.Collapsible {
  background-color: white;
  margin: 0 0 43px;
  display: none; /* ne mobile e bojm display block */
}

.Collapsible__contentInner {
  padding: 10px;
  border: 1px solid #ebebeb;
  border-top: 0;
}
.Collapsible__contentInner p {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
}
.Collapsible__contentInner p:last-child {
  margin-bottom: 0;
}

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: #333333;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: #a06464;
  font-family: "Zona Pro";
  color: white;
}
.Collapsible__trigger:after {
  font-family: "FontAwesome";
  content: "\f107";
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  transition: transform 300ms;
  cursor: pointer;
}
.Collapsible__trigger.is-open:after {
  transform: rotateZ(180deg);
}
.Collapsible__trigger.is-disabled {
  opacity: 0.5;
  background-color: grey;
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #cbb700;
  color: black;
}
/* Filters collapse for mobile */

/*Fundi right sidebar */
.select_size input[type='radio'], .color_select input[type='radio'] {
  display: none;
}

.select_size label span {
  color: #9f9f9f;
  cursor: pointer;
}

.select_size input[type="radio"]:checked + label span {
  border-bottom: 1px solid #000;
  color: #a65a6a;
}

.color_select label span {
  cursor: pointer;
}

.color_select input[type="radio"]:checked + label span > i {
  background-image: url('../../assets/check-icn.svg') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.button-filter-product-shop:hover {
  background: #a65a6a;
  color: #fff;
}

.eltdf-onsale {
  left: 12px;
  background-color: red;
  font-family: Montserrat,sans-serif;
  font-weight: 400;
  font-size: 11px;
  text-transform: uppercase;
  position: absolute;
  display: block;
  top: 15px;
  width: 50px;
  height: 20px;
  margin: 0;
  padding: 0;
  color: #fff;
  line-height: 20px;
  letter-spacing: 1px;
  text-align: center;
  z-index: 100;
  box-sizing: border-box;
}

.eltdf-outofstock {
  right: 12px !important;
  background-color: red;
  font-family: Montserrat,sans-serif;
  font-weight: 400;
  font-size: 11px;
  text-transform: uppercase;
  position: absolute;
  display: block;
  top: 15px;
  width: 100px;
  height: 20px;
  margin: 0;
  padding: 0;
  color: #fff;
  line-height: 20px;
  letter-spacing: 1px;
  text-align: center;
  z-index: 100;
  box-sizing: border-box;
}

@media only screen and (min-width: 1025px) {
  .eltdf-woocommerce-columns-3
    .cross-sells
    > .products
    > .product:nth-child(3n + 1),
  .eltdf-woocommerce-columns-3
    .eltdf-pl-main-holder
    > .products
    > .product:nth-child(3n + 1),
  .eltdf-woocommerce-columns-3
    .related.products
    > .products
    > .product:nth-child(3n + 1),
  .eltdf-woocommerce-columns-3
    .upsells.products
    > .products
    > .product:nth-child(3n + 1) {
    clear: both;
  }
  .eltdf-woocommerce-columns-3 .cross-sells > .products > .product,
  .eltdf-woocommerce-columns-3 .eltdf-pl-main-holder > .products > .product,
  .eltdf-woocommerce-columns-3 .related.products > .products > .product,
  .eltdf-woocommerce-columns-3 .upsells.products > .products > .product {
    width: 33.33333%;
  }
}

@media only screen and (max-width: 1024px) {
  .eltdf-woocommerce-columns-3 .cross-sells > .products > .product,
  .eltdf-woocommerce-columns-3 .eltdf-pl-main-holder > .products > .product,
  .eltdf-woocommerce-columns-3 .related.products > .products > .product,
  .eltdf-woocommerce-columns-3 .upsells.products > .products > .product,
  .eltdf-woocommerce-columns-4 .cross-sells > .products > .product,
  .eltdf-woocommerce-columns-4 .eltdf-pl-main-holder > .products > .product,
  .eltdf-woocommerce-columns-4 .related.products > .products > .product,
  .eltdf-woocommerce-columns-4 .upsells.products > .products > .product,
  .woocommerce.columns-4 > .products > .product,
  .woocommerce.columns-5 > .products > .product,
  .woocommerce.columns-6 > .products > .product {
    width: 33.33333%;
  }

  #shop_page .eltdf-grid-col-9 {
    width: 100%;
    float: none;
  }

  #shop_page .eltdf-grid-col-3 {
    width: 100%;
    float: none;
  }
  #shop_page .woocommerce-pagination ul {
    text-align: left;
  }
}

@media only screen and (max-width: 768px) {
  .eltdf-woocommerce-columns-3 .cross-sells > .products > .product,
  .eltdf-woocommerce-columns-3 .eltdf-pl-main-holder > .products > .product,
  .eltdf-woocommerce-columns-3 .related.products > .products > .product,
  .eltdf-woocommerce-columns-3 .upsells.products > .products > .product,
  .eltdf-woocommerce-columns-4 .cross-sells > .products > .product,
  .eltdf-woocommerce-columns-4 .eltdf-pl-main-holder > .products > .product,
  .eltdf-woocommerce-columns-4 .related.products > .products > .product,
  .eltdf-woocommerce-columns-4 .upsells.products > .products > .product,
  .woocommerce.columns-3 > .products > .product,
  .woocommerce.columns-4 > .products > .product,
  .woocommerce.columns-5 > .products > .product,
  .woocommerce.columns-6 > .products > .product {
    width: 50%;
  }

  #shop_page ul.products > .product {
      padding: 0 0;
  }

  .desktop_filter {
    display: none !important;
  }

  .Collapsible {
    display: block !important;
  }

  .eltdf-pl-icons-holder {  
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    transform: translateX(0%);
    bottom: -1px;
    right: -1px;
    padding: 19px 0 10px;
    width: 100%;
    position: absolute;
    line-height: 0;
    -webkit-transition: 0.4s linear;
    -moz-transition: 0.4s linear;
    -ms-transition: 0.4s linear;
    -o-transition: 0.4s linear;
    transition: 0.4s linear;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  #shop_page ul.products > .product:hover .eltdf-pl-icons-holder {
    transform: none!important;
  }
}

@media only screen and (max-width: 680px) {
  .eltdf-woocommerce-columns-3 .cross-sells > .products > .product,
  .eltdf-woocommerce-columns-3 .eltdf-pl-main-holder > .products > .product,
  .eltdf-woocommerce-columns-3 .related.products > .products > .product,
  .eltdf-woocommerce-columns-3 .upsells.products > .products > .product,
  .eltdf-woocommerce-columns-4 .cross-sells > .products > .product,
  .eltdf-woocommerce-columns-4 .eltdf-pl-main-holder > .products > .product,
  .eltdf-woocommerce-columns-4 .related.products > .products > .product,
  .eltdf-woocommerce-columns-4 .upsells.products > .products > .product,
  .woocommerce.columns-2 > .products > .product,
  .woocommerce.columns-3 > .products > .product,
  .woocommerce.columns-4 > .products > .product,
  .woocommerce.columns-5 > .products > .product,
  .woocommerce.columns-6 > .products > .product {
    width: 100%;
  }
}
