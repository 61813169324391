[class*=sidebar-dark-] {
  background-color: #F4E4E3 !important;
}

[class*=sidebar-dark] .user-panel {
  border-bottom: 1px solid #A06464;
}

[class*=sidebar-dark-] .sidebar a {
  color: #A06464 !important;
}

[class*=sidebar-dark] .btn-sidebar, [class*=sidebar-dark] .form-control-sidebar {
  background-color: #F4E4E3;
  border: 1px solid #A06464;
  color: #A06464;
}

.card-danger:not(.card-outline)>.card-header {
  background-color: #A06464;
}

.color-palette {
  height: 35px;
  line-height: 35px;
  text-align: right;
  padding-right: 0.75rem;
}

.color-palette.disabled {
  text-align: center;
  padding-right: 0;
  display: block;
}

.color-palette-set {
  margin-bottom: 15px;
}

.color-palette span {
  display: none;
  font-size: 12px;
}

.color-palette:hover span {
  display: block;
}

.color-palette.disabled span {
  display: block;
  text-align: left;
  padding-left: 0.75rem;
}

.color-palette-box h4 {
  position: absolute;
  left: 1.25rem;
  margin-top: 0.75rem;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  display: block;
  z-index: 7;
}

.edit-imageabc12 {
  width: 160px;
  height: auto;
}
