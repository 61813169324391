@font-face {
    font-family: "ZonaPro-ExtraLight";
    src: url("../../zona-pro/FontsFree-Net-ZonaPro-Bold.ttf");
}

@font-face {
    font-family: "ZonaPro-ExtraLight";
    src: url("../../zona-pro/FontsFree-Net-ZonaPro-ExtraLight.ttf");
}

.product-item-space {
    width: 35%;
    padding: 0 10px;
    margin: 0 0 30px;
    /* float: left; */
    position: relative;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    cursor: pointer;
}
.product-pli-inner {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
}
.product-pli-image {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
}
.product-pli-image img {
    display: block;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: scale(1.06) rotate(.1deg);
    transition: transform .45s cubic-bezier(.33,.02,0,.93);
}
.product-pli-text-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    margin: 23px 0 38px;
}
.product-pli-text-wrapper .eltdf-pli-title>* {
    text-decoration: none;
}

.product-pli-text-wrapper .eltdf-pli-title a {
    color: #A06464;
}

.product-pli-text-wrapper .eltdf-pli-title {
    float: left;
    font-family: 'Zona Pro' !important;
}
.product-pli-text-wrapper .eltdf-pli-category {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .16em;
    line-height: 21px;
    margin: 0;
    width: 100%;
    display: inline-block;
    font-family: 'ZonaPro-Extralight';
    color: #ACABAB;
}
.product-pli-text-wrapper .eltdf-pli-category.price-products {
    font-family: 'Zona Pro' !important;
    color: #ACABAB;
    font-size: 24px !important;
}

@media only screen and (min-width: 1025px) {
    .product-item-space:nth-child(4n+1) {
        clear: both;
    }
}