.account_customers {
    margin-top: 80px;
}

.pb-30 {
    padding-bottom: 100px!important;
}

#customer_login .title_block {
    font-size: 18px;
    font-family: "Zona Pro";
    color: #a65a6a;
    font-weight: 700;
    margin: 0;
    border-bottom: 1px solid #e5e5e8;
    position: relative;
    padding-bottom: 8px;
    margin-bottom: 15px;
}

#customer_login .title_block:after {
    content: '';
    display: block;
    width: 170px;
    height: 1px;
    background: #a65a6a;
    position: absolute;
    bottom: -1px;
    left: 0;
}

#customer_login .block-form-login .title_form {
    font-family: Spartan,sans-serif;
    font-size: 13px;
    color: #666;
    font-weight: 400;
    margin: 0 0 15px;
}

#customer_login .block-form-login .form-group {
    margin-bottom: 10px;
    /* margin-right: 50px; */
}

#customer_login .errors ul li {
    font-weight: 400;
    font-size: 18px;
    color:#ff3d27;
}

#CustomerAccountForm .form-group, #RecoverPasswordForm .form-group, #create_customer .form-group, #customer_login .form-group {
    position: relative;
}

/* #CustomerAccountForm .form-group.novform-email:before, #RecoverPasswordForm .form-group.novform-email:before, #create_customer .form-group.novform-email:before, #customer_login .form-group.novform-email:before {
    width: 16px;
    height: 12px;
    background-position: 0 -18px;
}

#CustomerAccountForm .form-group:before, #RecoverPasswordForm .form-group:before, #create_customer .form-group:before, #customer_login .form-group:before {
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 15px;
    background: url(//cdn.shopify.com/s/files/1/0256/4594/0810/t/66/assets/icon-popup-login.png?v=1096982…) no-repeat;
    background-position: 0 0;
    z-index: 9;
} */

#CustomerAccountForm .form-group input:not([type=submit]), #RecoverPasswordForm .form-group input:not([type=submit]), #create_customer .form-group input:not([type=submit]), #customer_login .form-group input:not([type=submit]) {
    border: 1px solid #e5e5e8;
    padding: 0;
    padding-left: 15px;
    padding-right: 30px;
    height: 46px;
    width: 100%;
    margin-bottom: 0;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    font-family: Spartan,sans-serif;
    color: #666;
    font-weight: 400;
}

/* #CustomerAccountForm .form-group:after, #RecoverPasswordForm .form-group:after, #create_customer .form-group:after, #customer_login .form-group:after {
    content: "";
    width: 6px;
    height: 44px;
    background: url(//cdn.shopify.com/s/files/1/0256/4594/0810/t/66/assets/icon-popup-login.png?v=1096982…) no-repeat;
    background-position: -19px 0;
    position: absolute;
    top: 1px;
    left: 45px;
    z-index: 9;
} */

#CustomerAccountForm .form-group input:not([type=submit]):hover, #RecoverPasswordForm .form-group input:not([type=submit]):hover, #create_customer .form-group input:not([type=submit]):hover, #customer_login .form-group input:not([type=submit]):hover {
    border: 1px solid #a65a6a;
}

#CustomerAccountForm .form-group input:not([type=submit]):focus, #RecoverPasswordForm .form-group input:not([type=submit]):focus, #create_customer .form-group input:not([type=submit]):focus, #customer_login .form-group input:not([type=submit]):focus {
    outline: none;
}

#customer_login .bank_register {
    font-family: Spartan,sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #666;
    margin: 10px 0 20px;
}

#customer_login .bank_register a {
    font-family: 'Zona Pro';
    font-weight: 600;
    font-size: 12px;
    color: #a65a6a;
}

#customer_login .block-form-login .btn {
    font-family: Spartan,sans-serif;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    color: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    padding: 12px 0;
    min-width: 118px;
    background: #a65a6a;
}

@media (max-width: 575px) {
    .col-xs-12 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        width: 100%;
        max-width: 100%;
    }
}

@media only screen and (max-width: 680px) {
    .account_customers {
        margin-top: 10px;
    }

    .login-container {
        width: 307px !important;
    }
    #customer_login .block-form-login .form-group {
        margin-right: 0;
    }
}