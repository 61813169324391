@font-face {
    font-family: "ZonaPro-ExtraLight";
    src: url("../../zona-pro/FontsFree-Net-ZonaPro-Bold.ttf");
}

@font-face {
    font-family: "ZonaPro-ExtraLight";
    src: url("../../zona-pro/FontsFree-Net-ZonaPro-ExtraLight.ttf");
}

#yith-quick-view-modal .related.products, #yith-quick-view-modal .upsells.products, .eltdf-woo-single-page .related.products, .eltdf-woo-single-page .upsells.products {
    margin: 30px 0 0;
}

.cross-sells, .eltdf-content .woocommerce, .eltdf-pl-main-holder, .related.products, .upsells.products {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
}

#related_products .eltdf-woo-normal-space ul.products {
    margin: 0 -15px;
}

#related_products .eltdf-woo-normal-space ul.products>.product {
    padding: 0 15px;
    margin: 0 0 30px;
    cursor: pointer;
}

#related_products ul.products>.product .eltdf-pl-inner {
    will-change: transform;
    -webkit-filter: blur();
    filter: blur();
}

#related_products #yith-quick-view-modal .related.products>h2, #yith-quick-view-modal .upsells.products>h2, .eltdf-woo-single-page .related.products>h2, .eltdf-woo-single-page .upsells.products>h2 {
    font-size: 25px;
    line-height: 1.12em;
    color: #000;
    display: block;
    text-align: left;
    margin: 25px 0 53px;
    text-align: center;
}

#related_products ul.products>.product .eltdf-pl-inner, ul.products>.product .eltdf-pl-inner .eltdf-pl-image {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    overflow: hidden;
}

#related_products ul.products>.product .eltdf-pl-inner, ul.products>.product .eltdf-pl-inner .eltdf-pl-image {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    overflow: hidden;
}

#related_products #yith-quick-view-modal .related.products .product .eltdf-pl-image img, #yith-quick-view-modal .upsells.products .product .eltdf-pl-image img, .eltdf-woo-single-page .related.products .product .eltdf-pl-image img, .eltdf-woo-single-page .upsells.products .product .eltdf-pl-image img {
    -webkit-transform: scale(1.06);
    -moz-transform: scale(1.06);
    transform: scale(1.06);
    -webkit-transition: -webkit-transform .4s cubic-bezier(.54,.01,.58,1);
    -moz-transition: -moz-transform .4s cubic-bezier(.54,.01,.58,1);
    transition: transform .4s cubic-bezier(.54,.01,.58,1);
}

#related_products #yith-quick-view-modal .related.products .product .eltdf-pl-text, #yith-quick-view-modal .upsells.products .product .eltdf-pl-text, .eltdf-woo-single-page .related.products .product .eltdf-pl-text, .eltdf-woo-single-page .upsells.products .product .eltdf-pl-text {
    opacity: 1;
    background: 0 0;
}

#related_products .eltdf-woo-pl-info-below-image ul.products .eltdf-pl-inner .eltdf-pl-text {
    padding: 0;
}

/*Kta kod me shkru nuk bon hoveri i ikonave */
/* #related_products ul.products>.product .eltdf-pl-inner .eltdf-pl-text {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 10px;
    opacity: 0;
    box-sizing: border-box;
    -webkit-transition: opacity .2s ease-out;
    -moz-transition: opacity .2s ease-out;
    transition: opacity .2s ease-out;
}

#related_products ul.products>.product .eltdf-pl-inner .eltdf-pl-text-outer {
    position: relative;
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
    text-align: center;
}

#related_products ul.products>.product .eltdf-pl-inner .eltdf-pl-text-inner {
    position: relative;
    display: table-cell;
    height: 100%;
    width: 100%;
    vertical-align: middle;
} */
/*Kta kod me shkru nuk bon hoveri i ikonave */

#related_products .eltdf-woo-pl-info-below-image ul.products .eltdf-pl-inner .eltdf-pl-text .eltdf-pl-text-inner .eltdf-pl-icons-holder {
    /* position: absolute;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    flex-direction: row-reverse;
    padding: 0 8px;
    bottom: -1px;
    right: -1px;
    background-color: #fff;
    z-index: 111;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: transform .35s cubic-bezier(.34,.18,0,.9);
    -moz-transition: transform .35s cubic-bezier(.34,.18,0,.9);
    transition: transform .35s cubic-bezier(.34,.18,0,.9); */

    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    transform: translateX(100%);
    bottom: -1px;
    right: -1px;
    padding: 19px 0 10px;
    width: 100%;
    position: absolute;
    line-height: 0;
    -webkit-transition: 0.4s linear;
    -moz-transition: 0.4s linear;
    -ms-transition: 0.4s linear;
    -o-transition: 0.4s linear;
    transition: 0.4s linear;
    display: flex;
    justify-content: center;
    align-items: center;
}

#related_products #yith-quick-view-modal .related.products .product .eltdf-pl-text-wrapper, #yith-quick-view-modal .upsells.products .product .eltdf-pl-text-wrapper, .eltdf-woo-single-page .related.products .product .eltdf-pl-text-wrapper, .eltdf-woo-single-page .upsells.products .product .eltdf-pl-text-wrapper {
    margin-bottom: 0;
}

#related_products ul.products>.product .eltdf-pl-text-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    margin: 20px 0 38px;
}

#related_products ul.products>.product .eltdf-product-list-title {
    float: left;
    width: 100%;
    margin: 0;
    line-height: 21px;
}


#related_products ul.products>.product .eltdf-product-list-title a {
    font-family: 'Zona Pro';
    color: #A65A6A;
}

#related_products ul.products>.product .price {
    font-family: 'Zona Pro';
    color:#ACABAB;
}

#related_products ul.products>.product .eltdf-pl-category {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .16em;
    line-height: 21px;
    float: left;
    width: 100%;
    margin: 4px 0 0;
}
#related_products ul.products>.product .eltdf-pl-category a {
    position: relative;
    font-family: 'ZonaPro-Extralight';
    color: #ACABAB;
    font-size: 14px;
}

/*Hover Icons */
#related_products .yith-cart-button:after {
    content: "\f290";
    font-size: 17px;
    line-height: 43px;
    font-family: FontAwesome;
    color:#A65A6A;
}

#related_products .yith-quickview-button::after {
    content: "\f06e";
    font-size: 17px;
    line-height: 43px;
    font-family: FontAwesome;
    color:#A65A6A;
}

#related_products .yith-heart-button:after {
    content: "\f004";
    font-size: 17px;
    line-height: 43px;
    font-family: FontAwesome;
    color:#A65A6A;
}

/* Hover effects */
#related_products ul.products>.product .eltdf-pl-inner:hover .eltdf-pl-text .eltdf-pl-text-inner .eltdf-pl-icons-holder {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
}

@media only screen and (min-width: 1025px) {
    #related_products .eltdf-woocommerce-columns-4 .cross-sells>.products>.product:nth-child(4n+1), .eltdf-woocommerce-columns-4 .eltdf-pl-main-holder>.products>.product:nth-child(4n+1), .eltdf-woocommerce-columns-4 .related.products>.products>.product:nth-child(4n+1), .eltdf-woocommerce-columns-4 .upsells.products>.products>.product:nth-child(4n+1), .woocommerce.columns-4>.products>.product:nth-child(4n+1) {
        clear: both;
    }

    #related_products  .eltdf-woocommerce-columns-4 .cross-sells>.products>.product, .eltdf-woocommerce-columns-4 .eltdf-pl-main-holder>.products>.product, .eltdf-woocommerce-columns-4 .related.products>.products>.product, .eltdf-woocommerce-columns-4 .upsells.products>.products>.product {
        width: 25%;
    }
}