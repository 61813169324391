.loader {
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 11221654878;
}

.load-wrapp:last-child {
    margin-right: 0;
}

.load-wrapp {
    position: fixed;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    text-align: center;
    background-color: #ffffff;
}

.letter-holder {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    vertical-align: middle;
}

.load-6 .letter {
    /* animation-name: loadingF; */
    animation: loaderanimation 2s infinite;
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
    animation-direction: linear;
}

.letter {
    float: left;
    font-size: 18px;
    color: #A65A6A;
}

.l-1 {
    animation-delay: 0.48s;
}

.l-2 {
    animation-delay: 0.6s;
}

.l-3 {
    animation-delay: 0.72s;
}

.l-4 {
    animation-delay: 0.84s;
}

.l-5 {
    animation-delay: 0.96s;
}

.l-6 {
    animation-delay: 1.08s;
}

.l-7 {
    animation-delay: 1.2s;
}

.l-8 {
    animation-delay: 1.32s;
}

.l-9 {
    animation-delay: 1.44s;
}

.l-10 {
    animation-delay: 1.56s;
}

@keyframes loaderanimation {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.1, 1.1);
    }
    100% {
        transform: scale(1, 1);
    }
}

@-moz-keyframes loaderanimation {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.1, 1.1);
    }
    100% {
        transform: scale(1, 1);
    }
}

@-ms-keyframes loaderanimation {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.1, 1.1);
    }
    100% {
        transform: scale(1, 1);
    }
}

@-webkit-keyframes loaderanimation {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.1, 1.1);
    }
    100% {
        transform: scale(1, 1);
    }
}

@-o-keyframes loaderanimation {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.1, 1.1);
    }
    100% {
        transform: scale(1, 1);
    }
}