@font-face {
    font-family: "ZonaPro-ExtraLight";
    src: url("../../zona-pro/FontsFree-Net-ZonaPro-Bold.ttf");
}

@font-face {
    font-family: "ZonaPro-ExtraLight";
    src: url("../../zona-pro/FontsFree-Net-ZonaPro-ExtraLight.ttf");
}

.eltdf-page-header {
    margin: 0;
    padding: 0;
    /* z-index: 110; */
}

.eltdf-sticky-header {
    position: fixed !important;
    top: 0%;
    left: 0;
    width: 100%;
    z-index: 101;
    box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px;
}

.eltdf-page-header {
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    position: relative;
    box-sizing: border-box;
}

.eltdf-page-header .eltdf-menu-area {
    position: relative;
    height: 90px;
    background-color: #fff;
    box-sizing: border-box;
}

.page-id-4584 .eltdf-page-header .eltdf-vertical-align-containers, .page-id-4584 .eltdf-top-bar .eltdf-vertical-align-containers {
    padding-left: 2%;
    padding-right: 2%;
}

.eltdf-page-header .eltdf-vertical-align-containers {
    /* padding: 0 40px; */
}

.eltdf-vertical-align-containers {
    position: relative;
    width: 100%;
    height: 100%;
    /* padding: 0 20px; */
    box-sizing: border-box;
}

.eltdf-vertical-align-containers .eltdf-position-left {
    position: relative;
    height: 100%;
    float: left;
    z-index: 2;
}

.eltdf-vertical-align-containers .eltdf-position-center:before, .eltdf-vertical-align-containers .eltdf-position-left:before, .eltdf-vertical-align-containers .eltdf-position-right:before {
    content: "";
    /* height: 100%; */
    display: inline-block;
    vertical-align: middle;
    margin-right: 0;
}

.eltdf-header-bottom .eltdf-menu-area .eltdf-position-left-inner, .eltdf-header-bottom .eltdf-menu-area .eltdf-position-right-inner {
    height: 100%;
}

.eltdf-header-bottom .eltdf-menu-area .eltdf-bottom-menu-left-widget-holder {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    height: 100%;
}

.eltdf-header-bottom .eltdf-logo-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    vertical-align: middle;
    line-height: 1;
}

.eltdf-page-header .eltdf-logo-area .eltdf-logo-wrapper a, .eltdf-page-header .eltdf-menu-area .eltdf-logo-wrapper a {
    max-height: 90px;
}

.eltdf-logo-wrapper a {
    position: relative;
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
}

.eltdf-logo-wrapper a img.eltdf-normal-logo {
    opacity: 1;
}

.eltdf-logo-wrapper a img {
    height: 100%;
    width: auto;
    -webkit-transition: opacity .2s ease;
    -moz-transition: opacity .2s ease;
    transition: opacity .2s ease;
}

.eltdf-page-header a img {
    display: block;
}

a img, table {
    border: none;
}

.wp-caption, img {
    height: auto;
    max-width: 100%;
}

.eltdf-vertical-align-containers .eltdf-position-center {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 1;
}

.eltdf-header-bottom .eltdf-position-center-inner {
    height: 100%;
}
 
.eltdf-cart-text-holder {
    display: inline-block;
    position: absolute;
    width: 25px;
    height: 25px;
    line-height: 20px;
    background: #A65A6A;
    color: #fff;
    border-radius: 100%;
    text-align: center;
    font-weight: 600;
    top: -30%;
}

.eltdf-main-menu {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: auto;
}

.eltdf-main-menu>ul {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    height: 100%;
}

.eltdf-main-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
}

.eltdf-main-menu>ul>li>a.active>span:before {
    content: '';
    position: absolute;
    bottom: 0;
    top: 30px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: currentColor;
    width: 100%;
    transform: scale(1,1);
    transform-origin: 0 50%;
    transition: transform .4s cubic-bezier(.28,.38,0,.81);
}

.clearfix:after, .clearfix:before {
    content: " ";
    display: table;
}

.eltdf-main-menu>ul>li {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}

.eltdf-main-menu ul li {
    float: left;
    padding: 0;
    margin: 0;
}
.eltdf-main-menu>ul>li>a {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 0 20px;
    font-size: 30px;
    line-height: 23px;
    box-sizing: border-box;
    font-weight: 600;
    font-family: "ZonaPro-ExtraLight";
    color:#A65A6A;
    text-decoration: none !important;
}

.eltdf-main-menu>ul>li>a.active {
    font-family: 'Zona Pro';
    color:#A65A6A;
}

.eltdf-main-menu>ul>li>a>span.item_outer {
    position: relative;
    display: block;
}

.eltdf-main-menu>ul>li.eltdf-active-item>a>span:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: currentColor;
    width: 100%;
    transform: scale(1,1);
    transform-origin: 0 50%;
    transition: transform .4s cubic-bezier(.28,.38,0,.81);
}

.eltdf-main-menu>ul>li>a>span.item_outer .item_text {
    position: relative;
    display: inline-block;
    vertical-align: top;
}

.eltdf-main-menu>ul>li.has_sub>a .eltdf-menu-arrow {
    display: none;
}

.eltdf-vertical-align-containers .eltdf-position-right {
    position: relative;
    height: 100%;
    float: right;
    text-align: right;
    z-index: 2;
}

.eltdf-header-bottom .eltdf-header-bottom-opener-outer {
    display: table;
    height: 100%;
}

.eltdf-header-bottom .eltdf-header-bottom-opener-inner {
    position: relative;
    display: table-cell;
    height: 100%;
    width: 100%;
    vertical-align: middle;
}

.eltdf-search-opener {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    margin: 0 10px;
    font-size: 16px;
}

.eltdf-search-opener .eltdf-search-opener-wrapper {
    display: block;
}

.eltdf-search-opener.eltdf-search-opener-svg-path .eltdf-search-opener-wrapper>* {
    vertical-align: middle;
}

.eltdf-search-opener .eltdf-search-opener-wrapper .eltdf-search-icon-text {
    font-size: 14px;
    line-height: 23px;
    box-sizing: border-box;
    font-weight: 600;
    margin-left: 6px;
}

.eltdf-search-opener .eltdf-search-opener-wrapper .eltdf-search-icon-text:before {
    transform: scale(0,1);
    transform-origin: 100% 50%;
}

.eltdf-wishlist-widget-holder {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    margin: 0 15px;
    font-size: 17px;
}

/* HOVER */
.eltdf-shopping-cart-dropdown {
    position: absolute;
    top: calc(100% + 50px);
    left: auto;
    right: 0;
    width: 297px;
    background-color: #fff;
    text-align: left;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    box-sizing: border-box;
    box-shadow: 0 5px 5px 0 rgb(0 0 0 / 3%);
    -webkit-transition: all .35s cubic-bezier(.53,.01,.18,1);
    -moz-transition: all .35s cubic-bezier(.53,.01,.18,1);
    transition: all .35s cubic-bezier(.53,.01,.18,1);
}
.eltdf-shopping-cart-dropdown .eltdf-header-cart {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    line-height: 1;
    font-family: Poppins,sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #000;
    letter-spacing: .01em;
}
.eltdf-shopping-cart-dropdown ul {
    margin: 30px;
    padding: 0;
}
.eltdf-shopping-cart-dropdown .eltdf-header-cart {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    line-height: 1;
    font-family: Poppins,sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #000;
    letter-spacing: .01em;
}
.eltdf-shopping-cart-dropdown ul li {
    position: relative;
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
    padding: 0 0 20px;
    box-sizing: border-box;
}
.eltdf-shopping-cart-dropdown .eltdf-item-image-holder {
    position: relative;
    display: table-cell;
    height: 100%;
    width: 77px;
    vertical-align: top;
}
.eltdf-shopping-cart-dropdown .eltdf-item-info-holder {
    position: relative;
    display: table-cell;
    height: 100%;
    width: 100%;
    vertical-align: middle;
    padding-left: 16px;
}
.eltdf-shopping-cart-dropdown .eltdf-item-info-holder .eltdf-product-title {
    display: block;
    margin: 0;
    padding: 0 20px 0 0;
    box-sizing: border-box;
    font-size: 16px;
}
.eltdf-shopping-cart-dropdown .eltdf-item-info-holder .eltdf-product-title a {
    font-family: 'Zona Pro';
    color: #A65A6A;
}

.eltdf-shopping-cart-dropdown .eltdf-item-info-holder .eltdf-quantity:after {
    content: 'X';
    font-size: 11px;
}
.eltdf-shopping-cart-dropdown .eltdf-item-info-holder .amount, .eltdf-shopping-cart-dropdown .eltdf-item-info-holder .eltdf-quantity {
    display: inline-block;
    margin: 3px 0 0;
    font-family: 'ZonaPro-ExtraLight';
    color: #ACABAB;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: .16em;
    font-weight: 400;
}
.eltdf-shopping-cart-dropdown .eltdf-item-info-holder .woocommerce-Price-currencySymbol {
    position: relative;
    top: -.5em;
    font-size: 7px;
}
.eltdf-shopping-cart-dropdown .eltdf-item-info-holder .remove {
    position: absolute;
    top: -1px;
    right: 0;
    font-size: 22px;
    line-height: 1;
}
.eltdf-shopping-cart-dropdown .eltdf-item-info-holder .remove span, .eltdf-shopping-cart-dropdown .eltdf-item-info-holder .remove span:before {
    display: block;
    line-height: inherit;
}
.eltdf-shopping-cart-dropdown .eltdf-item-info-holder .remove span, .eltdf-shopping-cart-dropdown .eltdf-item-info-holder .remove span:before {
    display: block;
    line-height: inherit;
    color: #BAB9B9;
    font-size: 16px;
}
header .icon-arrows-remove:before {
    content: "x";
}
.eltdf-shopping-cart-dropdown ul li:last-child {
    padding-bottom: 0;
}
.eltdf-shopping-cart-dropdown .eltdf-cart-bottom {
    border-top: 1px solid #e5e5e5;
    padding-top: 13px;
}
.eltdf-shopping-cart-dropdown .eltdf-cart-bottom .eltdf-subtotal-holder {
    position: relative;
    display: table;
    width: 100%;
    margin: 0 0 25px;
    font-size: 18px;
    font-weight: 600;
}
.eltdf-shopping-cart-dropdown .eltdf-cart-bottom .eltdf-subtotal-holder>* {
    display: table-cell;
    vertical-align: top;
    color: #000;
    line-height: 20px;
}
.eltdf-shopping-cart-dropdown .eltdf-cart-bottom .eltdf-subtotal-holder .eltdf-total-amount {
    text-align: right;
    font-size: 16px;
}
.eltdf-shopping-cart-dropdown .eltdf-cart-bottom .eltdf-subtotal-holder>* {
    display: table-cell;
    vertical-align: top;
    font-family: 'Zona Pro';
    color: #A65A6A;
    line-height: 20px;
}
.eltdf-shopping-cart-dropdown .eltdf-cart-bottom .eltdf-subtotal-holder .eltdf-total-amount .woocommerce-Price-currencySymbol {
    position: relative;
    /* top: -.5em; */
    font-size: 16px;
}
.eltdf-shopping-cart-dropdown .eltdf-cart-bottom .eltdf-view-cart, .eltdf-shopping-cart-dropdown .eltdf-cart-bottom .eltdf-view-checkout {
    position: relative;
    display: inline-block;
    width: auto;
    margin: 0;
    font-family: 'ZonaPro-ExtraLight';
    font-size: 13px;
    line-height: 2.1em;
    font-weight: 600;
    outline: 0;
    box-sizing: border-box;
    -webkit-transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out;
    -moz-transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out;
    transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out;
    padding: 0!important;
    color: #ACABAB !important;
    background-color: transparent!important;
    border: 0;
    letter-spacing: .01em;
    vertical-align: middle;
}
.eltdf-shopping-cart-dropdown .eltdf-cart-bottom .eltdf-view-checkout {
    float: left;
}
.eltdf-shopping-cart-dropdown .eltdf-cart-bottom .eltdf-view-cart {
    float: right;
}
.eltdf-shopping-cart-dropdown .eltdf-cart-bottom .eltdf-view-cart:before, .eltdf-shopping-cart-dropdown .eltdf-cart-bottom .eltdf-view-checkout:before {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: currentColor;
    width: 100%;
    transform: scale(1,1);
    transform-origin: 0 50%;
    transition: transform .4s cubic-bezier(.28,.38,0,.81);
}

.bag-header-link:hover .eltdf-shopping-cart-dropdown {
    top: 100%;
    opacity: 1;
    visibility: visible;
} 

/* kjo kur t bojm hover mbi checkout dhe view cart, ne fund qe jane tek shoping cart dropdown */
.eltdf-shopping-cart-dropdown .eltdf-cart-bottom .eltdf-view-cart:hover:before, .eltdf-shopping-cart-dropdown .eltdf-cart-bottom .eltdf-view-checkout:hover:before {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: currentColor;
    width: 100%;
    transform: scale(0,1);
    transform-origin: 100% 50%;
    transition: transform .4s cubic-bezier(.28,.38,0,.81);
}
/* kjo kur t bojm hover mbi checkout dhe view cart tek shoping cart dropdown */

.eltdf-shopping-cart-dropdown ul li.eltdf-empty-cart {
    font-family: 'Zona Pro';
    color: #A65A6A;
    border-bottom: 0;
}

/*Fundi Hover */

/*Mobile Menu */
.eltdf-mobile-header {
    position: relative;
    display: none;
    z-index: 110;
}
.eltdf-mobile-header .eltdf-mobile-header-inner {
    position: relative;
    height: 140px;
    background-color: #fff;
    box-sizing: border-box;
}
.eltdf-mobile-header .eltdf-mobile-header-inner .eltdf-mobile-header-holder {
    position: relative;
    z-index: 2;
    height: 100%;
}
.eltdf-mobile-header .eltdf-grid {
    height: 100%;
}
.eltdf-grid {
    margin: 0 auto;
}
.eltdf-mobile-header .eltdf-vertical-align-containers {
    padding: 0;
}
.eltdf-vertical-align-containers .eltdf-position-left {
    position: relative;
    height: 100%;
    float: left;
    z-index: 2;
}
.eltdf-vertical-align-containers .eltdf-position-center:before, .eltdf-vertical-align-containers .eltdf-position-left:before, .eltdf-vertical-align-containers .eltdf-position-right:before {
    content: "";
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0;
}
.eltdf-rev-has-paspartu, .eltdf-vertical-align-containers .eltdf-position-center-inner, .eltdf-vertical-align-containers .eltdf-position-left-inner, .eltdf-vertical-align-containers .eltdf-position-right-inner {
    display: inline-block;
    vertical-align: middle;
}
.eltdf-mobile-header .eltdf-mobile-logo-wrapper img {
    display: block;
    height: 100%;
    width: auto;
}
.eltdf-mobile-header .eltdf-vertical-align-containers .eltdf-position-right {
    max-width: 80%;
}
.eltdf-vertical-align-containers .eltdf-position-right {
    position: relative;
    height: 100%;
    float: right;
    text-align: right;
    z-index: 2;
}
.eltdf-mobile-header .eltdf-mobile-menu-opener {
    position: relative;
    display: flex;
    vertical-align: middle;
    z-index: 2;
}
.eltdf-mobile-header .eltdf-mobile-menu-opener a {
    display: table;
    height: 100%;
}
.eltdf-mobile-header .eltdf-mobile-menu-opener a>* {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    line-height: 1em;
}
.eltdf-mobile-header .eltdf-mobile-menu-opener.eltdf-mobile-menu-opener-svg-path .eltdf-mobile-menu-icon path, .eltdf-mobile-header .eltdf-mobile-menu-opener.eltdf-mobile-menu-opener-svg-path .eltdf-mobile-menu-icon>* {
    fill: currentColor;
    stroke: currentColor;
}

/*Left side mobile nav(kur klikojm ne ikonen e hamburgerit) */
/* left:0 kena me bo -300p% per me mshef */
.lahb-responsive-menu-wrap {
    position: fixed;
    top: 0;
    width: 300px;
    max-width: 100vw;
    height: 100%;
    background: #F4E4E3;
    /* z-index: 4; */
    z-index: 30;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 100px;
    color: silver;
}
.close-responsive-nav {
    display: block;
    position: absolute;
    /* top: 60px;
    right: 37px; */
    top: 40px;
    left: 15px;
    z-index: 2;
    cursor: pointer;
}
.lahb-menu-cross-icon {
    position: relative;
    margin-top: 7px;
    margin-bottom: 7px;
}
.lahb-menu-cross-icon.open:before {
    transform: translateY(7px) rotate(45deg);
}
.close-responsive-nav .lahb-menu-cross-icon:before, .close-responsive-nav .lahb-menu-cross-icon:after {
    background-color: #A65A6A;
}
.lahb-menu-cross-icon:before {
    top: -7px;
}
.lahb-menu-cross-icon:before, .lahb-menu-cross-icon:after {
    position: absolute;
    content: "";
    display: block;
    width: 25px;
    height: 2px;
    transition: background-color .18s ease 0.4s,transform .2s ease .38s;
}
.lahb-menu-cross-icon.open:after {
    transform: translateY(-7px) rotate(-45deg);
}
.lahb-menu-cross-icon:after {
    top: 7px;
}
.responav {
    margin: 0;
    padding: 0;
}
.responav .mm-menu-item {
    position: relative;
    width: 100%;
}
.responav ul, .responav li {
    list-style: none;
    margin: 0;
    padding: 0;
}
.nav__wrap_1570441659347 li.mm-menu-item > .top-level-link, .lahb-responsive-menu-1570441659347 .top-level-link {
    font-weight: 500;
    padding-right: 20px;
    padding-left: 20px;;
}
.responav .sub-level-link, .responav .top-level-link {
    display: block;
    padding: 10px 20px;
    position: relative;
    font-family: 'ZonaPro-Extralight';
    color: #A65A6A;
}
.responav .sub-level-link, .responav .top-level-link.active {
    font-family: 'Zona Pro';
    color: #A65A6A;
}
.menu .text-wrap {
    position: relative;
}
.menu .text-wrap:after {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 1.7em;
    width: 20%;
    border-bottom: 2px solid;
    content: "";
    z-index: 1;
    opacity: 0;
    transition: width .5s ease,opacity .3s ease;
}

.lahb-responsive-menu-wrap {
    left: -300%;
}

.lahb-responsive-menu-wrap.open {
    left: 0%;
}


/*Mobile Menu */

/*Responsive */
@media (max-width: 1279px) {
    .lahb-responsive-menu-icon-wrap, .lahb-responsive-menu-wrap {
        opacity: 1;
        visibility: visible;
    }
    .nav__wrap_1570441659347 li.mm-menu-item > .top-level-link, .lahb-responsive-menu-1570441659347 .top-level-link {
        font-size: 18px;
    }
}
@media only screen and (max-width: 1200px) {
    .eltdf-container-inner, .eltdf-grid, .eltdf-row-grid-section {
        width: 950px;
    }
}
@media only screen and (max-width: 1024px) {
    .eltdf-page-header {
        display: none;
    }
    .eltdf-mobile-header {
        display: block;
    }
    .page-id-4584 .eltdf-content .eltdf-content-inner > .eltdf-container > .eltdf-container-inner, .page-id-4584 .eltdf-content .eltdf-content-inner > .eltdf-full-width > .eltdf-full-width-inner {
        padding: 0 0 0 0 !important;
    }
    .eltdf-search-opener {
       margin: 0 25px;
    }
    .eltdf-menu-opener-wrapper {
        margin: 0 25px 0 0;
    }
}

@media only screen and (max-width: 1024px) {
    .eltdf-container-inner, .eltdf-grid, .eltdf-row-grid-section {
        width: 768px;
    }
}

@media (max-width: 767px) {
    .eltdf-mobile-header .eltdf-mobile-logo-wrapper img {
        width: 100px;
    }
}

