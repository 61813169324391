@font-face {
    font-family: "ZonaPro-ExtraLight";
    src: url("../../zona-pro/FontsFree-Net-ZonaPro-Bold.ttf");
}

@font-face {
    font-family: "ZonaPro-ExtraLight";
    src: url("../../zona-pro/FontsFree-Net-ZonaPro-ExtraLight.ttf");
}

#section_footer {
    z-index: 110;
}
.kc-css-985990 {
    background: #FFF4F3;
}

.kc_row:not(.kc_row_inner) {
    clear: both;
    float: left;
    width: 100%;
}

*, .kc-col-container, .kc-container, .kc-elm {
    box-sizing: border-box;
}

.kc-row-container.kc-container .kc-wrap-columns, .kc_row_inner {
    margin-left: -15px;
    margin-right: -15px;
    clear: both;
}

div.kc_column, div.kc_column_inner {
    clear: none;
}

.kc_column, .kc_column_inner {
    min-height: 1px;
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
    float: left;
}

.kc_column>.kc-col-container, .kc_column_inner>.kc-col-inner-container {
    height: 100%;
}

.kc-col-container {
    clear: both;
    display: block;
    width: 100%;
}

.kc_col-sm-12 {
    width: 100%;
}

.kc-css-859427 {
    margin-bottom: 60px;
    /* padding-top: 90px; */
    padding-top: 45px;
    /* padding-bottom: 55px; */
    /* border-bottom: 1px solid #293746; */
}

.kc_row.kc_row_inner {
    width: calc(100% + 30px);
}

.kc-col-container > div {
    position: relative;
}

.kc-col-container > div {
    position: relative;
}

.kc-css-976240 {
    border-top: 1px solid #D27F8B;   
}

.kc_row.kc_row_inner {
    width: calc(100% + 30px);
}

.kc_column, .kc_column_inner {
    min-height: 1px;
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
    float: left;
}

.kc_col-sm-3 {
    width: 20%;
}

.kc_column>.kc-col-container, .kc_column_inner>.kc-col-inner-container {
    height: 100%;
}

.kc-css-889399 .bgr_icon i, body.kc-css-system .kc-css-889399 .bgr_img, body.kc-css-system .kc-css-889399 {
    text-align: center;
}

.kc-css-889399 {
    display: inline;
    margin-right: 40px;
    margin-bottom: 0px;
    padding-top: 12px;
    padding-bottom: 8px;
    text-align: center;
}

.ssc_icon_box.t_icon_left > div, .ssc_icon_box.t_icon_right > div {
    display: table-cell;
}

.ssc_icon_box .icon_box {
    width: 10px;
}

.kc-css-889399 .bgr_icon i, body.kc-css-system .kc-css-889399 .bgr_img img, body.kc-css-system .kc-css-889399 .bgr_no {
    line-height: 40px;
}

.ssc_icon_box .bgr_no {
    position: relative;
    z-index: 1;
    display: block;
}

.ssc_icon_box:hover, .ssc_icon_box:hover .bgr_icon, .ssc_icon_box:hover .bgr_icon i, .ssc_icon_box:hover .bgr_img, .ssc_icon_box:hover .bgr_img img, .ssc_icon_box .bgr_no, .ssc_icon_box:hover .c_icon, .ssc_icon_box:hover .c_img, .ssc_icon_box:hover .c_img img, .ssc_icon_box:hover .c_text, .ssc_icon_box:hover .icon_box, .ssc_icon_box:hover .cont_box, .ssc_icon_box:hover .title, .ssc_icon_box:hover .subtitle, .ssc_icon_box:hover .cont_box .description, .ssc_icon_box:hover .cont_box .rm {
    transition: all 200ms;
}

.ssc_icon_box .bgr_no div {
    display: block;
}

.kc-css-889399 .c_icon, body.kc-css-system .kc-css-889399 .c_text {
    color: #3695d9;
}

.ssc_icon_box .c_icon {
    display: block;
    position: relative;
    z-index: 1;
    text-align: center;
}

.kc-css-889399 .c_icon i, body.kc-css-system .kc-css-889399 .c_img img, body.kc-css-system .kc-css-889399 .c_text {
    line-height: 20px;
}

[class^="nat-"], [class*=" nat-"] {
    font-family: 'native' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.kc-css-889399 .first_cont_box {
    padding-left: 8px;
}

.ssc_icon_box.t_icon_left > div, .ssc_icon_box.t_icon_right > div {
    display: table-cell;
}

.ssc_icon_box .cont_box {
    vertical-align: top;
}

.kc-css-889399 .title, body.kc-css-system .kc-css-889399 .title a {
    color:#A65A6A;
    font-size: 22px;
    line-height: 25px;
    font-weight: 500;
    font-family: 'Zona Pro';
}

.kc-css-889399 .cont_box .description {
    font-size: 0px;
}

.ssc_icon_box .cont_box .description {
    color: #414141;
    line-height: 1.4em;
}
.ssc_icon_box .cont_box .description p:last-child {
    margin-bottom: 0;
}

.ssc_icon_box .cont_box .description p:first-child {
    margin-top: 0;
}

.kc-css-827259 {
    margin-top: 10px;
    margin-bottom: 0px;
    margin-left: 45px;
}

.kc-css-827259 .divider_inner {
    width: 30px;
    border-width: 3px;
}

.divider_line .divider_inner {
    display: inline-block;
    border-top: 0!important;
    border-left: 0!important;
    border-right: 0!important;
}

.kc-css-433909, body.kc-css-system .kc-css-433909 p {
    font-family: "ZonaPro-ExtraLight";
    font-size: 14px;
}

.kc_text_block {
    display: inline-block;
    clear: both;
    width: 100%;
    /* margin-top: 15px; */
}

.kc-css-433909, body.kc-css-system .kc-css-433909 p {
    font-family: "ZonaPro-ExtraLight";
}

.kc-css-976240 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.kc_row_inner>div.kc_column_inner:only-child {
    width: 100%;
}

.kc_column>.kc-col-container, .kc_column_inner>.kc-col-inner-container {
    height: 100%;
}

.slmm, .slmm2 {
    position: relative;
    z-index: 999;
}

.slmm, .slmm2, .slmm *, .slmm2 * {
    margin: 0;
    padding: 0;
    outline: none;
    border: 0;
    background: none;
}

.kc-css-851798 .slmm li, .kc-css-851798 .slmm2 li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 0px;
}

.slmm li, .slmm2 li {
    position: relative;
    white-space: nowrap;
    list-style: none;
}

.slmm, .slmm *, .slmm2, .slmm2 * {
    margin: 0;
    padding: 0;
    outline: none;
    border: 0;
    background: none;
}
.kc-css-851798 .slmm li a, .kc-css-851798 .slmm li > span {
    font-family: "ZonaPro-ExtraLight";
    color: #D27F8B;
    padding: 10px 15px 10px 15px;
}

.kc-css-851798 .slmm2 li a, .kc-css-851798 .slmm2 li > span {
    font-family: "ZonaPro-ExtraLight";
    color: #D27F8B !important;
    padding: 10px 4px 10px 4px;
    /* padding: 4px; */
}

.slmm li a, .slmm2 li a {
    display: block;
    text-decoration: none;
    transition: background 0.4s, color 0.4s, border 0.4s;
    -o-transition: background 0.4s, color 0.4s, border 0.4s;
    -ms-transition: background 0.4s, color 0.4s, border 0.4s;
    -moz-transition: background 0.4s, color 0.4s, border 0.4s;
    -webkit-transition: background 0.4s, color 0.4s, border 0.4s;
}

.slmm li a:hover, .slmm2 li a:hover {
    color: #A65A6A;
}

.kc_text_block a {
    text-decoration: none;
}

#slmenu-882132 {
    display: flex;
    justify-content: space-between;
}

@media screen and (min-width: 999px) {
    .kc-row-container.kc-container .kc-wrap-columns, .kc_row_inner {
        width: calc(100% + 30px);
    }

    .kc-row-container .kc-wrap-columns, .kc_row_inner {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 999px) {
    .kc-css-421427 {
        margin-left: 0%;
        width: 50%;
    }

    .slmm {
        float: none;
        margin-top: 0;
    }

    .slmm li {
        position: relative;
    }
}

@media only screen and (max-width: 768px) {
    .kc-css-889399 {
        width: 100%;
        margin-right: 0px;
        padding-right: 30px;
    }
}

@media screen and (max-width: 767px) {
    .kc_column, .kc_column_inner {
        width: 100%;
    }

    div.kc_row {
        display: block;
    }

    .slmm2 {
        display: none;
    }

}

@media screen and (max-width: 601px) {
    p {
        margin: 10px 0 25px 0;
        line-height: 26px;
    }
}