.about_row_fluid_about1202929920 {
    padding-bottom: 59px;
}

.eltdf-section-title-holder {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    box-sizing: border-box;
}

.eltdf-section-title-holder .eltdf-st-subtitle {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .29em;
    line-height: 21px;
    font-family: Montserrat,sans-serif;
    color: #3d3d3d;
}

.eltdf-section-title-holder .eltdf-st-title {
    display: block;
    margin: 0;
    padding-top: 6px;
    font-family: "Zona Pro";
    color: #A65A6A;
}

.eltdf-section-title-holder .eltdf-st-text {
    display: block;
    margin: 28px 0 0;
    line-height: 27px;
}

.eltdf-progress-bar {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
}

.eltdf-progress-bar .eltdf-pb-title-holder {
    position: relative;
    margin: 10px 0 4px;
}

.eltdf-progress-bar .eltdf-pb-title-holder .eltdf-pb-title {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-family: "ZonaPro-Extralight";
    color: #A65A6A;
}

.eltdf-progress-bar .eltdf-pb-percent {
    position: absolute;
    right: 0;
    bottom: 1px;
    width: auto;
    display: inline-block;
    vertical-align: middle;
    opacity: 0;
    font-family: "ZonaPro-Extralight";
    color: #A65A6A;
    /* z-index: 10; */
}

.eltdf-progress-bar .eltdf-pb-percent:after {
    content: '%';
}

.eltdf-progress-bar .eltdf-pb-content-holder {
    position: relative;
    height: 2px;
    overflow: hidden;
    background-color: #e1e1e1;
}

.eltdf-progress-bar .eltdf-pb-content-holder .eltdf-pb-content {
    height: 2px;
    max-width: 100%;
    overflow: hidden;
    background-color: #A65A6A;
}

.about_page_grid_images {
    margin-top: 114px;
}

@media (min-width: 1200px) {
    .vc_col-lg-6 {
        width: 50%;
    }
    .vc_col-lg-1, .vc_col-lg-10, .vc_col-lg-11, .vc_col-lg-12, .vc_col-lg-2, .vc_col-lg-3, .vc_col-lg-4, .vc_col-lg-5, .vc_col-lg-6, .vc_col-lg-7, .vc_col-lg-8, .vc_col-lg-9 {
        float: left;
    }
}

@media screen and (max-width: 768px) {
    .about_page_grid_images {
        margin-top: 0;
    }
    .eltdf-eh-item-content-about_progress {
        padding: 30px 0 0 0px !important;
    }
}

@media only screen and (min-width: 481px) and (max-width: 680px) {
    .eltdf-eh-item.eltdf-eh-custom-4395 .eltdf-eh-item-content {
        padding: 0 14% 0 0 !important;
    }
    .eltdf-eh-item-content-about_progress {
        padding: 30px 0 0 0px !important;
    }
}