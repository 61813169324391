.site-header.fmc-transparent-header {
    width: 100vw;
    max-width: 100%;
    /* z-index: 10; */
}

.site-header {
    position: relative;
}

.header-mobile {
    height: 66px;
    padding: 3px 0;
    background: #FFF;
    /* border-bottom: 1px solid #e4e4e486; */
}

.header-mobile.header-mobile-layout1 > .container {
    justify-content: space-between;
}

.header-mobile > .container {
    height: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
}

.header-mobile > .container, .header-container, #content > .container, .site-content .page-heading-wrap .page-heading-container, .header-breadcrumb-wrap > .container {
    /* max-width: 100%; */
    padding-left: 15px;
    padding-right: 15px;
}

.header-mobile.header-mobile-layout1 > .container > div {
    flex: 1;
}

.header-mobile > .container > div {
    /* max-height: 60px; */
}

.header-mobile > .container .mobile-nav-btn > a {
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 15px;
}

.fm-icon {
    /* width: 15px;
    height: 15px; */
    width: 20px;
    height: 30px;
}

.header-mobile.header-mobile-layout1 .site-branding {
    text-align: center;
}

.site-branding {
    width: 165px;
    padding-top: 0;
    padding-bottom: 12px;
    padding-left: 0;
    padding-right: 0;
    align-self: inherit;
}

.site-branding .site-logo {
    display: inline-block;
}

.site-logo {
    max-width: 95px;
}

.header-mobile > .container .site-branding .site-logo img, .header-mobile > .container .site-branding .site-logo .logo-img {
    max-height: 60px;
}

.img-responsive {
    max-width: 100%;
}

.site-branding .site-logo img, .site-branding .site-logo .logo-img {
    width: 100%;
}

.header-control-wrap {
    display: flex;
    align-items: center;
}

.header-mobile .header-control-wrap.header-mobile-right-control {
    justify-content: flex-end;
}

.header-mobile.header-mobile-layout1 .header-mobile-right-control > .header-element {
    margin-left: 8px;
}

.header-element.search_box.button {
    position: relative;
}

.header-element.search_box svg.fm-icon, .header-element.menu_box svg.fm-icon, .header-element.my-account-box svg.fm-icon, .header-element.wishlist-box svg.fm-icon, .header-element.hamburger-box .hamburger-menu svg.fm-icon {
    width: 20px;
    height: 20px;
}

.header-element.menu_box a.cart-btn {
    display: flex;
    align-items: center;
}

.header-element.menu_box > a {
    position: relative;
}

.mini-menu-contents {
    margin-left: 12px;
    margin-right: 8px;
}

.styledMenu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: blue;
    width: 300px;
    height: 100vh;
    text-align: left;
    padding: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
}

.styledMenu.open {
    transform: translateX(0%);
}

.styledMenu a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: white;
    text-decoration: none;
    transition: color 0.3s linear;
}

/*Left side menu */
#mobile-header-canvas {
    width: 300px;
}

.c-offcanvas.is-open {
    transform: translate3d(0, 0, 0);
    visibility: visible;
    opacity: 1;
}

.c-offcanvas.is-closed {
    max-height: 100%;
    overflow: hidden;
    visibility: hidden;
    box-shadow: none;
    opacity: 0;
    z-index: -1;
}

.c-offcanvas--full-fade {
    height: 100%;
    transition: opacity 300ms cubic-bezier(0.4, 0, 0.6, 1),visibility 300ms cubic-bezier(0.4, 0, 0.6, 1);
}

.c-offcanvas--overlay {
    z-index: 1080;
}

.c-offcanvas {
    position: fixed;
    min-height: 100%;
    max-height: none;
    top: 0;
    left: 0;
    display: block;
    background: #FFF4F3;
    overflow: hidden;
}

.c-offcanvas.is-open .offcanvas-content {
    overflow: unset;
}

.c-offcanvas.is-open .offcanvas-content {
    height: 100%;
}

#mobile-header-canvas .offcanvas-content .mobile-header-screen-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

#mobile-header-canvas .offcanvas-content .mobile-header-screen-inner .mobile-header-title {
    display: flex;
    height: 50px;
    flex: 0 0 50px;
    width: 100%;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    background: #FFF4F3;
    border-bottom: 1px solid #e4e4e4;
}

#mobile-header-canvas .offcanvas-content .mobile-header-screen-inner .mobile-header-title h3 {
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    color: #000000;
    font-weight: 600;
    margin: 0;
}

#mobile-header-canvas .offcanvas-content .mobile-header-screen-inner .mobile-header-title .js-offcanvas-close {
    position: static;
    right: 15px;
    top: 15px;
    line-height: 24px;
    height: auto;
    width: auto;
    padding: 0;
}

#mobile-header-canvas .offcanvas-content .mobile-header-screen-inner .mobile-header-title .js-offcanvas-close > .c-button__text {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}

#mobile-header-canvas .offcanvas-content .mobile-header-screen-inner .mobile-header-title .js-offcanvas-close svg.fm-icon {
    width: 9px;
    height: 9px;
    margin-left: 3px;
    fill: #000000;
}

#mobile-header-canvas .offcanvas-content .mobile-header-screen-inner .mobile-navigation {
    width: 100%;
    padding: 0 20px;
    height: calc(100% - 150px);
    overflow-y: auto;
}

#mobile-header-canvas .offcanvas-content .mobile-header-screen-inner .mobile-navigation ul.menu {
    margin-bottom: 0;
    padding-bottom: 0;
}

.default-nav-menu ul.menu {
    list-style: none;
    padding: 1rem 0;
}

#mobile-header-canvas .offcanvas-content .mobile-header-screen-inner .mobile-navigation ul.menu li {
    line-height: 24px;
    padding: 0.5rem 0;
}

#mobile-header-canvas .offcanvas-content .mobile-header-screen-inner .mobile-navigation ul.menu > li > a {
    font-family: 'Zona Pro';
    color: #A65A6A;
    text-transform: uppercase;
}

.default-nav-menu ul.menu li.menu-item a.toggle-submenu {
    position: absolute;
    right: 0;
    top: 0;
    display: inline-block;
    height: 40px;
    width: 40px;
    line-height: 100%;
    font-size: 1.5rem;
    text-align: center;
}

.default-nav-menu ul.menu li.menu-item a.toggle-submenu .toggle-icon {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: flex-end;
}

.default-nav-menu ul.menu li.menu-item a.toggle-submenu .toggle-icon::before {
    content: "";
    display: inherit;
    width: 7px;
    height: 7px;
    border: 1px solid #b2b2b2;
    border-width: 1px 1px 0 0;
    transform: rotate(45deg);
}

/* per mi bo toggle shigjetat kur t hapet left side menu per kategori, bazohemi tek kjo klasa open li.menu-item.open -> nese o tru e bejme display: inline block, kete per lart, kurse ata posht e bojm display:none */
.default-nav-menu ul.menu li.menu-item.open > a.toggle-submenu .toggle-icon::after {
    display: inline-block;
}

.default-nav-menu ul.menu li.menu-item.open > a.toggle-submenu .toggle-icon::before {
    display: none;
}

.default-nav-menu ul.menu li.menu-item a.toggle-submenu .toggle-icon::after {
    content: "";
    width: 7px;
    height: 7px;
    border: 1px solid #b2b2b2;
    border-width: 1px 1px 0 0;
    transform: rotate(-45deg);
    display: none;
}

#mobile-header-canvas .offcanvas-content .mobile-header-screen-inner .mobile-header-title .js-offcanvas-close .button-title {
    display: inline-block;
    font-weight: 400;
    font-size: 10px;
    text-transform: uppercase;
    color: #000000;
}

.js-offcanvas .fisino-close-btn {
    background: none !important;
    border: none !important;
    padding: 0;
}

.default-nav-menu ul.menu li.menu-item ul.sub-menu {
    list-style: none;
    padding-left: 0.75rem;
    visibility: hidden;
    opacity: 0;
    height: 0;
    -webkit-transition: height 0.35s linear 0s;
    -moz-transition: height 0.35s linear 0s;
    -ms-transition: height 0.35s linear 0s;
    -o-transition: height 0.35s linear 0s;
    transition: height 0.35s linear 0s;
}

/* kjo e shfaq submenun */
.default-nav-menu ul.menu li.menu-item.open > ul.sub-menu {
    visibility: visible;
    opacity: 1;
    height: auto;
}

#mobile-header-canvas .offcanvas-content .mobile-header-screen-inner .mobile-navigation ul.menu li .sub-menu li {
    padding: 5px 0;
}

#mobile-header-canvas .offcanvas-content .mobile-header-screen-inner .mobile-navigation ul.menu li .sub-menu li a {
    font-family: 'Zona Pro';
    color: #A65A6A;
}

#mobile-header-canvas .offcanvas-content .mobile-header-screen-inner .mobile-navigation ul.menu li {
    line-height: 24px;
    padding: 0.5rem 0;
}

.default-nav-menu ul.menu li.menu-item {
    position: relative;
}

/*Left side menu */

.c-offcanvas-bg.is-closed {
    visibility: hidden;
}

.c-offcanvas-bg.is-animating, .c-offcanvas-bg.is-open {
    left: 0;
    background-color: rgba(0, 0, 0, 0.68);
    visibility: visible;
}

.c-offcanvas-bg {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1079;
    /* left: -100%; -kur eshte e mbyllur duhet me ja shfaq kete */
    background-color: transparent;
    transition: background-color 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

@media (min-width: 1025px) {
    .header-layout ~ .header-mobile.not_device_hidden, .header-layout ~ .header-mobile-spacer.not_device_hidden {
        display: none !important;
    }
}

@media (min-width: 768px) {
    .site-header.fmc-transparent-header {
        position: absolute;
    }
}

@media (max-width: 767px) {
    .site-branding .site-logo img, .site-branding .site-logo .logo-img {
        max-height: 60px;
    }
}

@media (max-width: 365px) {
    #mobile-badge {
        display: none !important;
    }

}
