.modal-content button.close {
  position: absolute;
  left: 94%;
  width: 35px;
  height: 35px;
  line-height: 34px;
  display: block;
  color: #acabab;
  top: 10px;
  cursor: pointer;
  font-size: 24px;
  z-index: 9;
}

.modal {
  overflow-x: hidden;
  overflow-y: auto !important;
}

.modal_body {
  padding: 29px 6px 38px;
}

.modal-dialog.modal-dialog-centered {
  min-width: 1100px;
}

.tab-content > .tab-pane.active {
  display: block;
  height: auto;
  opacity: 1;
  overflow: visible;
}

.modal_tab_img {
  text-align: center;
  margin-bottom: 10px;
}

.modal_tab_img img {
  margin: 0 auto;
}

.modal_title h2 {
  font-size: 24px !important;
}

.modal_title h2 {
  font-family: "ZonaPro";
  color: #a65a6a;
  font-weight: 600;
  margin-bottom: 14px;
  line-height: 26px;
}

.modal_price {
  margin-bottom: 12px;
}

.modal_price span {
  font-family: "ZonaPro";
  font-weight: 600;
  color: #acabab;
  font-size: 16px;
}

.modal_price span.old_price {
  color: #333;
  font-size: 14px;
  font-weight: 400;
  text-decoration: line-through;
  margin-left: 5px;
}

.modal_description.mb-15 {
  margin-bottom: 20px;
}

.modal_description p {
  font-family: "ZonaPro-ExtraLight";
  color: #acabab;
}

.modal_right {
  padding: 54px 15px 15px 52px;
}

.yith-wcwl-add-to-wishlist {
  width: auto;
  background-color: transparent;
  margin: 18px 0 0;
}

.cart-modal {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  margin: 27px 0 0;
}

.cart-modal .eltdf-quantity-buttons {
  position: relative;
  /* display: inline-block; */
  vertical-align: middle;
  background: none;
}

.cart-modal .eltdf-quantity-minus {
  position: relative;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  line-height: 20px;
  cursor: pointer;
  border: 1px solid #e8c9c7;
  transition: all 0.15s ease-in-out;

  /* pointer-events: none; */
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
  border: 1px solid #d3d3d3;
  text-align: center;
  box-sizing: border-box;
}

.cart-modal .product-page-eltdf-quantity-plus:after {
  top: 3px;
  left: 13px;
  bottom: 3px;
  width: 1px;
  color: #acabab;
  content: "";
  position: absolute;
  background: currentColor;
  color: #acabab;
  font-family: "ZonaPro-ExtraLight";
}

.cart-modal .product-page-eltdf-quantity-minus:after,
.cart-modal .eltdf-quantity-minus:before,
.cart-modal .eltdf-quantity-plus:before {
  content: "";
  position: absolute;
  background: currentColor;
  color: #acabab;
  font-family: "ZonaPro-ExtraLight";
  top: 13px;
  left: 3px;
  right: 3px;
  height: 1px;
}

.cart-modal .eltdf-quantity-buttons .eltdf-quantity-input {
  position: relative;
  top: -1px;
  height: 44px;
  width: 44px;
  padding: 0;
  margin: 0;
  /* border: 0; */
  outline: 0;
  font-size: 19px;
  line-height: 1.578947368421053em;
  text-transform: uppercase;
  font-family: "Zona Pro";
  color: #acabab;
  font-weight: 500;
  text-align: center;
}

.cart-modal .eltdf-quantity-plus {
  top: 0;
  border-bottom: 0;

  position: relative;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  line-height: 20px;
  cursor: pointer;
  border: 1px solid #e8c9c7;
  transition: all 0.15s ease-in-out;
}

.btn-add-to-cart-open-modal {
  margin: 12px 0 0 0;
}

.btn-add-to-cart-open-modal a {
  background-color: #a65a6a;
  font-family: "Zona Pro";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  padding: 13px 23px 11px;
  color: #ffffff !important;
  font-weight: bold;
  border-radius: 40px;
}

.modal_right .product_meta {
  margin: 52px 0 0;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.29em;
  color: #3d3d3d;
}

.modal_right .sku,
.modal_right .posted_in a,
.modal_right .tagged_as a {
  /* font-family: 'ZonaPro-Extralight'; */
  color: #acabab;
}

.modal_right .product_meta > span {
  display: block;
  margin: 3px 0;
  font-family: "Zona Pro";
  color: #a65a6a;
  text-transform: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal-dialog.modal-dialog-centered {
    min-width: 730px;
  }
}

/* Product Size */
#modal_box409 .product-size .size-choose div {
  display: inline-block;
  margin: 15px 0 0 0;
}

#modal_box409 .product-size .size-choose input[type="radio"] {
  display: none;
}

#modal_box409 .product-size .size-choose input[type="radio"] + label span {
  width: 45px;
  height: 45px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #e8c9c7;
  cursor: pointer;
  margin-right: 6px;
  background: #fff;
  margin-bottom: 10px;
  padding: 0 7px;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: inline-box;
  display: -moz-inline-flex;
  display: inline-flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
}

#modal_box409 .product-size .size-choose input[type="radio"] + label span {
  font-family: "Zona Pro";
  color: #acabab;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
}

#modal_box409 .product-size .size-choose input[type="radio"] + label .out-of-stock {
  background-image: url(../../assets/cancel.svg);
  background-repeat: no-repeat;
  background-position: center;
  pointer-events: none;
}

#modal_box409
  .product-size
  .size-choose
  input[type="radio"]:checked
  + label
  span {
  background: #a65a6a;
  color: #fff;
}

/* Product Size */

/* Product Color */
#modal_box409 .product-color .color-choose div {
  display: inline-block;
  margin: 15px 0 0 0;
}

#modal_box409 .product-color .color-choose input[type="radio"] {
  display: none;
}

#modal_box409 .product-color .color-choose input[type="radio"] + label span {
  display: inline-block;
  width: 35px;
  height: 35px;
  margin: -1px 4px 0 0;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
}

#modal_box409 .product-color .color-choose input[type="radio"] + label span {
  border: none;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
}

#modal_box409
  .product-color
  .color-choose
  input[type="radio"]:checked
  + label
  span {
  background-image: url(../../assets/check-icn.svg);
  background-repeat: no-repeat;
  background-position: center;
}

/* Product Color */

@media only screen and (max-width: 767px) {
  .modal-dialog.modal-dialog-centered {
    min-width: 90%;
  }

  .modal-content button.close {
    left: 83%;
    width: 32px;
    height: 32px;
    line-height: 35px;
    top: 4px;
    margin-bottom: 14px;
  }

  .modal_body {
    padding: 42px 6px 38px;
  }
}
/* @font-face {
    font-family: "ZonaPro-ExtraLight";
    src: url("../../zona-pro/FontsFree-Net-ZonaPro-Bold.ttf");
}
@font-face {
    font-family: "ZonaPro-ExtraLight";
    src: url("../../zona-pro/FontsFree-Net-ZonaPro-ExtraLight.ttf");
}

#yith-quick-view-modal {
    z-index: 1050;
}

#yith-quick-view-modal {
    position: fixed;
    visibility: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1400;
    opacity: 0;
    text-align: center;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

#yith-quick-view-modal:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0;
}

#open-modal-eltdf-quantity-minus:after, #open-modal-eltdf-quantity-minus:before {
    content: '';
    position: absolute;
    background: currentColor;
    color: #ACABAB;
    font-family: 'ZonaPro-ExtraLight';
}

#open-modal-eltdf-quantity-plus:after, #open-modal-eltdf-quantity-plus:before {
    content: '';
    position: absolute;
    background: currentColor;
    color: #ACABAB;
    font-family: 'ZonaPro-ExtraLight';
}

#yith-quick-view-modal .yith-quick-view-overlay {
    background: rgba(246,246,246,.7);
}

#yith-quick-view-modal .yith-quick-view-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba( 0, 0, 0, 0.8);
}

#yith-quick-view-modal .yith-wcqv-wrapper {
    display: inline-block;
    vertical-align: middle;
    visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    text-align: left;
    height: 600px;
    width: 1000px;
}

#yith-quick-view-modal.open .yith-wcqv-main {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    height: 100%;
    overflow-y: scroll;
    position: relative;
}

#yith-quick-view-modal .yith-wcqv-main {
    overflow: -moz-scrollbars-none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

#yith-quick-view-modal .yith-wcqv-main {
    background: #ffffff;
}

#yith-quick-view-modal .yith-wcqv-main:after, #yith-quick-view-modal .yith-wcqv-main:before {
    content: '';
    display: table;
    clear: both;
}

Scroll-bar me mshef por me qene ne gjendje me bo scroll-bar
#yith-quick-view-modal .yith-wcqv-main::-webkit-scrollbar {
    -ms-overflow-style: none; 
    scrollbar-width: none;
    display: none;
}

#yith-quick-view-modal #yith-quick-view-close {
    position: absolute;
    top: 24px;
    right: 24px;
    color: #ACABAB;
    background: url(../../images/close-icon-modal.svg) no-repeat;
    width: 16px;
    height: 17px;
    font-size: 26px;
    line-height: 15px;
    font-weight: 400;
    text-align: center;
    opacity: 1;
    border: 0;
}


#yith-quick-view-modal #yith-quick-view-content {
    height: 100%;
}

#yith-quick-view-modal #yith-quick-view-content>.product, #yith-quick-view-modal #yith-quick-view-content>.product>.product {
    margin-bottom: 0;
}

#yith-quick-view-modal .product, .eltdf-woo-single-page .product {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    float: none;
    margin: 0 0 50px;
}

#yith-quick-view-modal #yith-quick-view-content>.product, #yith-quick-view-modal #yith-quick-view-content>.product>.product {
    margin-bottom: 0;
}

#yith-quick-view-modal .product, .eltdf-woo-single-page .product {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    float: none;
    margin: 0 0 50px;
}

#yith-quick-view-modal #yith-quick-view-content .product div.images {
    width: 50%;
}

#yith-quick-view-content div.images {
    float: left;
    opacity: 1 !important;
}

#yith-quick-view-modal #yith-quick-view-content .product div.images figure.woocommerce-product-gallery__wrapper {
    margin: 0;
}

#yith-quick-view-modal #yith-quick-view-content .product div.images figure.woocommerce-product-gallery__wrapper div:first-child {
    padding-right: 10px;
}

#yith-quick-view-modal #yith-quick-view-content .product div.images figure.woocommerce-product-gallery__wrapper a, #yith-quick-view-modal #yith-quick-view-content .product div.images figure.woocommerce-product-gallery__wrapper img {
    display: block;
    height: 100%;
    height: -webkit-fill-available;
    object-fit: cover;
}

#yith-quick-view-modal #yith-quick-view-content div.summary {
    width: calc(50% - 50px);
    padding: 54px 15px 15px 52px;
}

#yith-quick-view-modal #yith-quick-view-content div.summary, #yith-quick-view-modal .eltdf-single-product-summary, .eltdf-woo-single-page #yith-quick-view-content div.summary, .eltdf-woo-single-page .eltdf-single-product-summary {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    float: left;
    box-sizing: border-box;
}

#yith-quick-view-modal #yith-quick-view-content div.summary .product_title.entry-title {
    font-size: 40px;
    line-height: 1.125em;
    margin-bottom: 7px;
    font-family: 'Zona Pro';
    color: #A65A6A;
}

#yith-quick-view-modal #yith-quick-view-content div.summary .price, #yith-quick-view-modal .eltdf-single-product-summary .price, .eltdf-woo-single-page #yith-quick-view-content div.summary .price, .eltdf-woo-single-page .eltdf-single-product-summary .price {
    font-family: 'Zona Pro';
    font-weight: 600;
    font-size: 18px;
    position: relative;
    display: block;
    margin: 14px 0 0;
    line-height: 1;
    color: #ACABAB;
}

#yith-quick-view-modal #yith-quick-view-content div.summary .woocommerce-product-details__short-description {
    margin: 47px 0 13px;
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
}

#yith-quick-view-modal #yith-quick-view-content div.summary .woocommerce-product-details__short-description p, #yith-quick-view-modal .eltdf-single-product-summary .woocommerce-product-details__short-description p, .eltdf-woo-single-page #yith-quick-view-content div.summary .woocommerce-product-details__short-description p, .eltdf-woo-single-page .eltdf-single-product-summary .woocommerce-product-details__short-description p {
    font-size: 16px;
    line-height: 26px;
    margin: 0;
    font-family: 'ZonaPro-ExtraLight';
    color: #ACABAB;
}

#yith-quick-view-modal #yith-quick-view-content div.summary form.cart {
    margin: 43px 0 0;
}

#yith-quick-view-modal #yith-quick-view-content div.summary form.cart, #yith-quick-view-modal .eltdf-single-product-summary form.cart, .eltdf-woo-single-page #yith-quick-view-content div.summary form.cart, .eltdf-woo-single-page .eltdf-single-product-summary form.cart {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
}

#modal-button {
    border-radius: 40px;
    font-family: 'Zona Pro';
    background: #A65A6A;
    color: #fff;
}

#modal-button:before {
    position: unset;
}

#modal_product_meta {
    margin: 52px 0 0;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .29em;
    color: #3d3d3d;
}

#modal_product_meta > span {
    display: block;
    margin: 10px 0;
}

#modal_product_meta .sku_wrapper, #modal_product_meta .tagged_as, #modal_product_meta .posted_in {
    font-family: 'Zona Pro';
    color: #ACABAB;
}

#modal_product_meta .sku_wrapper .sku, #modal_product_meta .tagged_as a, #modal_product_meta .posted_in a {
    font-family: 'ZonaPro-ExtraLight';
    color: #ACABAB !important;
}

#yith-quick-view-modal #yith-quick-view-content div.summary .yith-wcwl-add-to-wishlist .yith-wcwl-add-button a:before, #yith-quick-view-modal #yith-quick-view-content div.summary .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistaddedbrowse a:before, #yith-quick-view-modal #yith-quick-view-content div.summary .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistexistsbrowse a:before {
    top: 1px;
}

#yith-quick-view-modal.open, #yith-quick-view-modal.open .yith-wcqv-wrapper {
    visibility: visible;
    opacity: 1;
}

@media only screen and (max-width: 768px) {
    #yith-quick-view-modal .yith-wcqv-wrapper {
        position: absolute;
        left: 30px;
        top: 116.5px;
        width: 423px;
        height: 400px;
    }
    #yith-quick-view-modal #yith-quick-view-content .product div.images, #yith-quick-view-modal #yith-quick-view-content div.summary{
        width: 100%;
    }
} */
