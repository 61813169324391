@font-face {
    font-family: "ZonaPro-ExtraLight";
    src: url("../../zona-pro/FontsFree-Net-ZonaPro-Bold.ttf");
}

@font-face {
    font-family: "ZonaPro-ExtraLight";
    src: url("../../zona-pro/FontsFree-Net-ZonaPro-ExtraLight.ttf");
}


#prducts_page .eltdf-normal-space.eltdf-disable-bottom-space {
    margin-bottom: -30px;
}
#prducts_page .eltdf-normal-space .eltdf-outer-space {
    margin: 0 -15px;
}
#prducts_page .eltdf-grid-list.eltdf-four-columns .eltdf-item-space, .eltdf-grid-list.eltdf-four-columns .eltdf-masonry-grid-sizer {
    width: 25%;
}
#prducts_page .eltdf-normal-space .eltdf-outer-space .eltdf-item-space {
    padding: 0 30px;
    margin: 0 0 30px;
}
#prducts_page .eltdf-pl-holder .eltdf-pli-inner .eltdf-pli-image .eltdf-pli-new-product {
    left: 1px;
    background-color: #fff;
    color: #000;
}
#prducts_page .eltdf-pl-holder.eltdf-info-below-image .eltdf-pli .eltdf-pli-inner .eltdf-pli-text {
    padding: 0;
}
#prducts_page .eltdf-pl-holder .eltdf-pli-inner .eltdf-pli-text {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px;
    box-sizing: border-box;
}
#prducts_page .eltdf-pl-holder .eltdf-pli-inner .eltdf-pli-text-outer {
    position: relative;
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
    text-align: center;
}
#prducts_page .eltdf-pl-holder .eltdf-pli-inner .eltdf-pli-text-outer .eltdf-pli-text-inner {
    display: table-cell;
}
#prducts_page .eltdf-pl-holder.eltdf-info-below-image .eltdf-pli .eltdf-pli-inner .eltdf-pli-text .eltdf-pli-icons-holder {
    /* position: absolute;
    bottom: -1px;
    right: -1px;
    background-color: #fff;
    z-index: 111;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: transform .35s cubic-bezier(.34,.18,0,.9);
    -moz-transition: transform .35s cubic-bezier(.34,.18,0,.9);
    transition: transform .35s cubic-bezier(.34,.18,0,.9); */
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    transform: translateX(100%);
    bottom: -1px;
    right: -1px;
    padding: 19px 0 10px;
    width: 100%;
    position: absolute;
    line-height: 0;
    -webkit-transition: 0.4s linear;
    -moz-transition: 0.4s linear;
    -ms-transition: 0.4s linear;
    -o-transition: 0.4s linear;
    transition: 0.4s linear;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 111;

}
.products-cart-button_1234098765 {
    display: inline-flex !important;
    width: 46px !important;
    height: 46px !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50% !important;
    padding: 0 !important;
    font-weight: 400;
    margin: 0 8px 10px !important;
    background-color: #fff;
    box-shadow: 0 3px 10px rgb(0 0 0 / 8%) !important;
    font-size: 0 !important;
    text-align: center !important;
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
  
}
#prducts_page .eltdf-pl-holder .eltdf-pli .eltdf-pli-icons-holder {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    /* flex-direction: row-reverse; */
    padding: 0 8px;
}
#prducts_page .eltdf-pl-holder .eltdf-pli .eltdf-pli-icons-holder>.eltdf-pli-add-to-cart a.button.add_to_cart_button {
    box-sizing: content-box;
}
#prducts_page .eltdf-pl-holder .eltdf-pli .eltdf-pli-icons-holder>.eltdf-pli-add-to-cart a.button {
    bottom: 0;
    right: -1px;
    display: inline-block;
    background: 0 0;
    color: inherit;
}
#prducts_page .eltdf-pl-holder .eltdf-pli .eltdf-pli-icons-holder .added_to_cart, .eltdf-pl-holder .eltdf-pli .eltdf-pli-icons-holder>.eltdf-pli-add-to-cart a.button, .eltdf-pl-holder .eltdf-pli .eltdf-pli-icons-holder>.yith-quickview-button, .eltdf-pl-holder .eltdf-pli .eltdf-pli-icons-holder>.yith-wcwl-add-to-wishlist {
    height: 43px;
    width: 18px;
    z-index: 300;
    overflow: hidden;
    padding: 0 8px;
    font-size: 0;
    vertical-align: bottom;
    margin: 0;
    border: 0;
    box-sizing: initial;
    -webkit-transition: opacity .2s ease-out;
    -moz-transition: opacity .2s ease-out;
    transition: opacity .2s ease-out;
    text-decoration: none;
}

#prducts_page .eltdf-pl-holder .eltdf-pli .eltdf-pli-icons-holder>.yith-quickview-button:before {
    content: "\f06e";
    /* content: url(../../images/heart.svg); */
    font-size: 17px;
    line-height: 43px;
    font-family: FontAwesome;
    color: #A65A6A;
}
#prducts_page .eltdf-pl-holder .eltdf-pli .eltdf-pli-icons-holder>.yith-cart-button:before {
    content: "\f290";
    font-size: 17px;
    line-height: 43px;
    font-family: FontAwesome;
    color: #A65A6A;
}
#prducts_page .eltdf-pl-holder .eltdf-pli .eltdf-pli-icons-holder>.yith-heart-button:before {
    content: "\f004";
    font-size: 17px;
    line-height: 43px;
    font-family: FontAwesome;
    color: #A65A6A;
}
#prducts_page .yith-heart-button {
    text-decoration: none;
}

#prducts_page .eltdf-pl-holder .eltdf-pli .eltdf-pli-icons-holder .added_to_cart:after, .eltdf-pl-holder .eltdf-pli .eltdf-pli-icons-holder .added_to_cart:before, .eltdf-pl-holder .eltdf-pli .eltdf-pli-icons-holder>.eltdf-pli-add-to-cart a.button:after, .eltdf-pl-holder .eltdf-pli .eltdf-pli-icons-holder>.eltdf-pli-add-to-cart a.button:before, .eltdf-pl-holder .eltdf-pli .eltdf-pli-icons-holder>.yith-quickview-button:after, .eltdf-pl-holder .eltdf-pli .eltdf-pli-icons-holder>.yith-quickview-button:before, .eltdf-pl-holder .eltdf-pli .eltdf-pli-icons-holder>.yith-wcwl-add-to-wishlist:after, .eltdf-pl-holder .eltdf-pli .eltdf-pli-icons-holder>.yith-wcwl-add-to-wishlist:before {
    color: inherit;
}
#prducts_page .eltdf-pl-holder .eltdf-pli .eltdf-pli-icons-holder .added_to_cart, .eltdf-pl-holder .eltdf-pli .eltdf-pli-icons-holder>.eltdf-pli-add-to-cart a.button, .eltdf-pl-holder .eltdf-pli .eltdf-pli-icons-holder>.yith-quickview-button, .eltdf-pl-holder .eltdf-pli .eltdf-pli-icons-holder>.yith-wcwl-add-to-wishlist {
    height: 43px;
    width: 18px;
    z-index: 300;
    overflow: hidden;
    padding: 0 8px;
    font-size: 0;
    vertical-align: bottom;
    margin: 0;
    border: 0;
    box-sizing: initial;
    -webkit-transition: opacity .2s ease-out;
    -moz-transition: opacity .2s ease-out;
    transition: opacity .2s ease-out;
}
#prducts_page .yith-wcwl-add-to-wishlist {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 8px 0 0;
    text-align: center;
}
#prducts_page .eltdf-pl-holder .eltdf-pli .eltdf-pli-icons-holder>.yith-wcwl-add-to-wishlist>div, .eltdf-pl-holder .eltdf-pli .eltdf-pli-icons-holder>.yith-wcwl-add-to-wishlist>div a {
    height: 100%;
}
#prducts_page .eltdf-pl-holder .eltdf-pli .eltdf-pli-icons-holder>.yith-wcwl-add-to-wishlist>div, .eltdf-pl-holder .eltdf-pli .eltdf-pli-icons-holder>.yith-wcwl-add-to-wishlist>div a {
    height: 100%;
}
#prducts_page .wishlist_table .add_to_cart.button, .yith-wcwl-add-button a.add_to_wishlist, .yith-wcwl-popup-button a.add_to_wishlist, .wishlist_table a.ask-an-estimate-button, .wishlist-title a.show-title-form, .hidden-title-form a.hide-title-form, .woocommerce .yith-wcwl-wishlist-new button, .wishlist_manage_table a.create-new-wishlist, .wishlist_manage_table button.submit-wishlist-changes, .yith-wcwl-wishlist-search-form button.wishlist-search-button {
    margin: 0px;
    box-shadow: none;
    text-shadow: none;
    border-radius: 3px;
}
#prducts_page .yith-wcwl-add-button a, .yith-wcwl-wishlistaddedbrowse a, .yith-wcwl-wishlistexistsbrowse a {
    position: relative;
    display: inline-block;
    vertical-align: top;
    font-size: 0!important;
    line-height: inherit;
    color: #000;
}
#prducts_page a.add_to_wishlist {
    cursor: pointer;
}
#prducts_page .yith-wcwl-add-button .ajax-loading {
    display: none!important;
}
#prducts_page .yith-wcwl-add-to-wishlist .ajax-loading {
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    display: inline !important;
    margin-left: 5px;
    vertical-align: middle;
    visibility: hidden;
    width: 16px !important;
    height: 16px !important;
}
#prducts_page .eltdf-pl-holder .eltdf-pli-text-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    margin: 23px 0 38px;
}
#prducts_page .eltdf-pl-holder.eltdf-info-below-image .eltdf-pli .eltdf-pli-title {
    float: left;
}
#prducts_page .eltdf-pl-holder.eltdf-info-below-image .eltdf-pli .eltdf-pli-category {
    font-size: 12px;
    font-weight: 500;
    /* letter-spacing: .16em; */
    line-height: 21px;
    margin: 0;
    width: 100%;
    display: inline-block;
    font-family: 'ZonaPro-Extralight';
    color: #ACABAB;
}
#prducts_page .eltdf-pl-holder.eltdf-info-below-image .eltdf-pli .price-products {
    font-family: 'Zona Pro' !important;
    color: #ACABAB;
    font-size: 24px !important;
}
.eltdf-pli-title-products {
    width: unset !important;
    font-family: 'Zona Pro' !important;
}

/* Loading */
#prducts_page .eltdf-pl-holder .eltdf-prl-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
    background-color: transparent;
    display: none;
    margin-top: 45px;
    box-sizing: border-box;
    text-align: center;
    padding: 120px;
}
#prducts_page .eltdf-pl-holder .eltdf-prl-loading .eltdf-prl-loading-msg {
    font-size: 12px;
    line-height: 1.3em;
    color: #000;
    font-weight: 500;
}
/*Finish Loading */

/* Hover effects */
#prducts_page .eltdf-normal-space .eltdf-outer-space .eltdf-item-space:hover {
    cursor: pointer;
}
#prducts_page .eltdf-normal-space .eltdf-outer-space .eltdf-item-space:hover .eltdf-pli-inner:hover img {
    transform: scale(1.06) translate3d(-3%,0,0);
    transition: transform .75s cubic-bezier(.33,.02,0,.93);
}

#prducts_page .eltdf-normal-space .eltdf-outer-space .eltdf-item-space:hover .eltdf-pli-icons-holder {
    transform: translateX(0%) !important;
    cursor: pointer;
}

@media only screen and (max-width: 1200px) {
    .eltdf-container-inner, .eltdf-grid, .eltdf-row-grid-section {
        width: 950px;
    }
}
@media only screen and (max-width: 1024px) {
    .eltdf-grid-list.eltdf-four-columns .eltdf-item-space, .eltdf-grid-list.eltdf-four-columns .eltdf-masonry-grid-sizer {
        /* width: 33.33333% !important; */
    }
    .eltdf-container-inner, .eltdf-grid, .eltdf-row-grid-section {
        width: 768px;
    }
}
@media only screen and (max-width: 768px) {
    .eltdf-grid-list.eltdf-four-columns .eltdf-item-space, .eltdf-grid-list.eltdf-four-columns .eltdf-masonry-grid-sizer {
        width: 50% !important;
    }
    .eltdf-container-inner, .eltdf-grid, .eltdf-row-grid-section {
        width: 600px;
    }
    #prducts_page .eltdf-pl-holder.eltdf-info-below-image .eltdf-pli .eltdf-pli-inner .eltdf-pli-text .eltdf-pli-icons-holder {
        transform: translateX(0%) !important;
        cursor: pointer;
    }
    #prducts_page .eltdf-pl-holder.eltdf-info-below-image .eltdf-pli .eltdf-pli-inner .eltdf-pli-text .eltdf-pli-icons-holder:hover {
        transform: none !important;
    }
}
@media only screen and (max-width: 680px) {
    .eltdf-grid-list.eltdf-five-columns .eltdf-item-space, .eltdf-grid-list.eltdf-five-columns .eltdf-masonry-grid-sizer, .eltdf-grid-list.eltdf-four-columns .eltdf-item-space, .eltdf-grid-list.eltdf-four-columns .eltdf-masonry-grid-sizer, .eltdf-grid-list.eltdf-six-columns .eltdf-item-space, .eltdf-grid-list.eltdf-six-columns .eltdf-masonry-grid-sizer, .eltdf-grid-list.eltdf-three-columns .eltdf-item-space, .eltdf-grid-list.eltdf-three-columns .eltdf-masonry-grid-sizer, .eltdf-grid-list.eltdf-two-columns .eltdf-item-space, .eltdf-grid-list.eltdf-two-columns .eltdf-masonry-grid-sizer {
        width: 100% !important;
    }
    .eltdf-container-inner, .eltdf-grid, .eltdf-row-grid-section {
        width: 420px;
        /* padding: 0 0px 45px !important; */
        padding: 0 0 0 !important;
    }
}
@media only screen and (max-width: 480px) {
    .eltdf-eh-item.eltdf-eh-custom-2059 .eltdf-eh-item-content {
        padding: 0 20px 0 20px !important;
    }
}
@media only screen and (max-width: 480px) {
    .eltdf-container-inner, .eltdf-grid, .eltdf-row-grid-section {
        /* width: 300px; */
        width: 307px;
    }
}
/* 
@media only screen and (max-width: 1024px) {
    #prducts_page .eltdf-normal-space .eltdf-outer-space .eltdf-item-space {
        width: 50%;
    }
} */
