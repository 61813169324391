body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, .kc-col-container, .kc-container, .kc-elm {
  box-sizing: border-box;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

/* footer container */
.kc-container {
  width: 100% !important;
  max-width: 1490px !important;
  margin: 0 auto !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  /* width: 100% !important;
  max-width: 1170px !important;
  margin: 0 auto !important;
  padding-left: 15px !important;
  padding-right: 15px !important; */
}
/* footer container */

.container {
  width: 100% !important;
  max-width: 1170px !important;
  margin: 0 auto !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.header-container {
  width: 100% !important;
  max-width: 1490px !important;
  margin: 0 auto !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.text-right {
  text-align: right;
}

a, p a {
  text-decoration: none;
  -webkit-transition: color .2s ease-out;
  -moz-transition: color .2s ease-out;
  transition: color .2s ease-out;
}

a, abbr, acronym, address, applet, b, big, blockquote, body, caption, center, cite, code, dd, del, dfn, div, dl, dt, em, fieldset, font, form, h1, h2, h3, h4, h5, h6, html, i, iframe, ins, kbd, label, legend, li, object, ol, p, pre, q, s, samp, small, span, strike, strong, sub, sup, table, tbody, td, tfoot, th, thead, tr, tt, u, ul, var {
  background: 0 0;
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}

@media screen and (min-width: 1920px) {
  .product-secondary-product__size-selector-toggle {
    padding-bottom: 9px;
    padding-top: 9px;
  }
}

/*Out of stock button */

@media (min-width: 992px) {
  .vc_col-md-5 {
    width: 41.66666667%;
  }
}

@media (min-width: 992px) {
  .vc_col-md-6 {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .vc_col-md-7 {
    width: 58.33333333%;
  }
}

.eltdf-container {
  width: 100%;
  padding: 0;
}
/* .eltdf-container, .eltdf-full-width {
  z-index: 100;
  position: relative;
} */

/* @media (min-width: 768px) {
  .vc_col-sm-1, .vc_col-sm-10, .vc_col-sm-11, .vc_col-sm-12, .vc_col-sm-2, .vc_col-sm-3, .vc_col-sm-4, .vc_col-sm-5, .vc_col-sm-6, .vc_col-sm-7, .vc_col-sm-8, .vc_col-sm-9 {
      float: left;
  }
} */

/* @media (min-width: 1200px) {
  .vc_col-lg-1, .vc_col-lg-10, .vc_col-lg-11, .vc_col-lg-12, .vc_col-lg-2, .vc_col-lg-3, .vc_col-lg-4, .vc_col-lg-5, .vc_col-lg-6, .vc_col-lg-7, .vc_col-lg-8, .vc_col-lg-9, .vc_col-md-6 {
      float: left;
  }
} */

/* alert-popup */
.custom-ui {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: #28bae6;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}
.custom-ui > h1 {
  margin-top: 0;
}
.custom-ui > button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
}

.alert-general {
  margin: 6rem auto;
}
#alert_category {
  margin: 6rem auto;
}

/* spinner-loading */
.lds-facebook {
  display: block;
  margin: 6rem auto;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background:#A65A6A;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}

.woocommerce-loop-product__link, .eltdf-pli-link {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

#scrollUp {
  background: #A65A6A none repeat scroll 0 0;
  bottom: 85px;
  color: #ffffff;
  cursor: pointer;
  display: none;
  font-size: 20px;
  height: 45px;
  line-height: 39px;
  position: fixed;
  right: 12px;
  text-align: center;
  width: 45px;
  z-index: 999;
  border-radius: 50%;
  border: 2px solid #A65A6A;
  -webkit-transition: .3s;
  transition: .3s;
}

.dropdown-item.active, .dropdown-item:active {
  color: #A65A6A;
  text-decoration: none;
  background-color: #FFF4F3 !important;
}

del {
  text-decoration: line-through;
}

/* .off_canvars_overlay.active {
  opacity: 0.5;
  visibility: visible;
}

.off_canvars_overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  cursor: crosshair;
  background: #333;
  top: 0;
} */
