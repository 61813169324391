@media only screen and (max-width: 1024px) {
    /* pasi qe headerin e boj fixed, me qendru ne top kur boj scroll, athere duhet, ketij top sliderit me i jap margin, sepse ndryshe nuk shihet */    
    .eltdf-sticky-header-top_banner {
        margin: 0 0 40px 0;
    }
}

@media only screen and (max-width: 767px) {
    /* pasi qe headerin e boj fixed, me qendru ne top kur boj scroll, athere duhet, ketij top sliderit me i jap margin, sepse ndryshe nuk shihet */    
    .eltdf-sticky-header-top_banner {
        margin: 60px 0 40px 0;
    }
}
