@font-face {
  font-family: "ZonaPro-ExtraLight";
  src: url("../../zona-pro/FontsFree-Net-ZonaPro-Bold.ttf");
}

@font-face {
  font-family: "ZonaPro-ExtraLight";
  src: url("../../zona-pro/FontsFree-Net-ZonaPro-ExtraLight.ttf");
}

#product_page #yith-quick-view-modal .product,
.eltdf-woo-single-page .product {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  float: none;
  margin: 0 0 50px;
}

#product_page #yith-quick-view-modal .eltdf-single-product-content,
.eltdf-woo-single-page .eltdf-single-product-content {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  clear: both;
  margin: 0 0 20px;
}

#product_page #yith-quick-view-modal .eltdf-single-product-content .images,
.eltdf-woo-single-page .eltdf-single-product-content .images {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 62%;
  margin: 0;
  padding: 0 30px 0 0;
  float: left;
  box-sizing: border-box;
}

#product_page
  #yith-quick-view-modal
  .eltdf-single-product-content
  .images
  figure,
.eltdf-woo-single-page .eltdf-single-product-content .images figure {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

#product_page
  #yith-quick-view-modal
  .eltdf-single-product-content
  .images
  .woocommerce-product-gallery__image:first-child,
.eltdf-woo-single-page
  .eltdf-single-product-content
  .images
  .woocommerce-product-gallery__image:first-child {
  left: 10px;
  width: calc(100% - 20px);
  padding: 0;
}

#product_page
  #yith-quick-view-modal
  .eltdf-single-product-content
  .images
  .woocommerce-product-gallery__image,
.eltdf-woo-single-page
  .eltdf-single-product-content
  .images
  .woocommerce-product-gallery__image {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 0;
  padding: 0 10px;
  box-sizing: border-box;
}

#product_page
  #yith-quick-view-modal
  .eltdf-single-product-content
  .images
  figure
  a,
.eltdf-woo-single-page .eltdf-single-product-content .images figure a {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}

#product_page
  #yith-quick-view-modal
  .eltdf-single-product-content
  .images
  figure
  a
  img,
.eltdf-woo-single-page .eltdf-single-product-content .images figure a img {
  position: relative;
  width: 100%;
  display: block;
}

#product_page #yith-quick-view-modal #yith-quick-view-content div.summary,
#yith-quick-view-modal .eltdf-single-product-summary,
.eltdf-woo-single-page #yith-quick-view-content div.summary,
.eltdf-woo-single-page .eltdf-single-product-summary {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 38%;
  padding: 0 0 0 21px;
  float: left;
  box-sizing: border-box;
}

#product_page
  #yith-quick-view-modal
  #yith-quick-view-content
  div.summary
  .eltdf-single-product-title,
#yith-quick-view-modal
  .eltdf-single-product-summary
  .eltdf-single-product-title,
.eltdf-woo-single-page
  #yith-quick-view-content
  div.summary
  .eltdf-single-product-title,
.eltdf-woo-single-page
  .eltdf-single-product-summary
  .eltdf-single-product-title {
  display: block;
  margin: 4px 0 16px;
  font-family: "Zona Pro";
  color: #a65a6a;
}

#product_page
  #yith-quick-view-modal
  #yith-quick-view-content
  div.summary
  .price,
#yith-quick-view-modal .eltdf-single-product-summary .price,
.eltdf-woo-single-page #yith-quick-view-content div.summary .price,
.eltdf-woo-single-page .eltdf-single-product-summary .price {
  font-family: "Zona Pro";
  color: #acabab;
  font-weight: 600;
  font-size: 18px;
  position: relative;
  display: block;
  margin: 14px 0 0;
  line-height: 1;
}

#product_page
  #yith-quick-view-modal
  #yith-quick-view-content
  div.summary
  .price
  *,
#yith-quick-view-modal .eltdf-single-product-summary .price *,
.eltdf-woo-single-page #yith-quick-view-content div.summary .price *,
.eltdf-woo-single-page .eltdf-single-product-summary .price * {
  font-size: inherit;
  line-height: inherit;
}

#product_page
  #yith-quick-view-modal
  #yith-quick-view-content
  div.summary
  .woocommerce-product-details__short-description,
#yith-quick-view-modal
  .eltdf-single-product-summary
  .woocommerce-product-details__short-description,
.eltdf-woo-single-page
  #yith-quick-view-content
  div.summary
  .woocommerce-product-details__short-description,
.eltdf-woo-single-page
  .eltdf-single-product-summary
  .woocommerce-product-details__short-description {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  margin: 47px 0 0;
}

#product_page
  #yith-quick-view-modal
  #yith-quick-view-content
  div.summary
  .woocommerce-product-details__short-description
  p,
#yith-quick-view-modal
  .eltdf-single-product-summary
  .woocommerce-product-details__short-description
  p,
.eltdf-woo-single-page
  #yith-quick-view-content
  div.summary
  .woocommerce-product-details__short-description
  p,
.eltdf-woo-single-page
  .eltdf-single-product-summary
  .woocommerce-product-details__short-description
  p {
  font-size: 16px;
  line-height: 26px;
  margin: 0;
  font-family: "ZonaPro-ExtraLight";
  color: #acabab;
}

#product_page
  #yith-quick-view-modal
  #yith-quick-view-content
  div.summary
  form.cart,
#yith-quick-view-modal .eltdf-single-product-summary form.cart,
.eltdf-woo-single-page #yith-quick-view-content div.summary form.cart,
.eltdf-woo-single-page .eltdf-single-product-summary form.cart {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  /* margin: 57px 0 0; */
  margin: 27px 0 0;
}

#product_page
  #yith-quick-view-modal
  #yith-quick-view-content
  div.summary
  .eltdf-quantity-buttons,
#yith-quick-view-modal .eltdf-single-product-summary .eltdf-quantity-buttons,
.eltdf-woo-single-page
  #yith-quick-view-content
  div.summary
  .eltdf-quantity-buttons,
.eltdf-woo-single-page .eltdf-single-product-summary .eltdf-quantity-buttons {
  margin: 0 15px 0 0;
}

#product_page .screen-reader-text {
  position: absolute !important;
  width: 1px;
  height: 0;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal !important;
  overflow: hidden;
}

#product_page
  .woocommerce-page
  .eltdf-content
  .eltdf-quantity-buttons
  .eltdf-quantity-minus,
div.woocommerce .eltdf-quantity-buttons .eltdf-quantity-minus {
  bottom: 0;
}

#product_page
  .woocommerce-page
  .eltdf-content
  .eltdf-quantity-buttons
  .eltdf-quantity-minus,
.woocommerce-page .eltdf-content .eltdf-quantity-buttons .eltdf-quantity-plus,
div.woocommerce .eltdf-quantity-buttons .eltdf-quantity-minus,
div.woocommerce .eltdf-quantity-buttons .eltdf-quantity-plus {
  position: absolute;
  right: 0;
  width: 25px;
  height: 25px;
  line-height: 23px;
  border-left: 0;
  cursor: pointer;
  color: #464646;
  -webkit-transition: color 0.15s ease-in-out;
  -moz-transition: color 0.15s ease-in-out;
  transition: color 0.15s ease-in-out;
}

#product_page
  .woocommerce-page
  .eltdf-content
  .eltdf-quantity-buttons
  .eltdf-quantity-input,
.woocommerce-page .eltdf-content .eltdf-quantity-buttons .eltdf-quantity-minus,
.woocommerce-page .eltdf-content .eltdf-quantity-buttons .eltdf-quantity-plus,
div.woocommerce .eltdf-quantity-buttons .eltdf-quantity-input,
div.woocommerce .eltdf-quantity-buttons .eltdf-quantity-minus,
div.woocommerce .eltdf-quantity-buttons .eltdf-quantity-plus {
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
  border: 1px solid #d3d3d3;
  text-align: center;
  box-sizing: border-box;
}

#product_page
  .woocommerce-page
  .eltdf-content
  .eltdf-quantity-buttons
  .eltdf-quantity-plus,
div.woocommerce .eltdf-quantity-buttons .eltdf-quantity-plus {
  top: 0;
  border-bottom: 0;
}

/* #product_page .woocommerce-page .eltdf-content .eltdf-quantity-buttons .eltdf-quantity-minus, .woocommerce-page .eltdf-content .eltdf-quantity-buttons .eltdf-quantity-plus, div.woocommerce .eltdf-quantity-buttons .eltdf-quantity-minus, div.woocommerce .eltdf-quantity-buttons .eltdf-quantity-plus {
    position: absolute;
    right: 0;
    width: 25px;
    height: 25px;
    line-height: 23px;
    border-left: 0;
    cursor: pointer;
    color: #464646;
    -webkit-transition: color .15s ease-in-out;
    -moz-transition: color .15s ease-in-out;
    transition: color .15s ease-in-out;
} */

#product_page .woocommerce-page .eltdf-content .eltdf-quantity-buttons,
div.woocommerce .eltdf-quantity-buttons {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background: none;
}

#product_page
  .woocommerce-page
  .eltdf-content
  .eltdf-quantity-buttons
  .eltdf-quantity-input,
div.woocommerce .eltdf-quantity-buttons .eltdf-quantity-input {
  position: relative;
  top: -1px;
  height: 44px;
  width: 44px;
  padding: 0;
  margin: 0;
  /* border: 0; */
  outline: 0;
  font-size: 19px;
  line-height: 1.578947368421053em;
  text-transform: uppercase;
  font-family: "Zona Pro";
  color: #acabab;
  font-weight: 500;
}

#product_page
  .woocommerce-page
  .eltdf-content
  .eltdf-quantity-buttons
  .eltdf-quantity-minus,
.woocommerce-page .eltdf-content .eltdf-quantity-buttons .eltdf-quantity-plus,
div.woocommerce .eltdf-quantity-buttons .eltdf-quantity-minus,
div.woocommerce .eltdf-quantity-buttons .eltdf-quantity-plus {
  position: relative;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  line-height: 20px;
  cursor: pointer;
  border: 1px solid #000;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

#product_page
  .woocommerce-page
  .eltdf-content
  .eltdf-quantity-buttons
  .eltdf-quantity-minus:before,
div.woocommerce .eltdf-quantity-buttons .eltdf-quantity-minus:before {
  top: 13px;
  left: 3px;
  right: 3px;
  height: 1px;
}

#product_page
  .woocommerce-page
  .eltdf-content
  .eltdf-quantity-buttons
  .eltdf-quantity-minus,
.woocommerce-page .eltdf-content .eltdf-quantity-buttons .eltdf-quantity-plus,
div.woocommerce .eltdf-quantity-buttons .eltdf-quantity-minus,
div.woocommerce .eltdf-quantity-buttons .eltdf-quantity-plus {
  position: relative;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  line-height: 20px;
  cursor: pointer;
  border: 1px solid #e8c9c7;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

#product_page
  .woocommerce-page
  .eltdf-content
  .eltdf-quantity-buttons
  .eltdf-quantity-plus:before,
div.woocommerce .eltdf-quantity-buttons .eltdf-quantity-plus:before {
  top: 13px;
  left: 3px;
  right: 3px;
  height: 1px;
}

#product_page .product-page-eltdf-quantity-minus:after,
#product_page .product-page-eltdf-quantity-minus:before {
  content: "";
  position: absolute;
  background: currentColor;
  color: #acabab;
  font-family: "ZonaPro-ExtraLight";
}

#product_page .product-page-eltdf-quantity-plus:after,
#product_page .product-page-eltdf-quantity-plus:before {
  content: "";
  position: absolute;
  background: currentColor;
  color: #acabab;
  font-family: "ZonaPro-ExtraLight";
}

#product_page
  .woocommerce-page
  .eltdf-content
  .eltdf-quantity-buttons
  .eltdf-quantity-plus:after,
div.woocommerce .eltdf-quantity-buttons .eltdf-quantity-plus:after {
  top: 3px;
  left: 13px;
  bottom: 3px;
  width: 1px;
  color: #acabab;
}

#product_page .arrow_carrot-up:before {
  content: "<";
}

#product_page #yith-quick-view-content div.summary .yith-wcwl-add-to-wishlist,
.eltdf-single-product-summary .yith-wcwl-add-to-wishlist {
  width: auto;
  background-color: transparent;
  margin: 18px 0 0;
}

/* #product_page .yith-wcwl-add-to-wishlist {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 8px 0 0;
    text-align: center;
} */

#product_page
  .eltdf-single-product-summary
  .yith-wcwl-add-to-wishlist
  .yith-wcwl-add-button
  a,
.eltdf-single-product-summary
  .yith-wcwl-add-to-wishlist
  .yith-wcwl-wishlistaddedbrowse
  a,
.eltdf-single-product-summary
  .yith-wcwl-add-to-wishlist
  .yith-wcwl-wishlistexistsbrowse
  a {
  background-color: #e8c9c7;
  color: #e08686;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  padding: 13px 23px 11px;
  font-family: "ZonaPro-Extralight";
  color: #ffffff !important;
  font-weight: bold;
  border-radius: 40px;
}

#product_page
  .eltdf-single-product-summary
  .yith-wcwl-add-to-wishlist
  .yith-wcwl-add-button
  a:after,
.eltdf-single-product-summary
  .yith-wcwl-add-to-wishlist
  .yith-wcwl-wishlistaddedbrowse
  a:after,
.eltdf-single-product-summary
  .yith-wcwl-add-to-wishlist
  .yith-wcwl-wishlistexistsbrowse
  a:after {
  float: left;
  padding: 0 8px 0 0;
  font-size: 28px;
  line-height: 24px;
  content: "\f004" !important;
  font-family: FontAwesome !important;
}

#product_page .btn-add-to-cart a {
  background-color: #a65a6a !important;
  font-family: "Zona Pro";
  text-transform: none;
  min-width: 200px;
  text-align: center;
}

#product_page .btn-add-to-cart a:after {
  content: "" !important;
}

#product_page
  #yith-quick-view-content
  div.summary
  .yith-wcwl-add-to-wishlist
  .yith-wcwl-add-button
  a,
#yith-quick-view-content
  div.summary
  .yith-wcwl-add-to-wishlist
  .yith-wcwl-wishlistaddedbrowse
  a,
#yith-quick-view-content
  div.summary
  .yith-wcwl-add-to-wishlist
  .yith-wcwl-wishlistexistsbrowse
  a,
.eltdf-single-product-summary
  .yith-wcwl-add-to-wishlist
  .yith-wcwl-add-button
  a,
.eltdf-single-product-summary
  .yith-wcwl-add-to-wishlist
  .yith-wcwl-wishlistaddedbrowse
  a,
.eltdf-single-product-summary
  .yith-wcwl-add-to-wishlist
  .yith-wcwl-wishlistexistsbrowse
  a {
  font-size: 14px;
  line-height: inherit;
  color: #000;
  font-weight: 400;
  text-transform: lowercase;
  vertical-align: middle;
}

#product_page .eltdf-woo-single-page .entry-summary .yith-wcwl-add-button a,
.eltdf-woo-single-page .entry-summary .yith-wcwl-wishlistaddedbrowse a,
.eltdf-woo-single-page .entry-summary .yith-wcwl-wishlistexistsbrowse a {
  font-size: 14px !important;
  font-weight: 500 !important;
}

#product_page .wishlist_table .add_to_cart.button,
.yith-wcwl-add-button a.add_to_wishlist,
.yith-wcwl-popup-button a.add_to_wishlist,
.wishlist_table a.ask-an-estimate-button,
.wishlist-title a.show-title-form,
.hidden-title-form a.hide-title-form,
.woocommerce .yith-wcwl-wishlist-new button,
.wishlist_manage_table a.create-new-wishlist,
.wishlist_manage_table button.submit-wishlist-changes,
.yith-wcwl-wishlist-search-form button.wishlist-search-button {
  margin: 0px;
  box-shadow: none;
  text-shadow: none;
  border-radius: 3px;
}

#product_page a.add_to_wishlist {
  cursor: pointer;
}

#product_page
  #yith-quick-view-content
  div.summary
  .yith-wcwl-add-to-wishlist
  .yith-wcwl-add-button
  a:before,
.eltdf-single-product-summary
  .yith-wcwl-add-to-wishlist
  .yith-wcwl-add-button
  a:before {
  content: "\f004";
  color: #000;
}

#product_page
  #yith-quick-view-content
  div.summary
  .yith-wcwl-add-to-wishlist
  .yith-wcwl-add-button
  a:before,
#yith-quick-view-content
  div.summary
  .yith-wcwl-add-to-wishlist
  .yith-wcwl-wishlistaddedbrowse
  a:before,
#yith-quick-view-content
  div.summary
  .yith-wcwl-add-to-wishlist
  .yith-wcwl-wishlistexistsbrowse
  a:before,
.eltdf-single-product-summary
  .yith-wcwl-add-to-wishlist
  .yith-wcwl-add-button
  a:before,
.eltdf-single-product-summary
  .yith-wcwl-add-to-wishlist
  .yith-wcwl-wishlistaddedbrowse
  a:before,
.eltdf-single-product-summary
  .yith-wcwl-add-to-wishlist
  .yith-wcwl-wishlistexistsbrowse
  a:before {
  font-size: 17px;
  padding: 0 2px;
}

#product_page
  #yith-quick-view-modal
  #yith-quick-view-content
  div.summary
  .product_meta,
#yith-quick-view-modal .eltdf-single-product-summary .product_meta,
.eltdf-woo-single-page #yith-quick-view-content div.summary .product_meta,
.eltdf-woo-single-page .eltdf-single-product-summary .product_meta {
  margin: 52px 0 0;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.29em;
  color: #3d3d3d;
}

#product_page
  #yith-quick-view-modal
  #yith-quick-view-content
  div.summary
  .product_meta
  > span,
#yith-quick-view-modal .eltdf-single-product-summary .product_meta > span,
.eltdf-woo-single-page
  #yith-quick-view-content
  div.summary
  .product_meta
  > span,
.eltdf-woo-single-page .eltdf-single-product-summary .product_meta > span {
  display: block;
  margin: 3px 0;
}

#product_page
  #yith-quick-view-modal
  #yith-quick-view-content
  div.summary
  .product_meta
  > span,
#yith-quick-view-modal .eltdf-single-product-summary .product_meta > span,
.eltdf-woo-single-page
  #yith-quick-view-content
  div.summary
  .product_meta
  > span,
.eltdf-woo-single-page .eltdf-single-product-summary .product_meta > span {
  display: block;
  margin: 3px 0;
  font-family: "Zona Pro";
  color: #a65a6a;
  text-transform: none;
}

#product_page .sku,
#product_page .posted_in a,
#product_page .tagged_as a {
  /* font-family: 'ZonaPro-Extralight'; */
  color: #acabab;
}

#product_page
  #yith-quick-view-modal
  #yith-quick-view-content
  div.summary
  .product_meta
  > span
  a,
#yith-quick-view-modal
  #yith-quick-view-content
  div.summary
  .product_meta
  > span
  a:hover,
#yith-quick-view-modal .eltdf-single-product-summary .product_meta > span a,
#yith-quick-view-modal
  .eltdf-single-product-summary
  .product_meta
  > span
  a:hover,
.eltdf-woo-single-page
  #yith-quick-view-content
  div.summary
  .product_meta
  > span
  a,
.eltdf-woo-single-page
  #yith-quick-view-content
  div.summary
  .product_meta
  > span
  a:hover,
.eltdf-woo-single-page .eltdf-single-product-summary .product_meta > span a,
.eltdf-woo-single-page
  .eltdf-single-product-summary
  .product_meta
  > span
  a:hover {
  color: #000;
}

#product_page #yith-quick-view-modal .woocommerce-tabs,
.eltdf-woo-single-page .woocommerce-tabs {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  margin: 34px 0 0;
}

/* #product_page .woocommerce-page .eltdf-content input[type=text], .woocommerce-page .eltdf-content input[type=email], .woocommerce-page .eltdf-content input[type=tel], .woocommerce-page .eltdf-content input[type=password], .woocommerce-page .eltdf-content textarea, div.woocommerce input[type=text], div.woocommerce input[type=email], div.woocommerce input[type=tel], div.woocommerce input[type=password], div.woocommerce textarea {
    position: relative;
    width: 100%;
    margin: 0 0 20px;
    padding: 5px 0;
    font-family: Montserrat,sans-serif;
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .16em;
    line-height: calc(30px - (5px * 2) - 2px);
    color: #000;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    outline: 0;
    cursor: pointer;
    -webkit-appearance: none;
    box-sizing: border-box;
    -webkit-transition: border-color .2s ease-in-out;
    -moz-transition: border-color .2s ease-in-out;
    transition: border-color .2s ease-in-out;
} */

#product_page p.form-submit:before {
  position: absolute;
  transform: scale(1, 1);
  transform-origin: 0 50%;
}

#product_page p.form-submit:before,
p.form-submit:hover:before {
  content: "";
  bottom: -4px;
  left: 0;
  height: 1px;
  background-color: inherit;
  transition: transform 0.4s cubic-bezier(0.28, 0.38, 0, 0.81);
  width: 100%;
  right: 0;
}

#product_page
  .woocommerce-page
  .eltdf-content
  .wc-forward:not(.added_to_cart):not(.checkout-button),
.woocommerce-page .eltdf-content a.added_to_cart,
.woocommerce-page .eltdf-content a.button,
.woocommerce-page
  .eltdf-content
  button[type="submit"]:not(.eltdf-search-submit),
.woocommerce-page .eltdf-content input[type="submit"],
div.woocommerce .wc-forward:not(.added_to_cart):not(.checkout-button),
div.woocommerce a.added_to_cart,
div.woocommerce a.button,
div.woocommerce button[type="submit"]:not(.eltdf-search-submit),
div.woocommerce input[type="submit"] {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: auto;
  margin: 0;
  font-family: Poppins, sans-serif;
  font-size: 13px;
  line-height: 2.1em;
  font-weight: 600;
  outline: 0;
  box-sizing: border-box;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;
  /* padding: 9px 46px; */
  color: #fff;
  /* background-color: #000; */
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
  z-index: 3;
  text-transform: capitalize;
}

#product_page
  .woocommerce-page
  .eltdf-content
  .wc-forward:not(.added_to_cart):not(.checkout-button):before,
.woocommerce-page .eltdf-content a.added_to_cart:before,
.woocommerce-page .eltdf-content a.button:before,
.woocommerce-page
  .eltdf-content
  button[type="submit"]:not(.eltdf-search-submit):before,
.woocommerce-page .eltdf-content input[type="submit"]:before,
div.woocommerce .wc-forward:not(.added_to_cart):not(.checkout-button):before,
div.woocommerce a.added_to_cart:before,
div.woocommerce a.button:before,
div.woocommerce button[type="submit"]:not(.eltdf-search-submit):before,
div.woocommerce input[type="submit"]:before {
  content: "";
  position: absolute;
  bottom: -4px;
  left: -1px;
  right: 0;
  height: 1px;
  background-color: inherit;
  width: calc(100% + 2px);
  transform: scale(1, 1);
  transform-origin: 0 50%;
  transition: transform 0.4s cubic-bezier(0.28, 0.38, 0, 0.81);
}

#yith-quick-view-modal
  .eltdf-single-product-content
  .images.woocommerce-product-gallery--columns-5
  .woocommerce-product-gallery__image:not(:first-child),
.eltdf-woo-single-page
  .eltdf-single-product-content
  .images.woocommerce-product-gallery--columns-5
  .woocommerce-product-gallery__image:not(:first-child) {
  width: 20%;
}

#yith-quick-view-modal
  .eltdf-single-product-content
  .images.woocommerce-product-gallery--with-images
  .woocommerce-product-gallery__image:not(:first-child),
.eltdf-woo-single-page
  .eltdf-single-product-content
  .images.woocommerce-product-gallery--with-images
  .woocommerce-product-gallery__image:not(:first-child) {
  float: left;
  padding-top: 20px;
}

/*Product sizes */
#product-sizes-pr12345678 {
  margin: 27px 0 0;
}

#product-sizes-pr12345678 .list-inline {
  padding-left: 0;
  list-style: none;
}

#product-sizes-pr12345678 .list-inline li {
  width: 35px;
  height: 35px;
  line-height: 30px;
  text-align: center;
  border-radius: 40px;
  border: 1px solid #e8c9c7;
  cursor: pointer;
  margin-right: 6px;
  background: #fff;
  margin-bottom: 10px;
  padding: 0 7px;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
}

#product-sizes-pr12345678 .list-inline li span {
  font-size: 10px;
  font-weight: 700;
  line-height: 20px;
  font-family: "Zona Pro";
  color: #acabab;
}


/*product sizes */

/*Color Picker */

#product_page .eltdf-content .variations {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 27px 0 0;
  border: none;
  padding: 0;
  width: 100%;
  cursor: pointer;
}

#product_page .eltdf-content .variations tr {
  padding: 0;
  font-weight: 500;
  line-height: 20px;
}

#product_page .eltdf-content .variations tr:last-child {
  margin: 0;
}

#product_page .eltdf-content .variations tr .value {
  display: flex;
}
#product_page .eltdf-content .variations td {
  padding: 0;
  text-align: initial;
  vertical-align: top;
}

#product_page .select_box_colorpicker {
  display: flex;
}

#product_page
  .eltdf-content
  .variations
  tr
  .value
  .select_box_colorpicker
  .select_option_colorpicker {
  display: flex;
  margin-right: 10px;
  border: none;
}

#product_page
  .eltdf-content
  .variations
  tr
  .value
  .select_box_colorpicker
  .select_option_colorpicker
  .yith_wccl_value {
  width: 20px;
  height: 20px;
  min-width: 20px;
  line-height: inherit;
  border: none;
  border-radius: 100%;
}

/* react-tabs un e kom selektu */
.react-tabs__tab-list {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
  border: none;
  clear: both;
}

.react-tabs__tab-list > li {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-family: "Zona Pro";
  color: #a65a6a;
  font-size: 17px;
  font-weight: 700;
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 13px 26px;
}

.react-tabs__tab-list:after {
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #ccc;
  height: 1px;
}

.react-tabs__tab--selected {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #a65a6a;
}

.react-tabs__tab:hover {
  border-bottom: 1px solid #a65a6a;
}

.react-tabs__tab:focus {
  box-shadow: none;
  border-color: none;
  outline: none;
}

.react-tabs__tab-panel > p {
  margin: 32px 0 20px;
  line-height: 26px;
  font-family: "ZonaPro-Extralight";
  color: #acabab;
}

/* react-tabs un e kom selektu */

/* image slider modal*/
.modal-dialog {
  max-width: 730px;
}

.wrap-modal-slider {
  padding: 0 30px;
  transition: all 0.3s;
}

.wrap-modal-slider.open {
  opacity: 1;
}

.slick-prev:before,
.slick-next:before {
  color: red;
}
/*image slider modal */

/* Product Size */
#product_page .product-size .size-choose div {
  display: inline-block;
  margin: 15px 0 0 0;
}

#product_page .product-size .size-choose input[type='radio'] {
  display: none;
}

#product_page .product-size .size-choose input[type="radio"] + label span {
  width: 45px;
  height: 45px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #e8c9c7;
  cursor: pointer;
  margin-right: 6px;
  background: #fff;
  margin-bottom: 10px;
  padding: 0 7px;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: inline-box;
  display: -moz-inline-flex;
  display: inline-flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
}

#product_page .product-size .size-choose input[type="radio"] + label span {
  font-family: "Zona Pro";
  color: #acabab;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
}

#product_page .product-size .size-choose input[type="radio"] + label .out-of-stock {
  background-image: url(../../assets/cancel.svg);
  background-repeat: no-repeat;
  background-position: center;
  pointer-events: none;
}

#product_page .product-size .size-choose input[type="radio"]:checked + label span {
  background: #a65a6a;
  color: #fff;
}

/* Product Size */

/* Product Color */
#product_page .product-color .color-choose div {
  display: inline-block;
  margin: 15px 0 0 0;
}

#product_page .product-color .color-choose input[type='radio'] {
  display: none;
}

#product_page .product-color .color-choose input[type="radio"] + label span {
  display: inline-block;
  width: 35px;
  height: 35px;
  margin: -1px 4px 0 0;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
}

#product_page .product-color .color-choose input[type="radio"] + label span {
  border: none;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
}

#product_page .product-color .color-choose input[type="radio"]:checked + label span {
  background-image: url(../../assets/check-icn.svg);
  background-repeat: no-repeat;
  background-position: center;
}

/* Product Color */

@media only screen and (min-width: 769px) {
  #product_page
    #yith-quick-view-modal.eltdf-woo-single-thumb-on-left-side
    .eltdf-single-product-content
    .images
    figure,
  .eltdf-woo-single-page.eltdf-woo-single-thumb-on-left-side
    .eltdf-single-product-content
    .images
    figure {
    width: 100%;
    margin: 0;
    overflow: hidden;
  }
}

@media only screen and (min-width: 769px) {
  #product_page
    #yith-quick-view-modal.eltdf-woo-single-thumb-on-left-side
    .eltdf-single-product-content
    .images
    .woocommerce-product-gallery__image:first-child,
  .eltdf-woo-single-page.eltdf-woo-single-thumb-on-left-side
    .eltdf-single-product-content
    .images
    .woocommerce-product-gallery__image:first-child {
    left: 121px;
    width: calc(100% - 121px);
  }
}

@media only screen and (min-width: 769px) {
  #product_page
    #yith-quick-view-modal.eltdf-woo-single-thumb-on-left-side
    .eltdf-single-product-content
    .images.woocommerce-product-gallery--columns-5
    .woocommerce-product-gallery__image:nth-child(2n),
  .eltdf-woo-single-page.eltdf-woo-single-thumb-on-left-side
    .eltdf-single-product-content
    .images.woocommerce-product-gallery--columns-5
    .woocommerce-product-gallery__image:nth-child(2n) {
    top: calc(0% + 0px);
  }

  #product_page
    #yith-quick-view-modal.eltdf-woo-single-thumb-on-left-side
    .eltdf-single-product-content
    .images.woocommerce-product-gallery--columns-5
    .woocommerce-product-gallery__image:not(:first-child),
  .eltdf-woo-single-page.eltdf-woo-single-thumb-on-left-side
    .eltdf-single-product-content
    .images.woocommerce-product-gallery--columns-5
    .woocommerce-product-gallery__image:not(:first-child) {
    height: calc(20% + 2px);
  }

  #product_page
    #yith-quick-view-modal.eltdf-woo-single-thumb-on-left-side
    .eltdf-single-product-content
    .images.woocommerce-product-gallery--with-images
    .woocommerce-product-gallery__image:not(:first-child),
  .eltdf-woo-single-page.eltdf-woo-single-thumb-on-left-side
    .eltdf-single-product-content
    .images.woocommerce-product-gallery--with-images
    .woocommerce-product-gallery__image:not(:first-child) {
    position: absolute;
    top: 0;
    left: 0;
    width: 111px;
    padding: 0 0 10px;
    box-sizing: border-box;
  }

  #product_page
    #yith-quick-view-modal.eltdf-woo-single-thumb-on-left-side
    .eltdf-single-product-content
    .images.woocommerce-product-gallery--with-images
    .woocommerce-product-gallery__image:not(:first-child)
    a,
  #yith-quick-view-modal.eltdf-woo-single-thumb-on-left-side
    .eltdf-single-product-content
    .images.woocommerce-product-gallery--with-images
    .woocommerce-product-gallery__image:not(:first-child)
    a
    img,
  .eltdf-woo-single-page.eltdf-woo-single-thumb-on-left-side
    .eltdf-single-product-content
    .images.woocommerce-product-gallery--with-images
    .woocommerce-product-gallery__image:not(:first-child)
    a,
  .eltdf-woo-single-page.eltdf-woo-single-thumb-on-left-side
    .eltdf-single-product-content
    .images.woocommerce-product-gallery--with-images
    .woocommerce-product-gallery__image:not(:first-child)
    a
    img {
    height: 100%;
  }

  #product_page
    #yith-quick-view-modal.eltdf-woo-single-thumb-on-left-side
    .eltdf-single-product-content
    .images.woocommerce-product-gallery--columns-5
    .woocommerce-product-gallery__image:nth-child(3n),
  .eltdf-woo-single-page.eltdf-woo-single-thumb-on-left-side
    .eltdf-single-product-content
    .images.woocommerce-product-gallery--columns-5
    .woocommerce-product-gallery__image:nth-child(3n) {
    top: calc(20% + 2px);
  }

  #product_page
    #yith-quick-view-modal.eltdf-woo-single-thumb-on-left-side
    .eltdf-single-product-content
    .images.woocommerce-product-gallery--columns-5
    .woocommerce-product-gallery__image:not(:first-child),
  .eltdf-woo-single-page.eltdf-woo-single-thumb-on-left-side
    .eltdf-single-product-content
    .images.woocommerce-product-gallery--columns-5
    .woocommerce-product-gallery__image:not(:first-child) {
    height: calc(20% + 2px);
  }

  #product_page
    #yith-quick-view-modal.eltdf-woo-single-thumb-on-left-side
    .eltdf-single-product-content
    .images.woocommerce-product-gallery--columns-5
    .woocommerce-product-gallery__image:nth-child(4n),
  .eltdf-woo-single-page.eltdf-woo-single-thumb-on-left-side
    .eltdf-single-product-content
    .images.woocommerce-product-gallery--columns-5
    .woocommerce-product-gallery__image:nth-child(4n) {
    top: calc(40% + 4px);
  }

  #product_page
    #yith-quick-view-modal.eltdf-woo-single-thumb-on-left-side
    .eltdf-single-product-content
    .images.woocommerce-product-gallery--columns-5
    .woocommerce-product-gallery__image:not(:first-child),
  .eltdf-woo-single-page.eltdf-woo-single-thumb-on-left-side
    .eltdf-single-product-content
    .images.woocommerce-product-gallery--columns-5
    .woocommerce-product-gallery__image:not(:first-child) {
    height: calc(20% + 2px);
  }

  #product_page
    #yith-quick-view-modal.eltdf-woo-single-thumb-on-left-side
    .eltdf-single-product-content
    .images.woocommerce-product-gallery--columns-5
    .woocommerce-product-gallery__image:nth-child(5n),
  .eltdf-woo-single-page.eltdf-woo-single-thumb-on-left-side
    .eltdf-single-product-content
    .images.woocommerce-product-gallery--columns-5
    .woocommerce-product-gallery__image:nth-child(5n) {
    top: calc(60% + 6px);
  }

  #product_page
    #yith-quick-view-modal.eltdf-woo-single-thumb-on-left-side
    .eltdf-single-product-content
    .images.woocommerce-product-gallery--columns-5
    .woocommerce-product-gallery__image:nth-child(6n),
  .eltdf-woo-single-page.eltdf-woo-single-thumb-on-left-side
    .eltdf-single-product-content
    .images.woocommerce-product-gallery--columns-5
    .woocommerce-product-gallery__image:nth-child(6n) {
    top: calc(80% + 8px);
  }
}

@media only screen and (max-width: 1024px) {
  #product_page
    .eltdf-woo-single-page
    .eltdf-single-product-content
    .eltdf-single-product-summary,
  #product_page .eltdf-woo-single-page .eltdf-single-product-content .images {
    float: none;
    width: 100%;
    padding: 0;
  }
}
