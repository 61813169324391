.woocommerce-wishlist table.wishlist_table {
    font-size: inherit;
    margin: 50px 0 100px;
}

.woocommerce-wishlist table.wishlist_table thead tr {
    border: none;
    color: #000;
    font-family: Poppins,sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.235em;
    text-transform: capitalize;
}

.woocommerce-wishlist table.wishlist_table thead tr th.product-remove {
    width: 18px;
}

.woocommerce-wishlist table.wishlist_table thead tr th {
    border: none;
    text-align: left;
    padding: 16px 12px;
    font-weight: 600;
}

.wishlist_table tr th.product-remove {
    vertical-align: middle;
    /* width: 40px; */
}

.woocommerce-wishlist table.wishlist_table thead tr th {
    border: none;
    text-align: left;
    padding: 16px 12px;
    font-weight: 600;
    font-family: 'Zona Pro';
    color: #A65A6A;
}

.woocommerce-wishlist table.wishlist_table thead tr th.product-name {
    position: absolute;
    left: 0;
    padding-left: 0;
}

.woocommerce-wishlist table.wishlist_table tbody tr {
    border: 0;
    border-bottom: 1px solid #e6e6e6;
}

.woocommerce-wishlist table.wishlist_table tbody tr td.product-remove {
    text-align: left;
}

.woocommerce table.wishlist_table tr td.product-remove {
    padding: 16px 0;
}

.woocommerce-wishlist table.wishlist_table tbody tr td.product-remove a {
    color: #000;
    font-size: 0;
}

.woocommerce-wishlist table.wishlist_table tbody tr td.product-remove a:before {
    content: "\2715";
    display: inline-block;
    vertical-align: middle;
    speak: none;
    color: #B6B5B5;
    font-family: ElegantIcons;
    font-size: 18px;
    line-height: inherit;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.woocommerce-wishlist table.wishlist_table tbody tr td.product-thumbnail {
    padding-left: 8px;
    padding-right: 8px;
    width: 115px;
}

.woocommerce-wishlist table.wishlist_table tbody tr td {
    vertical-align: middle;
    text-align: left;
    padding: 30px 12px;
}

.woocommerce-wishlist table.wishlist_table tbody tr td.product-thumbnail a, .woocommerce-wishlist table.wishlist_table tbody tr td.product-thumbnail img {
    display: block;
    max-width: 115px;
    max-height: 115px;
    object-fit: cover;
}

.woocommerce-wishlist table.wishlist_table tbody tr td.product-name a {
    color: #000;
    font-family: Poppins,sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.235em;
    text-transform: capitalize;
    text-align: left;
}

.woocommerce-wishlist table.wishlist_table tbody tr td.product-name .yith-wcqv-button {
    display: none;
}

.woocommerce-wishlist table.wishlist_table tbody tr td.product-name a {
    color: #A65A6A;
    font-family: 'Zona Pro';
    font-size: 15px;
    font-weight: 500;
    line-height: 1.235em;
    text-transform: capitalize;
    text-align: left;
}

.woocommerce-wishlist table.wishlist_table tbody tr td.product-price, .woocommerce-wishlist table.wishlist_table tbody tr td.product-stock-status {
    font-size: 14px;
    font-family: 'ZonaPro-Extralight';
    color: #ACABAB;
}

.woocommerce-wishlist table.wishlist_table tbody tr td.product-stock-status span {
    color: #000!important;
}


/* butoni */
.woocommerce-wishlist table.wishlist_table tbody tr td.product-add-to-cart {
    text-align: right;

}

.woocommerce-wishlist table.wishlist_table tbody tr td.product-add-to-cart a {
    border-radius: 0;
    border: 0;
    display: inline-block!important;
}

.woocommerce .wishlist_table td.product-add-to-cart a {
    margin: 0;
    margin-bottom: 5px;
    text-align: center;

    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: auto;
    margin: 0;
    font-family: 'ZonaPro-Extralight';
    font-size: 13px;
    line-height: 2.1em;
    font-weight: 600;
    outline: 0;
    box-sizing: border-box;
    -webkit-transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out;
    -moz-transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out;
    transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out;
    padding: 9px 46px;
    color: #A65A6A;
    border-radius: 40px !important;
    background-color: #E8C9C7;
    border: 1px solid transparent;
    white-space: nowrap;
    cursor: pointer;
    z-index: 3;
    text-transform: capitalize;

}

#wishlist-button:before {
    background-color: unset;
}

/*butoni */

.add-to-cart-wishlist_ab11 a {
    font-family: "ZonaPro-Extralight";
    font-size: 13px;
    line-height: 2.1em;
    font-weight: 600;
    outline: 0;
    box-sizing: border-box;
    transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out;
    padding: 15px 46px;
    color: #a65a6a;
    border-radius: 40px!important;
    background-color: #e8c9c7;
}

.cart-mobile-quantity a {
    font-family: "ZonaPro-Extralight";
    font-size: 13px;
    line-height: 2.1em;
    font-weight: 600;
    outline: 0;
    box-sizing: border-box;
    transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out;
    padding: 15px 46px;
    color: #a65a6a;
    border-radius: 40px!important;
    background-color: #e8c9c7;
}

.cart-mobile-quantity a:hover, .add-to-cart-wishlist_ab11 a:hover {
    color: #A65A6A;
}

.wishlist_table tr td.product-thumbnail, .wishlist_table tr td.product-name, .wishlist_table tr td.wishlist-name, .wishlist_table tr td.wishlist-privacy, .woocommerce table.wishlist_table tfoot td {
    text-align: left;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    vertical-align: middle;
    width: 100%;
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    #wishlist_table_part_000 .eltdf-eh-item-content {
        padding: 38px 11% 0 11% !important;
    }
}

@media only screen and (max-width: 680px) {
    .woocommerce-wishlist table.wishlist_table thead tr th {
        padding: 14px 10px;
        font-size: 14px;
    }

    .woocommerce-wishlist table.wishlist_table tbody tr td.product-thumbnail, .woocommerce-wishlist table.wishlist_table thead tr th.product-thumbnail {
        display: none;
    }

    .woocommerce-wishlist table.wishlist_table thead tr th.product-name {
        position: relative;
    }

    .woocommerce-wishlist table.wishlist_table tbody tr td {
        padding: 25px 6px;
        line-height: 1.2em;
    }

    .woocommerce-wishlist table.wishlist_table tbody tr td.product-name a {
        font-size: 12px;
        padding-left: 1px;
        padding-right: 5px;
    }

    .woocommerce-wishlist table.wishlist_table tbody tr td.product-add-to-cart a {
        padding: 0;
        font-size: 12px;
        color: #000;
        background-color: transparent;
        border: 0;
    }

    .eltdf-eh-item-content {
        padding: 0px 15% 0 15% !important;
    }
}