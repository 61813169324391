@font-face {
    font-family: "ZonaPro-ExtraLight";
    src: url("../../zona-pro/FontsFree-Net-ZonaPro-Bold.ttf");
}

@font-face {
    font-family: "ZonaPro-ExtraLight";
    src: url("../../zona-pro/FontsFree-Net-ZonaPro-ExtraLight.ttf");
}

/* .sticky {
  position: fixed !important;
  top: 0;
  width: 100% !important;
  z-index: 110 !important;
} */

/* #top-header-sticky {
  position: fixed;
  width: 100%;
  top: -48px;
  left: 0;;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  -ms-opacity: 0;
  -o-opacity: 0;
  opacity: 0;
  z-index: 110;
}

#top-header-sticky.sticky-menu__active {
  top: 0;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  -ms-opacity: 1;
  -o-opacity: 1;
  opacity: 1;
  visibility: visible;
} */

.eltdf-top-bar {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    height: 46px;
    font-size: 12px;
    line-height: 1;
    color: #fff;
    box-sizing: border-box;
    /* z-index: 111; */
}

.eltdf-top-bar>.eltdf-vertical-align-containers {
    padding: 0 40px;
    background-color: #FFF4F3;
}

.eltdf-vertical-align-containers .eltdf-position-left-top-header {
    position: relative;
    height: 100%;
    float: left;
}

.eltdf-top-bar .eltdf-vertical-align-containers .eltdf-position-left-inner, .eltdf-top-bar .eltdf-vertical-align-containers .eltdf-position-right-inner {
    display: -webkit-flex;
    display: flex;
    height: 100%;
    -webkit-align-items: center;
    align-items: center;
}

.eltdf-top-bar .widget.widget_text {
    margin: 0 8px 0 0;
}

.eltdf-top-bar .textwidget:hover {
    cursor: pointer;
}

.eltdf-top-bar .widget p {
    margin: 0;
    font-family: 'Zona Pro';
    color: #A65A6A;
}

.eltdf-vertical-align-containers .eltdf-position-right-top-header {
    position: relative;
    height: 100%;
    float: right;
    text-align: right;
}

.eltdf-top-bar .eltdf-vertical-align-containers .eltdf-position-left-inner, .eltdf-top-bar .eltdf-vertical-align-containers .eltdf-position-right-inner {
    display: -webkit-flex;
    display: flex;
    height: 100%;
    -webkit-align-items: center;
    align-items: center;
}

.eltdf-top-bar .widget {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
}

.eltdf-top-bar .eltdf-login-register-widget.eltdf-user-not-logged-in .eltdf-login-opener {
    font-family: 'Zona Pro';
    color: #A65A6A;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: .16em;
    vertical-align: top;
}

.widget.eltdf-custom-font-widget, .widget.eltdf-custom-font-widget>* {
    margin: 0;
}

.eltdf-custom-font-holder a {
    position: relative;
    color: inherit;
}

.eltdf-top-bar .eltdf-custom-font-holder a:hover {
    color: #A65A6A !important;
}


/* left side modal */

/* sidebar-menu for subcatgories */
.items-menu {
  padding-left: 10%;
}

.menu-list-item.main-category {
  text-transform: uppercase;
}

.menu-list-item {
  display: flex;
  padding: 5px 0 5px 0;
  text-align: left;
}

.menu-list-item a {
  font-family: 'Zona Pro';
  color: #A65A6A;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  padding: 0;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
}

.menu-list-item a:hover {
  color: #A65A6A;
}

.menu-list-item .selected-item:after {
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  transition: width .3s ease 0s,left .3s ease 0s;
  width: 60px;
  text-align: center;
  background: #000;
}

.menu-list-item a:after {
  max-width: 70%!important;
}

.category-content {
  max-height: 2000px;
  width: 100%;
  transition: max-height 1s ease-in;
  overflow: hidden;
}

.items-menu-columns {
  display: flex;
  align-items: start;
  width: 100%;
  height: 75%;
  padding: 4px;
  padding-left: 12px;
  padding-top: 8px;
}

.items-menu-columns .column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding: 4px;
}

.items-menu .category-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s ease-out;
}

.items-menu .show {
  max-height: 2000px;
  width: 100%;
  transition: max-height 1s ease-in;
}

.items-menu-columns .column .menu-list-item {
  padding: 4px 0 10px 0;
}

.items-menu-columns .column .menu-list-item a {
  font-family: 'Zona Pro';
  color: #A65A6A;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  text-transform: uppercase;
}

.items-menu .menu-list-item a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  background: #A65A6A;
  transition: width .3s ease 0s,left .3s ease 0s;
  width: 0;
  max-width: 60px;
  text-align: center;
}

.items-menu .menu-list-item a:hover:after {
  width: 100%;
  left: 10px;
  right: 10px;
}

.items-menu .menu-list-item a:after {
  max-width: 70%!important;
}

.close-container{
  position: absolute;
  top: 0;
  right: 2rem;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}

.leftright{
  height: 4px;
  width: 25px;
  position: absolute;
  margin-top: 24px;
  background-color:#A65A6A;
  border-radius: 2px;
  transform: rotate(45deg);
  transition: all .3s ease-in;
}

.rightleft{
  height: 4px;
  width: 25px;
  position: absolute;
  margin-top: 24px;
  background-color:#A65A6A;
  border-radius: 2px;
  transform: rotate(-45deg);
  transition: all .3s ease-in;
}

.close{
  margin: 60px 0 0 5px;
  position: absolute;
}

.close-container:hover .leftright{
  transform: rotate(-45deg);
  background-color: #F25C66;
}
.close-container:hover .rightleft{
  transform: rotate(45deg);
  background-color: #F25C66;
}
.close-container:hover label{
  opacity: 1;
}

/*logout profile */
.dropdown-menu {
  min-width: 7rem !important;
}

@media only screen and (max-width: 767px) {
    .eltdf-top-bar {
        display: none;
    }
}