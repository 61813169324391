@font-face {
    font-family: "ZonaPro-ExtraLight";
    src: url("../../zona-pro/FontsFree-Net-ZonaPro-Bold.ttf");
}
@font-face {
    font-family: "ZonaPro-ExtraLight";
    src: url("../../zona-pro/FontsFree-Net-ZonaPro-ExtraLight.ttf");
}

.eltdf-grid-col-10:after, .eltdf-grid-col-10:before, .eltdf-grid-col-11:after, .eltdf-grid-col-11:before, .eltdf-grid-col-12:after, .eltdf-grid-col-12:before, .eltdf-grid-col-1:after, .eltdf-grid-col-1:before, .eltdf-grid-col-2:after, .eltdf-grid-col-2:before, .eltdf-grid-col-3:after, .eltdf-grid-col-3:before, .eltdf-grid-col-4:after, .eltdf-grid-col-4:before, .eltdf-grid-col-5:after, .eltdf-grid-col-5:before, .eltdf-grid-col-7:after, .eltdf-grid-col-7:before, .eltdf-grid-col-8:after, .eltdf-grid-col-8:before, .eltdf-grid-col-9:after, .eltdf-grid-col-9:before, .eltdf-grid-row:after, .eltdf-grid-row:before {
    content: " ";
    display: table;
}
.eltdf-side-menu-slide-from-right .eltdf-wrapper {
    background-color: #fff;
    position: relative;
    /* z-index: 1000; */
    z-index: 100;
    -webkit-transition: all .6s cubic-bezier(.77,0,.175,1);
    -moz-transition: all .6s cubic-bezier(.77,0,.175,1);
    transition: all .6s cubic-bezier(.77,0,.175,1);
}
.eltdf-wrapper {
    position: relative;
    left: 0;
    z-index: 1000;
    -webkit-transition: left .33s cubic-bezier(.694,.0482,.335,1);
    -moz-transition: left .33s cubic-bezier(.694,.0482,.335,1);
    transition: left .33s cubic-bezier(.694,.0482,.335,1);
}
.eltdf-wrapper .eltdf-wrapper-inner {
    width: 100%;
    overflow: hidden;
}
.eltdf-header-bottom .eltdf-content {
    -webkit-transition: transform .6s cubic-bezier(.165,.84,.44,1);
    -moz-transition: transform .6s cubic-bezier(.165,.84,.44,1);
    transition: transform .6s cubic-bezier(.165,.84,.44,1);
}
.eltdf-content {
    position: relative;
    margin-top: 0;
    background-color: #fff;
    z-index: 100;
}
.eltdf-container, .eltdf-full-width {
    z-index: 100;
    position: relative;
}
.page-id-4584 .eltdf-content .eltdf-content-inner > .eltdf-container > .eltdf-container-inner, .page-id-4584 .eltdf-content .eltdf-content-inner > .eltdf-full-width > .eltdf-full-width-inner {
    /* padding: 68px 0 0 0; */
    padding: 80px 0 0 0;
}
.eltdf-grid-row {
    margin-left: -15px;
    margin-right: -15px;
}
.eltdf-grid-col-12 {
    float: left;
    width: 100%;
}
.eltdf-grid-col-1, .eltdf-grid-col-10, .eltdf-grid-col-11, .eltdf-grid-col-12, .eltdf-grid-col-2, .eltdf-grid-col-3, .eltdf-grid-col-4, .eltdf-grid-col-5, .eltdf-grid-col-7, .eltdf-grid-col-8, .eltdf-grid-col-9 {
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    box-sizing: border-box;
}
@media only screen and (min-width: 1025px) {
    .eltdf-header-bottom .eltdf-content-inner>.eltdf-full-width {
        background: #fff;
    }
}

/*First part home page */
.eltdf-row-grid-section {
    position: relative;
    width: 1100px;
    margin: 0 auto;
    /* z-index: 20; */
}
.last_toggle_el_margin, .wpb_button, .wpb_content_element, .wpb_row, ul.wpb_thumbnails-fluid>li {
    margin-bottom: 0!important;
}
.vc_row {
    margin-left: -15px;
    margin-right: -15px;
}
.vc_custom_1537357253521 {
    padding-bottom: 93px !important;
}
.vc_column_container {
    padding-left: 0;
    padding-right: 0;
}
.vc_column_container>.vc_column-inner {
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}
.eltdf-banner-holder {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
}
.eltdf-banner-holder:not(.eltdf-banner-no-hover-anim) .eltdf-banner-image {
    overflow: hidden;
}
.eltdf-banner-holder .eltdf-banner-image {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    z-index: 1;
    overflow: hidden;
}
.eltdf-banner-holder:not(.eltdf-banner-no-hover-anim) .eltdf-banner-image img {
    backface-visibility: hidden;
    transform-origin: top;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: -webkit-transform .45s cubic-bezier(.33,.02,0,.93);
    -moz-transition: -moz-transform .45s cubic-bezier(.33,.02,0,.93);
    transition: transform .45s cubic-bezier(.33,.02,0,.93);
}
.eltdf-banner-holder:not(.eltdf-banner-no-hover-anim):hover .eltdf-banner-image img {
    -webkit-transform: scale(1.1) translateX(-4%);
    -moz-transform: scale(1.1) translateX(-4%);
    transform: scale(1.1) translateX(-4%);
    will-change: transform;
    -webkit-filter: blur();
    filter: blur();
    cursor: pointer;
}
.eltdf-banner-holder .eltdf-banner-text-holder {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 35px;
    box-sizing: border-box;
    z-index: 2;
}
.eltdf-banner-holder .eltdf-banner-text-outer {
    position: relative;
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
}
.eltdf-banner-holder.eltdf-banner-horizontal-center .eltdf-banner-text-inner {
    text-align: center;
}
.eltdf-banner-holder.eltdf-banner-vertical-middle .eltdf-banner-text-inner {
    vertical-align: middle;
}
.eltdf-banner-holder .eltdf-banner-text-inner {
    position: relative;
    display: table-cell;
    height: 100%;
    width: 100%;
}
.vc_custom_1537357253521 {
    /* padding-bottom: 93px !important; */
    /* padding-bottom: 43px !important; */
    padding-bottom: 10px !important;
}

@media only screen and (min-width: 1367px) {
    .eltdf-grid-1200 .eltdf-container-inner, .eltdf-grid-1200 .eltdf-grid, .eltdf-grid-1200 .eltdf-row-grid-section {
        width: 1200px;
    }
}

/*Finished First part home page */

/*Second part home page */
.eltdf-pl-holder {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
}
.eltdf-small-space .eltdf-outer-space {
    margin: 0 -10px;
}

.eltdf-grid-list.eltdf-four-columns .eltdf-item-space, .eltdf-grid-list.eltdf-four-columns .eltdf-masonry-grid-sizer {
    width: 33.33%;
}

.eltdf-grid-list:not(.eltdf-one-columns) .eltdf-item-space {
    float: left;
}

.eltdf-small-space .eltdf-outer-space .eltdf-item-space {
    padding: 0 10px;
    margin: 0 0 20px;
}

.eltdf-grid-list .eltdf-item-space {
    position: relative;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
}

.eltdf-pl-holder .eltdf-pli-inner {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
}

.eltdf-pl-holder .eltdf-pli-inner .eltdf-pli-image {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
}

.eltdf-pl-holder .eltdf-pli-inner .eltdf-pli-image img {
    display: block;
    width: 100%;
    backface-visibility: hidden;
    -webkit-transform: scale(1.06) rotate(.1deg);
    -moz-transform: scale(1.06) rotate(.1deg);
    transform: scale(1.06) rotate(.1deg);
    -webkit-transition: -webkit-transform .45s cubic-bezier(.33,.02,0,.93);
    -moz-transition: -moz-transform .45s cubic-bezier(.33,.02,0,.93);
    transition: transform .45s cubic-bezier(.33,.02,0,.93);
}

.eltdf-pl-holder .eltdf-pli-text-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    margin: 23px 0 38px;
}

.eltdf-pl-holder.eltdf-info-below-image .eltdf-pli .eltdf-pli-title {
    float: left;
    width: 100%;
    font-size: 24px;
    font-family: "ZonaPro-ExtraLight";
}
.eltdf-pl-holder.eltdf-info-below-image .eltdf-pli .eltdf-pli-title a {
    color: #A06464;
}
.eltdf-pl-holder .eltdf-pli .eltdf-pli-title {
    margin: 0;
    text-align: center;
}
.eltdf-pl-holder .eltdf-pli .eltdf-pli-title>* {
    font-family: inherit;
    text-decoration: none;
}

@media only screen and (min-width: 1025px) {
    .eltdf-grid-list.eltdf-four-columns .eltdf-item-space:nth-child(4n+1) {
        clear: both;
    }
}
/*Finished Second part home page */

/*Third part home page */
.fullWidth {
    width: 100% !important;
    /* display: table; */
    float: none;
    padding: 0;
    min-height: 1px;
    height: 100%;
    position: relative;
}
.projectFactsWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}
.projectFactsWrap .item {
    width: 33.33%;
    height: 100%;
    padding: 15px 0px;
    text-align: center;
}
.projectFactsWrap .item p.number {
    font-size: 25px;
    padding: 0;
    font-weight: bold;
    margin-top: 20px;
    font-family: 'Zona Pro';
    color: #fff;
}
  
.projectFactsWrap .item p {
    color: #fff;
    font-size: 13px;
    margin: 0;
    padding: 10px;
    font-family: 'Open Sans';
    font-family: "ZonaPro-ExtraLight";
    color: #fff;
}

.projectFactsWrap .item img {
    vertical-align: middle;
}


.projectFactsWrap .item:hover img, .projectFactsWrap .item:hover p {
    color: white;
}
  
.projectFactsWrap .item:hover span {
    background: white;
}
  
@media (max-width: 786px){
    .projectFactsWrap .item {
        flex: 0 0 100%;
    }
}

/*Finished Third part home page */

/*Fourth part home page */
.eltdf-elements-holder {
    width: 100%;
    display: table;
    table-layout: fixed;
}

.eltdf-elements-holder .eltdf-eh-item {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    background-position: center;
    background-size: cover;
}

.eltdf-product-exhibition {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
}
.eltdf-product-exhibition .eltdf-pe-item {
    position: relative;
    max-width: 1100px;
    /* margin: 0 auto 180px; */
    margin: 0 auto 45px;
    padding: 30px 0 0;
    clear: both;
    box-sizing: border-box;
}
.eltdf-product-exhibition .eltdf-pe-background {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
}

.eltdf-product-exhibition .eltdf-pe-background .eltdf-pe-background-image {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
}
.eltdf-product-exhibition .eltdf-pe-background .eltdf-pe-background-image img {
    display: block;
}
.eltdf-product-exhibition .eltdf-pe-main-info {
    position: absolute;
    top: 50%;
    right: 0;
    width: 625px;
    box-sizing: border-box;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
}
.eltdf-product-exhibition .eltdf-pe-main-info-inner {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 30px;
    box-shadow: 0 4px 21px 0 rgb(0 0 0 / 8%);
}
.eltdf-product-exhibition .eltdf-pe-main-image {
    width: 245px;
}
.eltdf-product-exhibition .eltdf-pe-main-image, .eltdf-product-exhibition .eltdf-pe-main-text {
    position: relative;
    box-sizing: border-box;
}
.eltdf-product-exhibition .eltdf-pe-main-image a, .eltdf-product-exhibition .eltdf-pe-main-image img {
    display: block;
}
.eltdf-product-exhibition .eltdf-pe-main-text {
    width: calc(100% - 245px);
    top: -6px;
    padding: 0 0 0 30px;
}
.eltdf-product-exhibition .eltdf-pe-main-image, .eltdf-product-exhibition .eltdf-pe-main-text {
    position: relative;
    box-sizing: border-box;
}
.eltdf-product-exhibition .eltdf-pe-title {
    margin: 0;
    color: #A65A6A;
        font-family: 'Zona Pro';
}
.eltdf-product-exhibition .eltdf-pe-excerpt {
    margin: 10px 0 0;
    font-size: 14px;
    color: #A65A6A;
    font-family: "ZonaPro-ExtraLight";
}
.eltdf-product-exhibition .eltdf-pe-price {
    position: relative;
    display: block;
    line-height: 1;
    color: #A65A6A;
    margin: 22px 0 0;
    font-size: 30px;
    font-weight: 500;
        font-family: 'Zona Pro';
}
.eltdf-product-exhibition .eltdf-pe-price * {
    font-size: inherit;
    line-height: inherit;
}
.eltdf-product-exhibition .eltdf-pe-button {
    position: relative;
    display: inline-block;
    margin: 30px 0 0;
}
.eltdf-product-exhibition .eltdf-pe-button .eltdf-btn {
    padding: 8px 36px 6px;
}
.eltdf-btn.eltdf-btn-small {
    font-size: 11px;
}
.eltdf-btn.eltdf-btn-solid {
    color: #fff;
    background-color: #A65A6A;
    border: 1px solid #A65A6A;
    text-decoration: none;
    border-radius: 30px;
}
.eltdf-btn {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: auto;
    margin: 0;
    font-weight: 500;
    line-height: 2em;
    letter-spacing: .14em;
    font-family: 'Zona Pro';
    text-transform: uppercase;
    outline: 0;
    border-radius: 0;
    box-sizing: border-box;
    -webkit-transition: color .5s ease-in-out,background-color .5s ease-in-out,border-color .5s ease-in-out;
    -moz-transition: color .5s ease-in-out,background-color .5s ease-in-out,border-color .5s ease-in-out;
    transition: color .5s ease-in-out,background-color .5s ease-in-out,border-color .5s ease-in-out;
    padding: 9px 26px;
    font-size: 12px;
}

/*Finished Fourth part home page */

/*Fifth Part on home page - follow us on instagram */
.follow_us_instagram_padding {
    padding-top: 0;
}

/* Finished fifth part on home page */

/*Last part -slider */
.owl-theme .owl-nav {
    display: none;
}

/*Finished last part slider */
.eltdf-banner-holder:not(.eltdf-banner-no-hover-anim) .eltdf-banner-image img {
    backface-visibility: hidden;
    transform-origin: top;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: -webkit-transform .45s cubic-bezier(.33,.02,0,.93);
    -moz-transition: -moz-transform .45s cubic-bezier(.33,.02,0,.93);
    transition: transform .45s cubic-bezier(.33,.02,0,.93);
}

@media only screen and (max-width: 1200px) {
    .eltdf-container-inner, .eltdf-grid, .eltdf-row-grid-section {
        width: 950px;
    }
}
@media only screen and (max-width: 1024px) {
    .page-id-4584 .eltdf-content .eltdf-content-inner > .eltdf-container > .eltdf-container-inner, .page-id-4584 .eltdf-content .eltdf-content-inner > .eltdf-full-width > .eltdf-full-width-inner {
        padding: 100px 0 0 0;
    }
}

@media only screen and (max-width: 1024px) {
    .eltdf-product-exhibition .eltdf-pe-background .eltdf-pe-background-image img {
        width: 100%;
    }
    .eltdf-product-exhibition .eltdf-pe-item:nth-child(5n+1) .eltdf-pe-background .eltdf-pe-background-mark, .eltdf-product-exhibition .eltdf-pe-item:nth-child(5n+3) .eltdf-pe-background .eltdf-pe-background-mark {
        left: calc(50% + 60px)!important;
    }
}
@media only screen and (max-width: 1024px) {
    .eltdf-product-exhibition .eltdf-pe-main-info {
        position: relative;
        top: auto!important;
        left: auto!important;
        right: auto!important;
        width: 100%!important;
        -webkit-transform: none!important;
        -moz-transform: none!important;
        transform: none!important;
    }
}
@media only screen and (max-width: 768px) and (min-width: 681px) {
    .eltdf-grid-list.eltdf-five-columns .eltdf-item-space:nth-child(2n+1), .eltdf-grid-list.eltdf-four-columns .eltdf-item-space:nth-child(2n+1), .eltdf-grid-list.eltdf-six-columns .eltdf-item-space:nth-child(2n+1), .eltdf-grid-list.eltdf-three-columns .eltdf-item-space:nth-child(2n+1) {
        clear: both;
    }
}

@media only screen and (max-width: 768px) {
    .eltdf-grid-list.eltdf-four-columns .eltdf-item-space, .eltdf-grid-list.eltdf-four-columns .eltdf-masonry-grid-sizer {
        width: 50%;
    }
}

@media only screen and (max-width: 768px) {
    .eltdf-container-inner, .eltdf-grid, .eltdf-row-grid-section {
        width: 600px;
    }
}

/* @media (min-width: 768px) {
    .vc_col-sm-12 {
        width: 100%;
    }
    .vc_col-sm-1, .vc_col-sm-10, .vc_col-sm-11, .vc_col-sm-12, .vc_col-sm-2, .vc_col-sm-3, .vc_col-sm-4, .vc_col-sm-5, .vc_col-sm-6, .vc_col-sm-7, .vc_col-sm-8, .vc_col-sm-9 {
        float: left;
    }
} */

.vc_row:after, .vc_row:before {
    content: " ";
    display: table;
}
.eltdf-elements-holder .eltdf-eh-item {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.eltdf-elements-holder .eltdf-eh-item .eltdf-eh-item-inner {
    position: relative;
    z-index: 1;
}
.wpb_single_image.vc_align_left {
    text-align: left;
}
.wpb_single_image .vc_figure {
    display: inline-block;
    vertical-align: top;
    margin: 0;
    max-width: 100%;
}
.wpb_single_image .vc_single_image-wrapper {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
}
.wpb_single_image img {
    height: auto;
    max-width: 100%;
    vertical-align: top;
}
.eltdf-elements-holder .eltdf-eh-item .eltdf-elements-holder-background-outer {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    box-sizing: border-box;
}
.eltdf-elements-holder .eltdf-eh-item.eltdf-eh-expander.eltdf-eh-expander-on .eltdf-elements-holder-background-inner {
    -webkit-transform: scale(1,1);
    -moz-transform: scale(1,1);
    transform: scale(1,1);
}
.eltdf-elements-holder .eltdf-eh-item.eltdf-eh-expander .eltdf-elements-holder-background-inner {
    transform-origin: left;
    -webkit-transform: scale(0,1);
    -moz-transform: scale(0,1);
    transform: scale(0,1);
    -webkit-transition: -webkit-transform 1.75s cubic-bezier(.22,.01,0,1);
    -moz-transition: -moz-transform 1.75s cubic-bezier(.22,.01,0,1);
    transition: transform 1.75s cubic-bezier(.22,.01,0,1);
}
.eltdf-elements-holder .eltdf-eh-item .eltdf-elements-holder-background-outer .eltdf-elements-holder-background-inner {
    position: relative;
    width: 100%;
    height: 100%;
}
.vc_custom_1538061460680 {
    padding-bottom: 125px;
}



.eltdf-elements-holder-background-inner {
    transform: scale(1,1);
}
.eltdf-elements-holder-background-outer {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    box-sizing: border-box;
}
.eltdf-elements-holder-background-inner {
    position: relative;
    width: 100%;
    height: 100%;
}

.mobile-slider-home {
    display: none;
}

/*rESPONSIVE */
@media only screen and (max-width: 1024px) {
    .eltdf-product-exhibition .eltdf-pe-item {
        max-width: 768px;
    }
    .eltdf-fullscreen-search-holder .eltdf-form-holder-inner {
        width: 80%;
    }
}
@media only screen and (max-width: 768px) {
    /* .eltdf-product-exhibition .eltdf-pe-main-image {
        display: none;
    } */

    .eltdf-product-exhibition .eltdf-pe-item {
        max-width: 600px;
    }
    #home_slider_padding_mob11 {
        padding: 20px !important;
    }
    #mobile_first_part_home {
        width: 100%;
        padding-bottom: 20px !important;
    }
    #slider_part-home {
        padding-bottom: 0;
        margin-top: 0 !important;
    }
    #follow_us_part_home {
        padding-bottom: 0 !important;
    }
    .first_part_home_padding {
        padding-bottom: 20px !important;
    }
    #beach_hat_home {
        margin-top: 0;
    }
    .desktop-slider-home {
        display: none;
    }
    .mobile-slider-home {
        display: block;
    }
}

@media only screen and (max-width: 540px) {
    .eltdf-product-exhibition .eltdf-pe-main-image {
        display: none;
    }
    .eltdf-product-exhibition .eltdf-pe-main-image, .eltdf-product-exhibition .eltdf-pe-main-text {
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .eltdf-product-exhibition .eltdf-pe-main-image {
        display: none;
    }
    .eltdf-product-exhibition .eltdf-pe-main-info-inner {
        padding: 30px 30px 43px;
    }
    .eltdf-product-exhibition .eltdf-pe-main-text {
        width: 100%;
        top: 0!important;
        padding: 0!important;
        text-align: left!important;
    }
}
