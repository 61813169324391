@media only screen and (max-width: 1024px) {
    .eltdf-pl-holder .eltdf-pli-inner {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) { 
    /* .eltdf-eh-item-content {
        padding: 0 !important;
    } */

    #new-products .product-item-space {
        width: 50%;
        padding: 35px 10px;
    }
}

@media only screen and (max-width: 480px) {
    .eltdf-eh-item-content {
        padding: 25px 0px 0px 0px !important;
    }
}