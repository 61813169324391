#subSubCategory .category-menu {
    padding: 0;
    /* float: right; */
}

#subSubCategory .category-menu li:first-child {
    padding-left: 0;
}

#subSubCategory .category-menu li {
    list-style: none;
    display: inline-block;
    padding-right: 15px;
    padding-top: 10px;
    position: relative;
}

#subSubCategory .category-menu li>label {
    width: 105px;
    height: 105px;
    /* display: inline-block; */
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cccccc;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    font-size: 11px;
    font-family: 'Zona Pro';
    color: #A65A6A;
    text-transform: uppercase;
    vertical-align: middle;
    /* padding-top: 20px; */
}

#subSubCategory .category-menu li>label:hover {
    /* border-color: rgb(166, 90, 106); */
    cursor: pointer;
}

#subSubCategory .category-menu li input[type="radio"] {
    display: none;
}

#subSubCategory .category-menu li input[type="radio"]:checked + label {
    border-color: rgb(166, 90, 106);
}

#subSubCategory .category-menu li>a img {
    display: block;
    margin: 0 auto;
}

@media (max-width: 1200px) {
    #subSubCategory .category-menu {
        float: none;
        display: table;
        margin: 0 auto;
    }
}

@media (max-width: 991px) {
    #subSubCategory .category-menu li>a {
        width: 100px;
        height: 100px;
        padding-top: 5px;
    }
}

@media (max-width: 767px) {
    #subSubCategory .category-menu {
        text-align: center;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    /* #subSubCategory .category-menu li {
        margin: 0;
        display: block;
        width: 100%;
        padding: 10px 0;
        float: none;
    } */
}