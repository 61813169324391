#contact_us .eltdf-title-holder {
    background-attachment: fixed;
}

#contact_us .eltdf-title-holder {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    background-color: #f6f6f6;
    background-position: center 0;
    background-repeat: no-repeat;
}

#contact_us .eltdf-title-holder .eltdf-title-wrapper {
    position: relative;
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
}

.eltdf-title-holder .eltdf-title-inner {
    position: relative;
    display: table-cell;
    height: 100%;
    width: 100%;
    vertical-align: middle;
}

#contact_us .eltdf-title-holder.eltdf-centered-type .eltdf-title-inner {
    text-align: center;
}

#contact_us .eltdf-title-holder.eltdf-centered-type .eltdf-page-title {
    margin: 0;
}

#contact_us .eltdf-title-holder .eltdf-page-title {
    text-transform: uppercase;
}

#contact_us .eltdf-full-width-inner {
    padding: 0px;
}

.contact_us-container{
    width: 100%;
    margin: 0 auto;
    max-width: 1490px;
    padding-left: 15px;
    padding-right: 15px;
}

.contact_us_vc_custom_1554525238 {
    padding-top: 67px;
    padding-bottom: 84px;
}

.wpb_text_column :last-child, .wpb_text_column p:last-child {
    margin-bottom: 0;
}

#contact_us .wpb_content_element p {
    margin: 10px 0;
}

#contact_us h6 {
    margin: 20px 0;
}

#contact_us .send_us_message {
    font-family: 'Zona Pro';
    color: #A65A6A;
    font-size: 19px;
}

#contact_us div.wpcf7 .screen-reader-response {
    position: absolute;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    margin: 0;
    padding: 0;
    border: 0;
}

#contact_us .wpcf7-form-control-wrap {
    position: relative;
}

#contact_us .wpcf7-textarea, #contact_us .wpcf7-text {
    position: relative;
    width: 100%;
    margin: 0 0 15px;
    padding: 12px 16px;
    font-family: inherit;
    font-weight: inherit;
    line-height: calc(50px - (12px * 2) - 2px);
    color: #333;
    background-color: transparent;
    border: 1px solid #e5e5e5;
    border-radius: 0;
    outline: 0;
    cursor: text;
    -webkit-appearance: none;
    box-sizing: border-box;
    -webkit-transition: border-color .2s ease-in-out;
    -moz-transition: border-color .2s ease-in-out;
    transition: border-color .2s ease-in-out;
}

#contact_us .eltdf-btn-contact_us {
    color: #fff;
    font-family: 'Zona Pro';
    background-color: #A65A6A;
    border: 1px solid #A65A6A;
    margin: 25px 0 0;
    padding: 11px 67px 9px 67px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: auto;
    font-weight: 500;
    line-height: 2em;
    letter-spacing: .14em;
    font-family: Heebo,sans-serif;
    text-transform: uppercase;
    outline: 0;
    border-radius: 0;
    box-sizing: border-box;
    -webkit-transition: color .5s ease-in-out,background-color .5s ease-in-out,border-color .5s ease-in-out;
    -moz-transition: color .5s ease-in-out,background-color .5s ease-in-out,border-color .5s ease-in-out;
    transition: color .5s ease-in-out,background-color .5s ease-in-out,border-color .5s ease-in-out;
    font-size: 12px;
}

@media (min-width: 1200px) {
    .vc_col-lg-4 {
        width: 33.33333333%;
    }
    .vc_col-lg-8 {
        width: 66.66666667%;
    }
    .vc_col-lg-1, .vc_col-lg-10, .vc_col-lg-11, .vc_col-lg-12, .vc_col-lg-2, .vc_col-lg-3, .vc_col-lg-4, .vc_col-lg-5, .vc_col-lg-6, .vc_col-lg-7, .vc_col-lg-8, .vc_col-lg-9 {
        float: left;
    }
}

@media only screen and (max-width: 1024px) {
    .contact_container {
        width: 768px !important;
    }

}

@media (max-width: 768px) {
    .contact_container {
        width: 600px !important;
    }

    .contact_us_vc_custom_1554525238 {
        padding-top: 0;
    }
}

@media only screen and (max-width: 680px) {
    #contact_us-title {
        font-size: 35px !important;
        line-height: 40px !important;
    }
    #contact_us .eltdf-btn-contact_us {
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .contact_container {
        /* width: 300px; */
        width: 307px !important;
    }
}