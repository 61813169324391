:root {
    --color-grey-400: #b2bec3;
    --color-grey-300: #dfe6e9;
    --color-grey-100: #f7f8f9;
    --color-grey: #636e72;
    --color-white: #fff;
    --color-black: #2d3436;
}

.flex {
    display: flex;
}

.checkout-padding-bottom {
    padding-bottom: 60px;
}

.checkout-form h3 {
    margin: 0 0 25px;
    font-family: "Zona Pro";
    color: #a65a6a;
}

.checkout-form input {
    width: 100%;
    padding: 14px 20px;
    border: 1px solid var(--color-grey-400);
    background-color: transparent;
    margin-bottom: 30px;
    /* color: var(--color-grey-400); */
    color: black;
}

.checkout-form input:focus, .checkout-form select:focus {
    color: #495057;
    background-color: #fff;
    border-color: #a65a6a;
    outline: 0;
    box-shadow: inset 0 0 0 transparent;
}

.checkout-form select {
    margin-bottom: 30px;
}

.checkout-form .form-select:focus {
    outline: 0;
    box-shadow: none !important;
}

.checkout-form .woocommerce_checkout_place_order {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    margin: 0;
    font-family: Poppins,sans-serif;
    font-size: 13px;
    line-height: 2.1em;
    font-weight: 600;
    outline: 0;
    box-sizing: border-box;
    transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out;
    padding: 9px 46px;
    color: #fff;
    background-color: #a65a6a;
    border: 1px solid transparent;
    white-space: nowrap;
    cursor: pointer;
    z-index: 3;
    text-transform: capitalize;
}

.checkout-items {
    border-width: 1px;
    border-style: solid;
    border-radius: .25rem;
    border-color: var(--color-grey-300);
    background-color: var(--color-grey-100);
}

.fs-sm {
    font-size: .875rem;
}

.fs-sm, .fs-xs {
    line-height: 1.5;
}
.py-p75 {
    padding-top: .75rem;
    padding-bottom: .75rem;
}
.px-1 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.color-grey {
    color: var(--color-grey);
}
.fw-bold {
    font-weight: 700;
}
.bwb-1 {
    border-bottom-width: 1px;
    border-style: solid;
}
.bwt-1 {
    border-top-width: 1px;
    border-style: solid;
}
.bc-grey-300 {
    border-color: var(--color-grey-300);
}
.bgc-white {
    background-color: var(--color-white);
}
.w-full {
    width: 100%;
}
.py-p5 {
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.px-1 {
    padding-left: 1rem;
    padding-right: 1rem;
}
a:-webkit-any-link {
    cursor: pointer;
    text-decoration: none;
}

.w-4 {
    width: 4rem;
}
.-ml-p5 {
    margin-left: -.5rem;
}
.flex-none {
    flex: none;
}
.w-full {
    width: 100%;
}

.p-relative {
    position: relative;
}
.p-0 {
    padding: 0;
}
.h-0 {
    height: 0;
}
.pin-l {
    left: 0;
}
.pin-t {
    top: 0;
}
.p-absolute {
    position: absolute;
}
.h-full {
    height: 100%;
}
.justify-center {
    justify-content: center;
}
.minw-1 {
    min-width: 1rem;
}
.ml-p5 {
    margin-left: .5rem;
}
.flex-grow {
    flex-grow: 1;
}
a:-webkit-any-link {
    cursor: pointer;
    text-decoration: none;
}

.fs-sm {
    font-size: .875rem;
}
.fs-sm, .fs-xs {
    line-height: 1.5;
}
.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.truncate:hover {
    color: var(--color-black);
}
.color-black {
    color: var(--color-black);
}
.fs-xs-n {
    font-size: .75rem;
    line-height: 16px;
}
.break-words {
    word-wrap: break-word;
}
.self-center {
    align-self: center;
}
.ta-right {
    text-align: right;
}
.fs-sm, .fs-xs {
    line-height: 1.5;
}

.fs-xs {
    font-size: .75rem;
}
.py-p75 {
    padding-top: .75rem;
    padding-bottom: .75rem;
}
.padding-1 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.checkout-form input[type="checkbox"] {
    width: 15px;
    height: 15px;
    position: relative;
    top: 2px;
    margin-right: 10px;
}

@media screen and (max-width: 768px) {
    .checkout-items {
        margin-top: 1.8rem;
    }
}
