@font-face {
    font-family: "ZonaPro-ExtraLight";
    src: url("../../zona-pro/FontsFree-Net-ZonaPro-Bold.ttf");
}

@font-face {
    font-family: "ZonaPro-ExtraLight";
    src: url("../../zona-pro/FontsFree-Net-ZonaPro-ExtraLight.ttf");
}

/*SECOND PART BLOG*/
#blog_page .second-part a {
    margin-top: 5%;
}

/*FUNDI SECOND PART BLOG */

#blog_page .blog-title {
    background: #E8C9C7;
    padding: 10px;
}

#blog_page .eltdf-pl-holder .eltdf-pli-text-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    margin: 5px 0 38px;
}

#blog_page .blog-title a {
    color: #fff;
}

#blog_page .eltdf-st-title {
    font-family: 'Zona Pro';
    color: #D27F8B;
}

#blog_page .eltdf-st-inner p {
    font-family: 'ZonaPro-ExtraLight';
    color: #ACABAB;
    font-size: 14px;
}

#blog_page .blog-part-5 {
    padding-bottom: 20px;
}

#blog_page .eltdf-small-space .eltdf-outer-space .eltdf-item-space {
    /* padding: 0 30px; */
    margin: 0 0 20px;
}

#blog_page .eltdf-small-space .eltdf-outer-space .eltdf-item-space_blog-lori_hoxha {
    padding: 0 30px;
}

#blog_page .padding-latest-from-blog {
    padding-bottom: 60px !important;
}

#blog_page .subscribe-part h1, #blog_page .subscribe-part p {
    font-family: 'ZonaPro-ExtraLight';
    color: #fff;
}

#blog_page .subscribe-part-2 {
    max-width: 1000px;
    margin: 20px auto 0 auto;
}

#blog_page .subscribe-part-2 input {
    font-family: 'ZonaPro-ExtraLight' !important;
    padding: .375rem 35px;
    border-radius: 40px;
    border: none;
}

#blog_page .btn-blog {
    padding: .375rem 25px;
    background: #fff;
    font-family: 'Zona Pro';
    color: #D27F8B;
    border-radius: 40px;
    cursor: pointer;
}

#blog_page .btn-blog:hover, .btn-blog:focus, .btn-blog:active {
    outline: 0;
    -webkit-box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
    box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
}

.form-control:focus { 
    box-shadow: none;
}

#blog_page .blog-column-2 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.eltdf-single-image-holder, .eltdf-single-image-holder .eltdf-si-inner  {
    width: 100%;
}

.eltdf-single-image-holder .eltdf-si-inner a, .eltdf-single-image-holder .eltdf-si-inner img {
    position: relative;
    display: block;
}

.eltdf-section-title-holder .eltdf-st-text {
    display: block;
    margin: 28px 0 0;
}

#blogpage .vc_column-inner::after, #blog_page .vc_column-inner::before {
    content: " ";
    display: table;
}

#blog_page .owl-theme .owl-dots {
    display: none;
}

.mobile-blog-slider {
    display: none;
}

@media only screen and (max-width: 768px) {
    #blog_page .owl-theme .owl-dots {
        display: block;
    }
    #blog-first_part_0091111, #eltdf-row-grid-section-blog_first_part_0081111 {
        padding-bottom: 0 !important;
    }
    #eltdf-item-space-shoes {
        margin-bottom: 0 !important;
    }
    .blog-second-part-mobile {
        padding: 0 !important;
        margin-top: 30px !important;
        margin-bottom: 60px !important;
    }
    #blog-lori_hoxha-eltdf-eh-item-content {
        padding: 0 !important;
    }
    .vc_empty_space {
        display: none;
    }
    #blog_page .eltdf-st-title {
        font-size: 16px;
        text-align: center;
    }
    .blog-st-title-mobile {
        margin-top: 15px;
    }
    #blog_page .subscribe-part-2 input {
        margin: 0 0 20px 0;
    }
    .subscribe-part_blog_0011111 {
        margin-bottom: 40px !important;
    }
    #blog_page .padding-latest-from-blog {
        padding-bottom: 0 !important;
    }
    #latest_from_blog, #blog_eltdf-row-grid-section {
        padding-bottom: 0 !important;
    }
    .blog_mobile-center, .btn-blog-center {
        text-align: center;
    }
    .mobile-subscribe {
        width: 100% !important;
    }

    .blog-lastpart_title {
        margin-top: 20px;
    }
    .desktop-blog-slider {
        display: none;
    }
    .mobile-blog-slider {
        display: block;
        padding-top: 45px;
    }
}

@media only screen and (max-width: 680px) {
    #blog-eltdf-eh-item-content{
        padding: 0 !important;
    }
}