/*Search bar */
#search_bar {
    -webkit-animation: search_fade_in .3s ease both;
    -moz-animation: search_fade_in .3s ease both;
    animation: search_fade_in .3s ease both;
}

.eltdf-fullscreen-search-holder {
    visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    opacity: 0;
    z-index: 112;
    box-sizing: border-box;
}

.eltdf-fullscreen-search-holder {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 105;
    opacity: 0;
}

#search_bar .eltdf-search-close {
    opacity: 1;
    visibility: visible;
}

.eltdf-fullscreen-search-holder .eltdf-search-close {
    position: absolute;
    top: 79px;
    right: 20%;
    /* right: 220px; */
    color: #000;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    /* display: none; */
}

.eltdf-fullscreen-search-holder .eltdf-search-close.eltdf-search-close-svg-path svg {
    visibility: visible;
    height: 16px;
    width: 17px;
}

.eltdf-search-fade .eltdf-fullscreen-search-holder .eltdf-fullscreen-search-table {
    background-color: #F4E4E3;
}

.eltdf-fullscreen-search-holder .eltdf-fullscreen-search-table {
    display: table;
    width: 100%;
    height: 100%;
}

.eltdf-fullscreen-search-holder .eltdf-fullscreen-search-cell {
    position: relative;
    display: table-cell;
    height: 100%;
    width: 100%;
    vertical-align: middle;
    text-align: center;
}

.eltdf-fullscreen-search-holder .eltdf-form-holder-inner {
    position: relative;
    width: 40%;
    margin: auto;
}

.eltdf-fullscreen-search-holder .eltdf-field-holder {
    display: block;
    position: relative;
}

.eltdf-fullscreen-search-holder .eltdf-search-field {
    position: relative;
    /* height: 100%; */
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 22px;
    line-height: 2em;
    background: 0 0;
    border: 0;
    border-radius: 0;
    outline: 0;
    font-weight: 600;
    letter-spacing: 0;
}

.eltdf-search-field::placeholder {
    font-family: 'Zona Pro';
    color: #A65A6A !important;
}

.eltdf-search-field::-ms-input-placeholder { /* Microsoft Edge */
    font-family: 'Zona Pro';
    color: #A65A6A !important;
}

.eltdf-search-field:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: 'Zona Pro';
    color: #A65A6A !important;
}

.close-icon-search_bar {
    position: relative;
    width: 40%;
    margin: auto;
}

#search_bar .close-search {
    float: right;
    cursor: pointer;
}

#search_bar .eltdf-search-close {
    color: #A65A6A;
    font-size: 24px;
    cursor: pointer;
    overflow: hidden;
    -webkit-transition: all .35s ease;
    -moz-transition: all .35s ease;
    -ms-transition: all .35s ease;
    -o-transition: all .35s ease;
    transition: all .35s ease;
}
 #search_bar .eltdf-search-close:hover {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: all .35s ease;
    -moz-transition: all .35s ease;
    -ms-transition: all .35s ease;
    -o-transition: all .35s ease;
    transition: all .35s ease;
}

.eltdf-fullscreen-search-opened .eltdf-fullscreen-search-holder .eltdf-search-submit {
    opacity: 0;
    bottom: 5px;
    -webkit-transition: color .2s ease,opacity .2s ease-out 1.1s,bottom .4s ease-out .9s;
    -moz-transition: color .2s ease,opacity .2s ease-out 1.1s,bottom .4s ease-out .9s;
    transition: color .2s ease,opacity .2s ease-out 1.1s,bottom .4s ease-out .9s;
}

.eltdf-fullscreen-search-holder .eltdf-search-submit {
    position: absolute;
    right: 0;
    bottom: -20px;
    width: auto;
    color: #000;
    background-color: transparent;
    border: none;
    outline: 0;
    opacity: 0;
    cursor: pointer;
    -webkit-transition: all .3s .2s;
    -moz-transition: all .3s .2s;
    transition: all .3s .2s;
}

.eltdf-fullscreen-search-holder .eltdf-search-submit.eltdf-search-submit-svg-path path, .eltdf-fullscreen-search-holder .eltdf-search-submit.eltdf-search-submit-svg-path>* {
    fill: currentColor;
    stroke: currentColor;
}

.eltdf-fullscreen-search-opened .eltdf-fullscreen-search-holder .eltdf-form-holder-inner .eltdf-line {
    width: 100%;
    -webkit-transition: width .5s ease .6s;
    -moz-transition: width .5s ease .6s;
    transition: width .5s ease .6s;
}

.eltdf-fullscreen-search-holder .eltdf-form-holder-inner .eltdf-line {
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 0;
    border-bottom: 2px solid #A65A6A;
    -webkit-transition: all .3s .2s;
    -moz-transition: all .3s .2s;
    width: 100%; /* per mu bo animacioni duhet kete width me bo 100%, kur hjeket me u bo 0%, si dhe duhet visibility: visible;
    opacity: 1; - kto tek eltdf-fullscreen-search-holder per mu hap */
    transition: all .3s .2s;
}

/*Search bar */

@media (max-width: 1500px) {
    .eltdf-fullscreen-search-holder .eltdf-search-close {
        top: 15%;
        right: 50px;
    }
}

@media (max-width: 800px) {
    .eltdf-fullscreen-search-holder .eltdf-search-close {
        top: 150px;
        right: 20px;
    }
}

@media (max-width: 768px) {
    .eltdf-fullscreen-search-holder .eltdf-search-close {
        right: 10%;
    }
}