.open-cart-aside .cart-flyout {
    visibility: inherit;
    content-visibility: auto;
    opacity: 0;
    display: none;
    transform: translate(0,0);
}

.la-overlay-global {
    position: fixed;
    background: #232324;
    z-index: 9;
    opacity: .7;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    transition: all 250ms ease-out;
}

.ltr .cart-flyout {
    right: 0;
    transform: translate(200px,0);
}

.cart-flyout {
    position: fixed;
    top: 0;
    width: 420px;
    padding: 50px;
    min-height: 100vh;
    bottom: 0;
    z-index: 111;
    visibility: hidden;
    content-visibility: hidden;
    opacity: 0;
    transition: all 250ms ease-in;
    box-shadow: 0 0 87px 0 rgb(0 0 0 / 9%);
    background-color: #fff;
    max-width: 100%;
}

.cart-flyout .cart-flyout--inner {
    display: block;
    height: 100%;
    position: relative;
}

.cart-flyout .btn-close-cart {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 36px;
    line-height: 36px;
    color: #ACABAB;
}

.cart-flyout .cart-flyout__content {
    padding-top: 50px;
}

.cart-flyout .cart-flyout__heading {
    margin-bottom: 40px;
    font-size: 22px;
    font-family: 'Zona Pro';
    color: #A65A6A;
}

.cart-flyout .cart-flyout__loading {
    text-align: center;
    display: block;
    opacity: 0;
    visibility: hidden;
    height: 0;
}

.la-loader.spinner3 {
    width: 70px;
    text-align: center;
}

.cart-flyout .widget_shopping_cart_content {
    overflow: hidden;
    opacity: 1;
}

.widget_shopping_cart_content:after, .widget_shopping_cart_content:before {
    content: " ";
    display: table;
    flex-basis: 0%;
    order: 1;
}

.cart-flyout .cart_list {
    display:grid;
    margin: 0 -30px 0 0;
    padding: 0 30px 0 0;
    max-height: calc(100vh - 410px);
    overflow: auto;
}

.cart-flyout .product_list_widget li {
    padding-right: 15px;
}

.product_list_widget li+li {
    margin-top: 30px;
}

.product_list_widget li {
    position: relative;
}

.product_list_widget li:after, .product_list_widget li:before {
    content: " ";
    display: table;
    flex-basis: 0%;
    order: 1;
}

.product_list_widget a.remove {
    display: block;
    font-weight: 400;
    font-size: 22px;
    height: 20px;
    line-height: 17px;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-align: right;
    width: 20px;
    font-family: 'Zona Pro';
    color: #ACABAB;
}

.product_list_widget a {
    display: block;
    line-height: normal;
    margin: 0 0 5px;
    font-family: 'Zona Pro';
    color: #A65A6A;
}

.product_list_widget li img {
    float: left;
    max-width: 70px;
    margin-right: 20px;
    border: 1px solid rgba(0,0,0,.1);
}

.product_list_widget .quantity {
    font-size: .857em;
    font-family: 'ZonaPro-Extralight';
    color: #575757;
}

.quantity {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    zoom: 1;
}

.product_list_widget .quantity>.amount {
    margin-left: 5px;
}

.cart-flyout .widget_shopping_cart_content .total {
    display: flex;
    justify-content: space-between;
    font-family: 'Zona Pro';
    color: #A65A6A;
}

.widget_shopping_cart_content .total {
    padding-top: 20px;
}

.cart-flyout .widget_shopping_cart_content .button-cart-modal-right-side {
    width: 100%;
    margin: 35px 0 0;
    font-size: .875em;
    display: inline-block;
    background-color: #A65A6A;
    color: #fff;
    padding: 18px 30px;
    cursor: pointer;
    text-align: center;
    line-height: 1;
    border: none;
    border-radius: 0;
    text-transform: none;
    font-weight: 600;
    transition: all .3s ease;
    font-family: 'Zona Pro';
    border-radius: 40px;
}

.cart-flyout .widget_shopping_cart_content .button-cart-modal-right-side {
    text-decoration: none;
}

/*Kjo eshte qe e hap modalin */
.ltr .cart-flyout-open {
    right: 0;
    opacity: 1;
    display: block;
    transform: translate(0,0);
}

@media (max-width: 767px) {
    .cart-flyout {
        padding: 0px 20px;
    }
}