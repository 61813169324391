#cartMobile {
    display: none;
}
#cartMobile .shopping-cart-mobile {
    display: flex;
    width: 750px;
    height: 423px;
    background: #ffffff;
    box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    flex-direction: column;
}

#cartMobile .title h2 {
    border-bottom: 1px solid #e1e8ee;
    padding: 20px 30px;
    font-family: 'Zona Pro';
    color: #A65A6A;
    }

#cartMobile .item {
    padding: 20px 30px;
    height: 120px;
    display: flex;
}

#cartMobile .item:nth-child(3) {
    border-top: 1px solid #e1e8ee;
    border-bottom: 1px solid #e1e8ee;
}

#cartMobile .buttons {
    position: relative;
    padding-bottom: 30px;
}
#cartMobile .delete-btn,
#cartMobile .like-btn {
    display: inline-block;
    cursor: pointer;
}
#cartMobile .delete-btn {
    display: inline-block;
    cursor: pointer;
    width: 18px;
    height: 17px;
    padding-bottom: 20px;
    background: url(../../images/delete-icn.svg) no-repeat center;
}

#cartMobile .like-btn {
    position: absolute;
    top: 9px;
    left: 15px;
    width: 60px;
    height: 60px;
    background-size: 2900%;
    background-repeat: no-repeat;
    background: url(../../images/twitter-heart.png);
    cursor: pointer;
}

#cartMobile .is-active {
    animation-name: animate;
    animation-duration: 0.8s;
    animation-iteration-count: 1;
    animation-timing-function: steps(28);
    animation-fill-mode: forwards;
}

@keyframes animate {
    0% {
        background-position: left;
    }
    50% {
        background-position: right;
    }
    100% {
        background-position: right;
    }
}

#cartMobile .image {
    margin-right: 50px;
}

#cartMobile .description {
    padding-top: 10px;
    margin-right: 60px;
    width: 115px;
}

#cartMobile .description a {
    display: block;
    font-size: 18px;
    font-family: 'Zona Pro';
    color: #A65A6A;
}

#cartMobile .description a:first-child {
    margin-bottom: 5px;
}
#cartMobile .description a:last-child {
    margin-top: 8px;
    font-size: 18px;
    font-family: 'Zona Pro';
    color: #A65A6A;
}

#cartMobile .cart-mobile-quantity {
    padding-top: 20px;
    margin-right: 60px;
}
#cartMobile .cart-mobile-quantity input {
    -webkit-appearance: none;
    border: none;
    text-align: center;
    width: 32px;
    font-size: 16px;
    color: #43484d;
    font-weight: 300;
}

#cartMobile button[class*="btn"] {
    width: 30px;
    height: 30px;
    background-color: #e1e8ee;
    border-radius: 6px;
    border: none;
    cursor: pointer;
}
#cartMobile .minus-btn img {
    margin-bottom: 3px;
}

#cartMobile button:focus,
#cartMobile input:focus {
    outline: 0;
}

#cartMobile .total-price {
    width: 83px;
    padding-top: 27px;
    text-align: center;
    font-size: 18px;
    font-family: 'ZonaPro-ExtraLight';
    color: #ACABAB;
}

@media (max-width: 767px) {
    .desktop-cart {
        display: none;
    }
    #cartMobile {
        display: block;
    }
    #cartMobile .shopping-cart-mobile {
        display: flex;
        width: 100%;
        height: auto;
        overflow: hidden;
    }
    #cartMobile .item {
        height: auto;
        flex-wrap: wrap;
        justify-content: center;
    }
    #cartMobile .image img {
        width: 50%;
    }
    #cartMobile .image,
    #cartMobile .cart-mobile-quantity,
    #cartMobile .description {
        width: 100%;
        text-align: center;
        margin: 6px 0;
    }
}
